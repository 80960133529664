import React, { useEffect, useState } from "react";
import {
  CalendarOutlined,
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  EditOutlined,
  FileOutlined,
  SearchOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  StrikethroughOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  List,
  Row,
  Col,
  Checkbox,
  Button,
  Divider,
  Tooltip,
  Avatar,
  Tag,
  Card,
  Dropdown,
  Menu,
  Input,
  DatePicker,
  Select,
  Form,
} from "antd";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import {
  Status,
  StatusByHexColor,
} from "../../../components/model/common/Status";
import { useTranslation } from "react-i18next";
import { setListHeaderStyle } from "../../common/ListHeader";
import PaginationFooter from "../../common/PaginationFooter";

export const JobRecruitmentList = ({
  isLoading,
  data,
  isFromAdmin = false,
  onAdd,
  onItemViewed,
  isSelectable = false,
  isMultipleAction = true,
  selectedItems = [],
  onItemSelected,
  onListActionsRendered,
  isShowStatus = true,
  filterCheckedText = "",
  footerElement,
  onAttachmentClicked,
  setCurrentYear,
  currentYear = dayjs().year(),
  pagination,
  setPagination,
  resetPagination = () => {},
}) => {
  const { t } = useTranslation();
  const { Search } = Input;
  const { Option } = Select;

  const [showSearchComp, setShowSearchComp] = useState(false);
  const [showDateComp, setShowDateComp] = useState(false);
  const [showUserComp, setShowUserComp] = useState(false);
  const [showStatusComp, setShowStatusComp] = useState(false);
  const [userSelectionData, setUserSelectionData] = useState([]);
  const [statusSelectionData, setStatusSelectionData] = useState([]);
  const [filteredDatas, setFilteredDatas] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  const [searchDateValue, setSearchDateValue] = useState();
  const [searchUserValue, setSearchUserValue] = useState();
  const [searchStatusValue, setSearchStatusValue] = useState();

  const [filterVisible, setFilterVisible] = useState(false);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("Date");
  const [isFilterChecked, setIsFilterChecked] = useState(true);

  const [footerValue, setFooterValue] = useState(0);

  useEffect(() => {
    data.sort((a, b) => dayjs(b.TransDate) - dayjs(a.TransDate));

    onCheckFilter(true);

    // let filteredData = [];
    // if (isFilterChecked) {
    //   filteredData = data.filter((x) => !x.IsViewOnly);
    // } else {
    //   filteredData = data;
    // }

    // getDistinctUser(filteredData);
    // setFilteredDatas(filteredData);
  }, [data]); // eslint-disable-line

  useEffect(() => {
    setListHeaderStyle(isFromAdmin);
  }, [isFromAdmin]);

  function getDistinctUser(data) {
    const result = [];
    const resultStatus = [];

    const map = new Map();
    const mapStatus = new Map();

    for (const item of data) {
      if (!map.has(item.UserKey)) {
        map.set(item.UserKey, true);
        result.push({
          UserKey: item.UserKey,
          EmpyName: item.EmpyName,
        });
      }
      if (!mapStatus.has(item.Status)) {
        mapStatus.set(item.Status, true);
        resultStatus.push({
          StatusKey: item.Status,
          StatusDesc: Status[item.Status],
        });
      }
    }
    setUserSelectionData(result);
    setStatusSelectionData(resultStatus);
  }

  function viewItem(key) {
    if (onItemViewed) {
      onItemViewed(key);
    }
  }

  function addItem() {
    onAdd();
  }

  function selectItem(isSelected, key) {
    if (onItemSelected) {
      if (isSelected) {
        if (
          selectedItems.findIndex((x) => x.JobRecruitmentKey === key) === -1
        ) {
          let selectedItem = data.find((x) => x.JobRecruitmentKey === key);
          onItemSelected([...selectedItems, selectedItem]);
        }
      } else {
        onItemSelected(
          selectedItems.filter((x) => x.JobRecruitmentKey !== key)
        );
      }
    }
  }

  function showCompFunc(comp) {
    if (comp === "Search") {
      setShowSearchComp(true);
    } else if (comp === "Date") {
      setShowDateComp(true);
    } else if (comp === "User") {
      setShowUserComp(true);
    } else if (comp === "Status") {
      setShowStatusComp(true);
    }
  }

  function closeCompFunc() {
    setShowSearchComp(false);
    setShowDateComp(false);
    setShowUserComp(false);
    setShowStatusComp(false);

    setSearchValue("");
    setSearchDateValue();
    setSearchUserValue();
    setSearchStatusValue();

    if (isFilterChecked) {
      let filteredData = data.filter((x) => !x.IsViewOnly);
      sumFooterValue(filteredData);
      setFilteredDatas(filteredData);
    } else {
      sumFooterValue(data);
      setFilteredDatas(data);
    }
    resetPagination();
  }

  function onSelectFilter(e) {
    let value = e.key;

    if (value === "0") {
      setOrderBy("Date");
      sortData("Date", order);
    } else if (value === "1") {
      setOrderBy("Status");
      sortData("Status", order);
    }

    if (value === "997") {
      setOrder("asc");
      sortData(orderBy, "asc");
    } else if (value === "998") {
      setOrder("desc");
      sortData(orderBy, "desc");
    }

    if (value === "999") {
      setFilterVisible(false);
      setOrderBy("Date");
      setOrder("desc");
      sortData("Date", "desc");
    }
  }

  const checkMenuIcon = (menu, check) => (
    <>
      <span style={{ float: "left" }}>{menu}</span>
      <span style={{ float: "right", display: check === true ? "" : "none" }}>
        <CheckOutlined />
      </span>
    </>
  );

  const menu = (
    <Menu
      onClick={onSelectFilter}
      items={[
        {
          label:
            orderBy === "Date"
              ? checkMenuIcon(t("general.date"), true)
              : checkMenuIcon(t("general.date"), false),
          key: "0",
        },
        {
          label:
            orderBy === "Status"
              ? checkMenuIcon(t("general.status"), true)
              : checkMenuIcon(t("general.status"), false),
          key: "1",
        },
        { type: "divider" },
        {
          label:
            order === "asc"
              ? checkMenuIcon(t("general.ascending"), true)
              : checkMenuIcon(t("general.ascending"), false),
          key: "997",
        },
        {
          label:
            order === "desc"
              ? checkMenuIcon(t("general.descending"), true)
              : checkMenuIcon(t("general.descending"), false),
          key: "998",
        },
        { type: "divider" },
        {
          label: t("general.reset"),
          key: "999",
        },
      ]}
    ></Menu>
  );

  function onEnterSearch(value, isChecked) {
    let filteredData = data.filter(
      (x) =>
        x.Title.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        x.Description.toLowerCase().indexOf(value.toLowerCase()) > -1
    );

    if (isChecked === true) {
      filteredData = filteredData.filter((x) => !x.IsViewOnly);
    }

    sumFooterValue(filteredData);
    setFilteredDatas(filteredData);
    resetPagination();
  }

  function onChangeSearch(e) {
    setSearchValue(e.target.value);
  }

  function onChangeDate(date, isChecked) {
    if (date) {
      let formatDate = date.format("DD/MM/YYYY");

      let filteredData = data.filter(
        (x) => dayjs(x.TransDate).format("DD/MM/YYYY") === formatDate
      );

      if (isChecked === true) {
        filteredData = filteredData.filter((x) => !x.IsViewOnly);
      }

      sumFooterValue(filteredData);
      setSearchDateValue(date);
      setFilteredDatas(filteredData);
      resetPagination();
    } else {
      setSearchDateValue();

      if (isChecked === true) {
        let filteredData = data.filter((x) => !x.IsViewOnly);

        sumFooterValue(filteredData);
        setFilteredDatas(filteredData);
      } else {
        sumFooterValue(data);
        setFilteredDatas(data);
      }
      resetPagination();
    }
  }

  function onChangeUserSelection(value, isChecked) {
    setSearchUserValue(value);
    let filteredData = data.filter((x) => x.UserKey === value);

    if (isChecked === true) {
      filteredData = filteredData.filter((x) => !x.IsViewOnly);
    }

    sumFooterValue(filteredData);
    setFilteredDatas(filteredData);
    resetPagination();
  }

  function onChangeStatusSelection(value, isChecked) {
    setSearchStatusValue(value);
    let filteredData = data.filter((x) => x.Status === value);

    if (isChecked === true) {
      filteredData = filteredData.filter((x) => !x.IsViewOnly);
    }

    sumFooterValue(filteredData);
    setFilteredDatas(filteredData);
    resetPagination();
  }

  function onVisibleChange(flag) {
    setFilterVisible(flag);
  }

  function onCheckFilter(isChecked) {
    setIsFilterChecked(isChecked);
    sortData(orderBy, order);

    let filteredData = [];
    if (isChecked) {
      filteredData = data.filter((x) => !x.IsViewOnly);
      getDistinctUser(filteredData);
    } else {
      getDistinctUser(data);
    }

    if (searchValue && searchValue.length > 0) {
      onEnterSearch(searchValue, isChecked);
    } else if (searchDateValue) {
      onChangeDate(searchDateValue, isChecked);
    } else if (searchUserValue) {
      onChangeUserSelection(searchUserValue, isChecked);
    } else if (searchStatusValue) {
      onChangeStatusSelection(searchStatusValue, isChecked);
    } else {
      if (isChecked) {
        sumFooterValue(filteredData);
        setFilteredDatas(filteredData);
      } else {
        sumFooterValue(data);
        setFilteredDatas(data);
      }
      resetPagination();
    }
  }

  function generateUsersOption() {
    let dataOptions = [];
    if (userSelectionData && userSelectionData.length) {
      dataOptions = userSelectionData.map((type) => (
        <Option key={type.UserKey} value={type.UserKey}>
          {type.EmpyName}
        </Option>
      ));
    }

    return dataOptions;
  }

  function generateStatusOption() {
    let dataOptions = [];
    if (statusSelectionData && statusSelectionData.length) {
      dataOptions = statusSelectionData.map((type) => (
        <Option key={type.StatusKey} value={type.StatusKey}>
          {type.StatusDesc}
        </Option>
      ));
    }

    return dataOptions;
  }

  function sortData(orderBy, order) {
    if (order === "asc") {
      if (orderBy === "Date") {
        //Sort By Date
        filteredDatas.sort((a, b) => dayjs(a.TransDate) - dayjs(b.TransDate));
        data.sort((a, b) => dayjs(a.TransDate) - dayjs(b.TransDate));
      } else if (orderBy === "Status") {
        //Sort By String
        filteredDatas.sort((a, b) => sortStringAsc(a, b));
        data.sort((a, b) => sortStringAsc(a, b));
      }
    } else {
      if (orderBy === "Date") {
        filteredDatas.sort((a, b) => dayjs(b.TransDate) - dayjs(a.TransDate));
        data.sort((a, b) => dayjs(b.TransDate) - dayjs(a.TransDate));
      } else if (orderBy === "Status") {
        filteredDatas.sort((a, b) => sortStringDesc(a, b));
        data.sort((a, b) => sortStringDesc(a, b));
      }
    }
  }

  function sortStringAsc(a, b) {
    let statusA = Status[a.Status].toUpperCase();
    let statusB = Status[b.Status].toUpperCase();

    if (statusA < statusB) {
      return -1;
    }
    if (statusA > statusB) {
      return 1;
    }
    return 0;
  }

  function sortStringDesc(a, b) {
    let statusA = Status[a.Status].toUpperCase();
    let statusB = Status[b.Status].toUpperCase();

    if (statusB < statusA) {
      return -1;
    }
    if (statusB > statusA) {
      return 1;
    }
    return 0;
  }

  function sumFooterValue(allData) {
    var sumValue = allData.reduce(function (accumulator) {
      return accumulator + 1;
    }, 0);

    setFooterValue(sumValue);
  }

  function viewAttachment(attachments) {
    if (onAttachmentClicked) {
      onAttachmentClicked(attachments).then((result) => {
        if (result && result.data) {
          result.data.forEach((attach) => {
            window.open(attach.UploadedUrl, "_blank");
          });
        }
      });
    }
  }

  const handleYearChange = (date) => {
    setIsFilterChecked(true);
    setCurrentYear(date.$y);
  };

  return (
    <div>
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: isFromAdmin ? 24 : 16, offset: isFromAdmin ? 0 : 4 }}
        >
          <div
            className="ant-list-header"
            style={{
              paddingInline: 24,
              paddingBlock: 12,
            }}
          >
            <div>
              <Row>
                <Col span={16}>
                  <Button.Group
                    style={{
                      display:
                        showSearchComp ||
                        showDateComp ||
                        showUserComp ||
                        showStatusComp
                          ? "none"
                          : "",
                      padding: "5px 0",
                    }}
                  >
                    <Tooltip
                      title={t("general.search")}
                      getPopupContainer={() =>
                        document.getElementById("scroll-container")
                      }
                    >
                      <Button
                        {...(!isFromAdmin && { type: "primary" })}
                        onClick={() => showCompFunc("Search")}
                      >
                        <SearchOutlined />
                      </Button>
                    </Tooltip>
                    <Tooltip
                      title={t("general.filter_by_date")}
                      getPopupContainer={() =>
                        document.getElementById("scroll-container")
                      }
                    >
                      <Button
                        {...(!isFromAdmin && { type: "primary" })}
                        onClick={() => showCompFunc("Date")}
                      >
                        <CalendarOutlined />
                      </Button>
                    </Tooltip>
                    <Tooltip
                      title={t("general.filter_by_user")}
                      getPopupContainer={() =>
                        document.getElementById("scroll-container")
                      }
                    >
                      <Button
                        {...(!isFromAdmin && { type: "primary" })}
                        onClick={() => showCompFunc("User")}
                        style={{
                          display: isSelectable ? "" : "none",
                        }}
                      >
                        <UserOutlined />
                      </Button>
                    </Tooltip>
                    <Tooltip
                      title={t("general.filter_by_status")}
                      getPopupContainer={() =>
                        document.getElementById("scroll-container")
                      }
                    >
                      <Button
                        {...(!isFromAdmin && { type: "primary" })}
                        onClick={() => showCompFunc("Status")}
                        style={{
                          display: isSelectable ? "none" : "",
                        }}
                      >
                        <StrikethroughOutlined />
                      </Button>
                    </Tooltip>
                  </Button.Group>
                  <>
                    <Search
                      value={searchValue}
                      enterButton
                      placeholder={t("general.search")}
                      onSearch={(value) =>
                        onEnterSearch(value, isFilterChecked)
                      }
                      onChange={onChangeSearch}
                      style={{
                        width: "220px",
                        padding: "5px 0",
                        display: showSearchComp ? "" : "none",
                      }}
                    />
                    <DatePicker
                      allowClear={true}
                      placeholder={t("general.date")}
                      format="DD/MM/YYYY"
                      onChange={(value) => onChangeDate(value, isFilterChecked)}
                      value={searchDateValue}
                      style={{
                        width: "220px",
                        display: showDateComp ? "" : "none",
                      }}
                    />

                    <Select
                      value={searchUserValue}
                      showSearch
                      placeholder={t("general.employee_placeholder")}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={() =>
                        document.getElementById("scroll-container")
                      }
                      onChange={(value) =>
                        onChangeUserSelection(value, isFilterChecked)
                      }
                      style={{
                        width: "220px",
                        padding: "5px 0",
                        display: showUserComp ? "" : "none",
                      }}
                    >
                      {generateUsersOption()}
                    </Select>

                    <Select
                      value={searchStatusValue}
                      showSearch
                      placeholder={t("general.status_placeholder")}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={() =>
                        document.getElementById("scroll-container")
                      }
                      onChange={(value) =>
                        onChangeStatusSelection(value, isFilterChecked)
                      }
                      style={{
                        width: "220px",
                        padding: "5px 0",
                        display: showStatusComp ? "" : "none",
                      }}
                    >
                      {generateStatusOption()}
                    </Select>
                    <Button
                      onClick={() => closeCompFunc()}
                      type="link"
                      style={{
                        color: isFromAdmin ? "#989898" : "#ffffff",
                        display:
                          showSearchComp ||
                          showDateComp ||
                          showUserComp ||
                          showStatusComp
                            ? ""
                            : "none",
                        padding: "5px 8px",
                      }}
                    >
                      <CloseOutlined />
                    </Button>
                  </>

                  <Divider type="vertical" />
                  <Button.Group style={{ padding: "5px 0" }}>
                    <Tooltip>
                      <Dropdown
                        dropdownRender={() => menu}
                        trigger={["click"]}
                        open={filterVisible}
                        onOpenChange={onVisibleChange}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                      >
                        <Button {...(!isFromAdmin && { type: "primary" })}>
                          {order === "asc" ? (
                            <SortAscendingOutlined />
                          ) : (
                            <SortDescendingOutlined />
                          )}
                          {t("general.sort_by")} {""}
                          {orderBy === "Date"
                            ? t("general.date")
                            : orderBy === "Status"
                            ? t("general.status")
                            : ""}
                          {""}
                          <DownOutlined />
                        </Button>
                      </Dropdown>
                    </Tooltip>
                  </Button.Group>

                  <Checkbox
                    style={{
                      paddingLeft: 10,
                      color: "#ffffff",
                      display:
                        filterCheckedText && filterCheckedText.length > 0
                          ? ""
                          : "none",
                    }}
                    onChange={(event) => onCheckFilter(event.target.checked)}
                    defaultChecked={isFilterChecked}
                    checked={isFilterChecked}
                  >
                    {filterCheckedText}
                  </Checkbox>
                </Col>
                {!isFromAdmin && (
                  <Col span={8} style={{ padding: "5px 0" }}>
                    <Button.Group
                      style={{
                        float: "right",
                        border: "1px solid #fff",
                        display: isSelectable ? "none" : "",
                      }}
                    >
                      <Button type="primary" onClick={() => addItem()}>
                        {t("general.new")}
                      </Button>
                    </Button.Group>

                    <Form
                      layout="inline"
                      style={{ position: "relative", float: "right", top: 1 }}
                    >
                      <Form.Item
                        label={
                          <label style={{ color: "#fff" }}>
                            {t("general.year")}
                          </label>
                        }
                        name="MonthYear"
                        initialValue={dayjs(dayjs().year(currentYear), "YYYY")}
                        style={{ marginRight: isSelectable ? 0 : 16 }}
                      >
                        <DatePicker
                          allowClear={false}
                          placeholder={t("general.year")}
                          picker="year"
                          suffixIcon={[]}
                          style={{ width: 60 }}
                          onChange={handleYearChange}
                        />
                      </Form.Item>
                    </Form>
                  </Col>
                )}
              </Row>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col
          style={{
            height: "calc(100vh - 214px)",
            overflow: "auto",
            backgroundColor: "#e4e6eb",
          }}
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: isFromAdmin ? 24 : 16, offset: isFromAdmin ? 0 : 4 }}
        >
          <List
            loading={isLoading}
            split={false}
            itemLayout="horizontal"
            dataSource={
              pagination === undefined
                ? filteredDatas
                : filteredDatas.filter(
                    (x, index) =>
                      index >= pagination.minIndex &&
                      index < pagination.maxIndex
                  )
            }
            renderItem={(item) => (
              <Card
                style={{ padding: 0 }}
                className="right-align-actions"
                actions={onListActionsRendered && onListActionsRendered(item)}
              >
                <List.Item
                  style={{
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                >
                  {(isSelectable || isFromAdmin) && (
                    <div>
                      <Checkbox
                        style={{
                          marginRight: "15px",
                          display:
                            !isFromAdmin && !item.IsViewOnly && isMultipleAction
                              ? ""
                              : "none",
                        }}
                        checked={
                          selectedItems.findIndex(
                            (x) =>
                              x.JobRecruitmentKey === item.JobRecruitmentKey
                          ) >= 0
                        }
                        onChange={(event) =>
                          selectItem(
                            event.target.checked,
                            item.JobRecruitmentKey
                          )
                        }
                      />

                      <Avatar
                        size={50}
                        src={item.EmpyPhotoUrl}
                        style={{ marginRight: "15px" }}
                      />
                    </div>
                  )}

                  <List.Item.Meta
                    title={
                      <div
                        style={{
                          display: isSelectable ? "none" : "",
                        }}
                      >
                        <Tag color={StatusByHexColor[item.Status]}>
                          {Status[item.Status]}
                        </Tag>

                        {item.Reason}
                      </div>
                    }
                    description={
                      <div style={{ lineHeight: "1.3" }}>
                        <Row>
                          <Col span={24}>
                            <span
                              style={{
                                display:
                                  isSelectable || isFromAdmin ? "" : "none",
                              }}
                            >
                              <b>{item.EmpyName}</b>
                            </span>
                          </Col>
                          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                            <span>
                              <b style={{ color: "#999" }}>{item.Title}</b>
                            </span>
                            <Tag
                              color={item.IsHired ? "green" : "magenta"}
                              style={{
                                marginLeft: 10,
                                display: item.IsValidated ? "" : "none",
                              }}
                            >
                              {item.IsHired
                                ? t("jobRecruitmentForm.hiring_successful")
                                : t("jobRecruitmentForm.hiring_unsuccessful")}
                            </Tag>
                            <br />
                            <span>{item.Description}</span>
                          </Col>

                          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                            <div
                              style={{
                                display: isShowStatus ? "" : "none",
                                textAlign: "right",
                                marginTop: "-25px",
                              }}
                            >
                              <Button
                                key={"Attch_" + item.JobRecruitmentKey}
                                size={"large"}
                                icon={<FileOutlined />}
                                type="link"
                                style={{
                                  display:
                                    item.Attaches && item.Attaches.length > 0
                                      ? ""
                                      : "none",
                                }}
                                onClick={() => viewAttachment(item.Attaches)}
                              />
                              <Button
                                key={item.JobRecruitmentKey}
                                size={"large"}
                                icon={<EditOutlined />}
                                type="link"
                                onClick={() => viewItem(item.JobRecruitmentKey)}
                              />
                              <br />
                              <span style={{ fontSize: "12px" }}>
                                {dayjs(item.TransDate).format("DD/MM/YYYY")}
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    }
                  />
                </List.Item>
              </Card>
            )}
            bordered
          />
        </Col>
      </Row>

      <Row
        style={{
          display: footerElement || isFromAdmin || pagination ? "" : "none",
        }}
      >
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: isFromAdmin ? 24 : 16, offset: isFromAdmin ? 0 : 4 }}
        >
          {filteredDatas.length > 0 ? (
            <div
              style={{
                fontSize: "14px",
                padding: "10px 15px",
                border: "1px solid #c3d0d9",
                backgroundColor: "#f0f3f5",
              }}
            >
              {isFromAdmin && pagination ? (
                <PaginationFooter
                  footerData={footerValue}
                  pagination={pagination}
                  setPagination={setPagination}
                />
              ) : (
                footerElement && footerElement(filteredDatas.length)
              )}
            </div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

JobRecruitmentList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  isFromAdmin: PropTypes.bool,
  onAdd: PropTypes.func,
  onItemViewed: PropTypes.func.isRequired,
  isSelectable: PropTypes.bool,
  isMultipleAction: PropTypes.bool,
  selectedItems: PropTypes.array,
  onItemSelected: PropTypes.func,
  onListActionsRendered: PropTypes.func,
  isShowStatus: PropTypes.bool,
  filterCheckedText: PropTypes.string,
  footerElement: PropTypes.func,
  onAttachmentClicked: PropTypes.func,
  setCurrentYear: PropTypes.func.isRequired,
  currentYear: PropTypes.number,
  pagination: PropTypes.object,
  setPagination: PropTypes.func,
  resetPagination: PropTypes.func,
};
