import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Button,
  Row,
  Col,
  Input,
  Spin,
  Card,
  Modal,
  Upload,
  Divider,
  message,
  Form,
  Tag,
} from "antd";
import dayjs from "dayjs";
import { ApprovalReasonModal } from "../../common/ApprovalReasonModal";
import EntryFormFooter from "../../common/EntryFormFooter";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
  Status,
  isStatusSubmitted,
  isStatusApproved,
  isStatusPartialApproved,
  isStatusRequestCancel,
  isStatusRejected,
  isStatusVoid,
} from "../../model/common/Status";
import { RecordType } from "../../model/common/RecordType";
import { RowState } from "../../model/common/RowState";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { confirm } = Modal;

const ManageTrainingApplicationForm = ({
  isLoading,
  form,
  editItem,
  loadPageData,
  onSubmitted,
  onCancelled,
  onWithdraw,
  onRequestCancel,
  isOwner,
  isViewOnly = false,
  isShowOU,
  fileList,
  setFileList,
  onUploadAttachment,
  onRemoveAttachment,
  onViewProgram,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const isSystemRcd = editItem.RcdType === RecordType.SYSTEM;
  const isSubmitted = isStatusSubmitted(editItem.Status);
  const isVoid = isStatusVoid(editItem.Status);
  const isApproved =
    isStatusPartialApproved(editItem.Status) ||
    isStatusApproved(editItem.Status);
  const isRequestCancelRejected =
    isApproved &&
    editItem.RejectRequestCancelBy > 0 &&
    editItem.Reason.length > 0;
  const isShowReason =
    isStatusVoid(editItem.Status) ||
    isStatusRejected(editItem.Status) ||
    isStatusRequestCancel(editItem.Status) ||
    isRequestCancelRejected;
  const startFeedback =
    editItem.IsTrainingEnded &&
    !editItem.IsFeedbackSubmitted &&
    (!editItem.IsRequireApproval || (editItem.IsRequireApproval && isApproved));
  const [showReasonForm, setShowReasonForm] = useState(false);
  const [deletedAttachments, setDeletedAttachments] = useState([]);
  const { TrainingApplicationKey } = useParams();

  useEffect(() => {
    loadPageData(TrainingApplicationKey, form.resetFields);
    // eslint-disable-next-line
  }, [TrainingApplicationKey]);

  function showWithdrawConfirm() {
    confirm({
      title: t("general.withdraw_confirmation"),
      content: t("trainingApplicationForm.withdraw_message"),
      okText: t("general.yes"),
      cancelText: t("general.no"),
      onOk: onActionWithdraw,
      onCancel() {},
    });
  }

  function showSubmitConfirm() {
    form
      .validateFields()
      .then((values) => {
        if (values.Feedback.length === 0 && fileList.length === 0) {
          toast.error(
            t("trainingApplicationForm.feedback_required_validation")
          );
          return;
        }

        confirm({
          title: t("general.submit_confirmation"),
          content: t("trainingApplicationForm.submit_message"),
          okText: t("general.yes"),
          cancelText: t("general.no"),
          onOk() {
            handleSubmit(true);
          },
          onCancel() {},
        });
      })
      .catch(() => {});
  }

  function handleSubmit(isSubmit) {
    form
      .validateFields()
      .then((values) => {
        let submitData = {
          ...editItem,
          ...values,
        };

        uploadAttachments()
          .then((result) => {
            submitData.FeedbackAttaches = [
              ...submitData.FeedbackAttaches.slice(),
              ...result,
            ];
            onSubmitted(submitData, isSubmit);
          })
          .catch(() => {
            toast.error(t("general.fail_to_upload_file"));
          });
      })
      .catch(() => {});
  }

  function onActionWithdraw() {
    onWithdraw(editItem);
  }

  function submitCancelReason(reason) {
    setShowReasonForm(false);
    onRequestCancel(editItem, reason);
  }

  function uploadProp() {
    return {
      onRemove: (file) => {
        editItem.FeedbackAttaches.forEach((item) => {
          if (item.AttchKey === file.uid) {
            item.RowState = RowState.DELETED;
            setDeletedAttachments([...deletedAttachments, file.url]);
          }
        });
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        let tempRcd = fileList.filter((item) => item.name === file.name);
        if (tempRcd.length > 0) {
          message.error(t("general.duplicate_uploaded_file_name"));
        } else {
          file.RowState = 1;
          setFileList([...fileList, file]);
        }

        return false;
      },
      showUploadList: {
        showPreviewIcon: true,
        showRemoveIcon:
          !editItem.IsFeedbackSubmitted &&
          (!editItem.IsRequireApproval ||
            (editItem.IsRequireApproval && isApproved)) &&
          isOwner &&
          !isSystemRcd,
      },
      fileList,
    };
  }

  function uploadAttachments() {
    const formData = new FormData();

    let newFiles = fileList.filter((x) => x.RowState === 1);
    newFiles.forEach((file) => {
      formData.append("files[]", file);
    });

    if (deletedAttachments.length) removeAttachments(deletedAttachments);
    if (!newFiles.length) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }
    return onUploadAttachment(formData);
  }

  function removeAttachments(file) {
    return onRemoveAttachment(file).then(() => {
      setDeletedAttachments([]);
    });
  }

  return (
    <div>
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 16, offset: 4 }}
        >
          <Card
            title={
              <div>
                <span className="form-title">
                  {t("trainingApplicationForm.training_application")}
                </span>
                <Tag
                  onClick={() => onViewProgram(editItem.TrainingKey)}
                  color="blue"
                  style={{
                    marginLeft: 10,
                    verticalAlign: "text-top",
                    cursor: "pointer",
                  }}
                >
                  {t("trainingApplicationForm.program_info")}
                </Tag>
              </div>
            }
          >
            <Spin spinning={isLoading}>
              <Form
                form={form}
                layout="vertical"
                onValuesChange={() => setIsDirty(true)}
              >
                <Row gutter={12}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={12}>
                    <Form.Item
                      label={<span>{t("trainingForm.training_date")}</span>}
                      name="TrainingDate"
                      initialValue={editItem.TrainingDate}
                    >
                      <RangePicker
                        style={{ width: "100%" }}
                        allowClear={true}
                        showToday={false}
                        showTime={{
                          defaultValue: dayjs("00:00:00", "HH:mm:ss"),
                          format: "HH:mm",
                        }}
                        format="DD/MM/YYYY HH:mm"
                        disabled={true}
                        placeholder={[
                          t("general.from_date"),
                          t("general.to_date"),
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={12}>
                    <Form.Item
                      label={<span>{t("general.status")}</span>}
                      name="StatusDesc"
                      initialValue={Status[editItem.Status]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                </Row>

                {isShowOU && (
                  <Col span={24}>
                    <Form.Item
                      label={<span>{t("general.operating_unit")}</span>}
                      name="OUCodeDesc"
                      initialValue={editItem.OUCode + " - " + editItem.OUDesc}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                )}

                <Col
                  span={24}
                  style={{
                    display: isShowReason ? "" : "none",
                  }}
                >
                  <Form.Item
                    label={
                      isRequestCancelRejected
                        ? t("general.reason_of_cancel_request_rejected")
                        : t("general.reason")
                    }
                  >
                    <TextArea
                      autoSize={{ minRows: 2, maxRows: 6 }}
                      disabled={true}
                      value={editItem.Reason}
                    />
                  </Form.Item>
                </Col>

                <Row gutter={12}>
                  <Col span={24}>
                    <Form.Item
                      label={<span>{t("general.title")}</span>}
                      name="Title"
                      initialValue={editItem.Title}
                    >
                      <TextArea
                        placeholder={t("general.title_placeholder")}
                        autoSize={{ minRows: 1, maxRows: 2 }}
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={<span>{t("trainingForm.location")}</span>}
                      name="Location"
                      initialValue={editItem.Location}
                    >
                      <TextArea
                        placeholder={t("trainingForm.location_placeholder")}
                        autoSize={{ minRows: 1, maxRows: 5 }}
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    span={24}
                    style={{ display: editItem.IsTrainingEnded ? "" : "none" }}
                  >
                    <Form.Item
                      label={
                        <span>
                          {t("trainingApplicationForm.training_feedback")}
                        </span>
                      }
                      name="Feedback"
                      initialValue={editItem.Feedback}
                    >
                      <TextArea
                        placeholder={t(
                          "trainingApplicationForm.training_feedback_placeholder"
                        )}
                        autoSize={{ minRows: 2, maxRows: 10 }}
                        disabled={
                          editItem.IsFeedbackSubmitted ||
                          (editItem.IsRequireApproval && !isApproved) ||
                          !isOwner ||
                          isViewOnly ||
                          isSystemRcd
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Divider
                    style={{ display: editItem.IsTrainingEnded ? "" : "none" }}
                  />
                  <Col
                    span={24}
                    style={{ display: editItem.IsTrainingEnded ? "" : "none" }}
                  >
                    <Form.Item
                      label={
                        <span>
                          {t("trainingApplicationForm.feeback_attachments")}
                        </span>
                      }
                      name="Attachment"
                      rules={[{ required: false }]}
                    >
                      <Upload {...uploadProp()} listType="picture">
                        <Button
                          disabled={
                            editItem.IsFeedbackSubmitted ||
                            (editItem.IsRequireApproval && !isApproved) ||
                            !isOwner ||
                            isViewOnly ||
                            isSystemRcd
                          }
                        >
                          <UploadOutlined /> {t("general.browse")}
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="save-form-section">
                  <Col style={{ textAlign: "right", padding: "10px" }}>
                    <Row>
                      <Col span={24}>
                        <Button
                          size="large"
                          type="link"
                          onClick={onCancelled}
                          style={{
                            marginRight: 10,
                          }}
                        >
                          {t("general.back")}
                        </Button>
                        <Button
                          size="large"
                          type="primary"
                          onClick={showSubmitConfirm}
                          style={{
                            marginRight: 10,
                            display:
                              startFeedback &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("trainingApplicationForm.submit_feedback")}
                        </Button>
                        <Button
                          type={startFeedback ? "default" : "primary"}
                          size="large"
                          onClick={showWithdrawConfirm}
                          style={{
                            marginRight: 10,
                            display:
                              isSubmitted &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd &&
                              !editItem.IsTrainingEnded
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.withdraw")}
                        </Button>
                        <Button
                          type={startFeedback ? "default" : "primary"}
                          size="large"
                          onClick={() => setShowReasonForm(true)}
                          style={{
                            display:
                              isApproved &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd &&
                              !editItem.IsTrainingEnded
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.request_cancel")}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Card>
          <EntryFormFooter
            footerData={editItem}
            isApproved={isApproved}
            isVoid={isVoid}
            isPending={
              editItem.Status === 4 || editItem.Status === 1 ? true : false
            }
            showFooter={editItem.TrainingApplicationKey !== -1 ? true : false}
            t={t}
          ></EntryFormFooter>
        </Col>
      </Row>

      <ApprovalReasonModal
        isVisible={showReasonForm}
        reasonType={"Cancel Training Application"}
        onSaved={submitCancelReason}
        onCancelled={() => setShowReasonForm(false)}
      />
    </div>
  );
};

ManageTrainingApplicationForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  editItem: PropTypes.object.isRequired,
  loadPageData: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onWithdraw: PropTypes.func.isRequired,
  onRequestCancel: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isShowOU: PropTypes.bool.isRequired,
  isViewOnly: PropTypes.bool,
  fileList: PropTypes.array.isRequired,
  setFileList: PropTypes.func.isRequired,
  onUploadAttachment: PropTypes.func.isRequired,
  onRemoveAttachment: PropTypes.func.isRequired,
  onViewProgram: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default ManageTrainingApplicationForm;
