import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import TrainingApplicationApprovalForm from "../../forms/Training/TrainingApplicationApprovalForm";
import {
  getPendingAndApprovedTrainingApplications,
  approveTrainingApplications,
  rejectTrainingApplications,
} from "../../../api/trainingApplicationApprovalApi";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function TrainingApplicationApprovalPage({ history }) {
  const location = useLocation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [trainingApplications, setTrainingApplications] = useState([]);
  const [selectedTrainingApplications, setSelectedTrainingApplications] =
    useState([]);
  const [invalidTrainingApplications, setInvalidTrainingApplications] =
    useState([]);
  const [currentYear, setCurrentYear] = useState(
    location.state ? location.state.year : dayjs().year()
  );
  const [pagination, setPagination] = useState({
    current: 1,
    minIndex: 0,
    maxIndex: 100,
    pageSize: 100,
  });

  useEffect(getTrainingApplications, [currentYear]);

  function getTrainingApplications() {
    setIsLoading(true);
    getPendingAndApprovedTrainingApplications(currentYear)
      .then((value) => {
        // reset pagination
        resetPagination();

        setTrainingApplications(value ? value : []);
        setSelectedTrainingApplications([]);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function resetPagination() {
    setPagination({
      current: 1,
      minIndex: 0,
      maxIndex: 100,
      pageSize: 100,
    });
  }

  function onAddNewTrainingApplication() {
    history("/home/trainingapplication/trainingtrans");
  }

  function onApproveTrainingApplications(selectedTrainingApplications) {
    setIsLoading(true);

    approveTrainingApplications(selectedTrainingApplications)
      .then((result) => {
        if (result && result.data && result.data.length > 0) {
          setInvalidTrainingApplications(result.data);

          //if have success approve data
          if (selectedTrainingApplications.length > result.data.length) {
            toast.success(t("general.record_approved_successfully"));
          }

          toast.error(t("trainingApplicationForm.training_program_is_full"));
        } else {
          setInvalidTrainingApplications([]);
          toast.success(t("general.record_approved_successfully"));
        }

        getTrainingApplications();
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_approve_record"), error);
      });
  }

  function onRejectTrainingApplications(selectedTrainingApplications, reason) {
    setIsLoading(true);

    rejectTrainingApplications(selectedTrainingApplications, reason)
      .then(() => {
        getTrainingApplications();
        toast.success(t("general.record_rejected_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_reject_record"), error);
      });
  }

  function viewTrainingApplication(trainingApplicationKey) {
    history(
      "/home/trainingapplication/trainingtrans/" + trainingApplicationKey,
      { state: { fromApproval: true } }
    );
  }

  function viewTrainingProgram(trainingKey) {
    history("/home/trainings/training/" + trainingKey, {
      state: {
        fromApproval: true,
        fromList: true,
        year: currentYear,
      },
    });
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <TrainingApplicationApprovalForm
      isLoading={isLoading}
      data={trainingApplications}
      selectedItems={selectedTrainingApplications}
      onAdd={onAddNewTrainingApplication}
      onView={viewTrainingApplication}
      onViewProgram={viewTrainingProgram}
      onApproved={onApproveTrainingApplications}
      onRejected={onRejectTrainingApplications}
      onItemSelected={setSelectedTrainingApplications}
      invalidTrainingApplications={invalidTrainingApplications}
      setCurrentYear={setCurrentYear}
      currentYear={currentYear}
      pagination={pagination}
      setPagination={setPagination}
      resetPagination={resetPagination}
    />
  );
}

TrainingApplicationApprovalPage.propTypes = {
  history: PropTypes.func.isRequired,
};
