import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Pagination } from "antd";
import { MedicalAmountDeductionModal } from "./MedicalAmountDeductionModal";
import { ClinicList } from "./ClinicList";
import "../../../App.css";
import {
  Status,
  StatusByHexColor,
} from "../../../components/model/common/Status";
import { useTranslation } from "react-i18next";

export default function MedicalDeductionForm({
  isLoading,
  data,
  selectedItems,
  onAdd,
  onView,
  onSave,
  onItemSelected,
  userCompCurr,
  getEntitledMedicalClaimType,
  getMedicalClaimTypeLimit,
  setCurrentYear,
  pagination,
  setPagination,
  resetPagination,
}) {
  const { t } = useTranslation();
  const [showAmountDeductionForm, setShowAmountDeductionForm] = useState(false);
  const [tempItem, setTempItem] = useState({});
  const [medicalClaimTypes, setMedicalClaimTypes] = useState([]);

  const footerElement = (footerValue, totalPagination) => (
    <div style={{ textAlign: "right", marginRight: "10px" }}>
      <Pagination
        style={{ display: "inline-flex", paddingRight: 10 }}
        defaultCurrent={pagination.current}
        current={pagination.current}
        total={totalPagination}
        onChange={onChangePagination}
        showTotal={(total) =>
          t("general.total") + ` ${total} ` + t("general.records")
        }
        defaultPageSize={pagination.pageSize}
        pageSize={pagination.pageSize}
      />
    </div>
  );

  function onChangePagination(page, pageSize) {
    setPagination({
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
      pageSize: pageSize,
    });
  }

  function submitAddAmount(values) {
    setShowAmountDeductionForm(false);
    if (tempItem) {
      //Add single item
      onSave(tempItem, values);
    } else {
      //Add multiple items
      onSave(selectedItems, values);
    }
  }

  function addMedicalDeduction(selectedClinic) {
    setTempItem(selectedClinic);
    getEntitledMedicalClaimType(
      selectedClinic.EmpyKey,
      selectedClinic.ClinicType
    ).then((claimTypes) => {
      setMedicalClaimTypes(claimTypes ? claimTypes : []);
      setShowAmountDeductionForm(true);
    });
  }

  function getListActions(item) {
    return [
      <div key>
        <span
          style={{
            float: "left",
            fontStyle: "italic",
            marginLeft: "30px",
            paddingTop: "5px",
            color: StatusByHexColor[item.Status],
          }}
        >
          {Status[item.Status]}
        </span>

        <Button
          type="primary"
          size="default"
          onClick={() => addMedicalDeduction(item)}
        >
          {t("general.edit")}
        </Button>
      </div>,
    ];
  }

  return (
    <div>
      <ClinicList
        isLoading={isLoading}
        data={data}
        onAdd={onAdd}
        onItemViewed={onView}
        footerElement={footerElement}
        isSelectable={false}
        selectedItems={selectedItems}
        onItemSelected={onItemSelected}
        onListActionsRendered={getListActions}
        setCurrentYear={setCurrentYear}
        pagination={pagination}
        setPagination={setPagination}
        resetPagination={resetPagination}
      />

      <MedicalAmountDeductionModal
        isVisible={showAmountDeductionForm}
        onSaved={submitAddAmount}
        onCancelled={() => setShowAmountDeductionForm(false)}
        selectedData={tempItem}
        userCompCurr={userCompCurr}
        allClaimTypes={medicalClaimTypes}
        getMedicalClaimTypeLimit={getMedicalClaimTypeLimit}
      />
    </div>
  );
}

MedicalDeductionForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  userCompCurr: PropTypes.object.isRequired,
  getEntitledMedicalClaimType: PropTypes.func.isRequired,
  getMedicalClaimTypeLimit: PropTypes.func.isRequired,
  setCurrentYear: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  resetPagination: PropTypes.func.isRequired,
};
