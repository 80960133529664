import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import ClaimCancellationApprovalForm from "../../forms/Claim/ClaimCancellationApprovalForm";
import {
  getCancelledClaims,
  approveCancelledClaims,
  rejectCancelledClaims,
  checkPayrollMonthEndClose,
} from "../../../api/claimApprovalApi";
import { getClaimConfg } from "../../../api/claimConfgApi";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import { Modal } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const { confirm } = Modal;

export default function ClaimCancellationApprovalPage({ history }) {
  const location = useLocation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [claimTrans, setClaimTrans] = useState([]);
  const [selectedClaimTrans, setSelectedClaimTrans] = useState([]);
  const [invalidClaims, setInvalidClaims] = useState([]);
  const [claimConfg, setClaimConfg] = useState({});
  const [currentYear, setCurrentYear] = useState(
    location.state ? location.state.year : dayjs().year()
  );
  const [pagination, setPagination] = useState({
    current: 1,
    minIndex: 0,
    maxIndex: 100,
    pageSize: 100,
  });

  useEffect(getClaimTrans, [currentYear]);

  function getClaimTrans() {
    setIsLoading(true);
    let promises = [getCancelledClaims(currentYear), getClaimConfg()];

    Promise.all(promises)
      .then((values) => {
        // reset pagination
        resetPagination();

        setClaimTrans(values[0] ? values[0] : []);
        setClaimConfg(values[1] ? values[1] : {});
        setSelectedClaimTrans([]);
        setInvalidClaims([]);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function resetPagination() {
    setPagination({
      current: 1,
      minIndex: 0,
      maxIndex: 100,
      pageSize: 100,
    });
  }

  function onAddNewClaim() {
    history("/home/claimsubmission/claimTrans");
  }

  function checkMthEndClose(selectedItems) {
    setIsLoading(true);

    checkPayrollMonthEndClose(selectedItems)
      .then((value) => {
        setIsLoading(false);
        if (value.length > 0) {
          setInvalidClaims(value);
          confirm({
            title: t("claimSubmissionForm.void_confirmation_closed_payroll"),
            content: t("claimSubmissionForm.void_message_closed_payroll"),
            okText: t("general.proceed"),
            okType: "danger",
            cancelText: t("general.cancel"),
            onOk() {
              approveClaimTrans(selectedItems);
            },
            onCancel() {},
          });
        } else {
          setInvalidClaims([]);
          approveClaimTrans(selectedItems);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(
          t("claimSubmissionForm.fail_to_check_payroll_month_end_status"),
          error
        );
      });
  }

  function approveClaimTrans(selectedClaims) {
    setIsLoading(true);

    approveCancelledClaims(selectedClaims)
      .then(() => {
        getClaimTrans();
        toast.success(t("general.record_approved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_approve_record"), error);
      });
  }

  function rejectClaimTrans(selectedClaims, reason) {
    setIsLoading(true);

    rejectCancelledClaims(selectedClaims, reason)
      .then(() => {
        getClaimTrans();
        toast.success(t("general.record_rejected_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_reject_record"), error);
      });
  }

  function viewClaimTrans(claimTransKey) {
    history("/home/claimsubmission/claimTrans/" + claimTransKey, {
      state: { fromCancel: true },
    });
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <ClaimCancellationApprovalForm
      isLoading={isLoading}
      data={claimTrans}
      selectedItems={selectedClaimTrans}
      onAdd={onAddNewClaim}
      onView={viewClaimTrans}
      onApproved={
        claimConfg && claimConfg.IsPostToQuarto
          ? checkMthEndClose
          : approveClaimTrans
      }
      onRejected={rejectClaimTrans}
      onItemSelected={setSelectedClaimTrans}
      invalidClaims={invalidClaims}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
      currentYear={currentYear}
      pagination={pagination}
      setPagination={setPagination}
      resetPagination={resetPagination}
    />
  );
}

ClaimCancellationApprovalPage.propTypes = {
  history: PropTypes.func.isRequired,
};
