import React from "react";
import PropTypes from "prop-types";
import { LeaveList } from "./LeaveList";
import { useTranslation } from "react-i18next";
import { Pagination } from "antd";

const LeaveApplicationDisplayForm = ({
  isLoading,
  leaveApplications,
  isFromAdmin = false,
  onAdd,
  onView,
  onAttachmentClicked,
  setCurrentYear,
  pagination,
  setPagination,
  resetPagination,
}) => {
  const { t } = useTranslation();
  const footerElement = (footerValue, totalPagination) => (
    <div style={{ textAlign: "right", marginRight: "10px" }}>
      <Pagination
        style={{ display: "inline-flex", paddingRight: 10 }}
        defaultCurrent={pagination.current}
        current={pagination.current}
        total={totalPagination}
        onChange={onChangePagination}
        showTotal={(total) =>
          t("general.total") + ` ${total} ` + t("general.records")
        }
        defaultPageSize={pagination.pageSize}
        pageSize={pagination.pageSize}
      />
      {t("general.total")} :{" "}
      <b style={{ color: "#0070cc" }}>
        {footerValue} {t("general.days")}
      </b>
    </div>
  );

  function onChangePagination(page, pageSize) {
    setPagination({
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
      pageSize: pageSize,
    });
  }

  return (
    <div>
      <LeaveList
        isLoading={isLoading}
        data={leaveApplications}
        isFromAdmin={isFromAdmin}
        onAdd={onAdd}
        onItemViewed={onView}
        isShowStatus={true}
        footerElement={footerElement}
        onAttachmentClicked={onAttachmentClicked}
        setCurrentYear={setCurrentYear}
        pagination={pagination}
        setPagination={setPagination}
        resetPagination={resetPagination}
      />
    </div>
  );
};

LeaveApplicationDisplayForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  leaveApplications: PropTypes.array.isRequired,
  isFromAdmin: PropTypes.bool,
  onAdd: PropTypes.func,
  onView: PropTypes.func.isRequired,
  onAttachmentClicked: PropTypes.func.isRequired,
  setCurrentYear: PropTypes.func.isRequired,
  pagination: PropTypes.object,
  setPagination: PropTypes.func,
  resetPagination: PropTypes.func,
};

export default LeaveApplicationDisplayForm;
