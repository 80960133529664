import React, { useState, useEffect } from "react";
import { getAllLeaveApplicationByUser } from "../../../api/leaveApplicationApi";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import LeaveApplicationDisplayForm from "../../forms/Leave/LeaveApplicationDisplayForm";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const LeaveApplicationDisplayPage = ({ history }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [leaveApplication, setLeaveApplication] = useState([]);
  const [currentYear, setCurrentYear] = useState(dayjs().year());
  const [pagination, setPagination] = useState({
    current: 1,
    minIndex: 0,
    maxIndex: 100,
    pageSize: 100,
  });

  useEffect(getLeaveApplications, [currentYear]);

  function getLeaveApplications() {
    setIsLoading(true);
    getAllLeaveApplicationByUser(currentYear)
      .then((result) => {
        // reset pagination
        resetPagination();

        setIsLoading(false);
        setLeaveApplication(result);
      })
      .catch((error) => {
        setIsLoading(false);
        setLeaveApplication([]);
        showErrorMessage(t("general.fail_to_load_record") + error.message);
      });
  }

  function resetPagination() {
    setPagination({
      current: 1,
      minIndex: 0,
      maxIndex: 100,
      pageSize: 100,
    });
  }

  function onAddNewLeave() {
    history("/home/leaveapplication/leavetrans");
  }

  function onViewLeave(leaveTransKey) {
    history("/home/leaveapplication/leavetrans/" + leaveTransKey);
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message) {
    toast.error(message);
  }

  return (
    <LeaveApplicationDisplayForm
      isLoading={isLoading}
      leaveApplications={leaveApplication}
      isFromAdmin={false}
      onAdd={onAddNewLeave}
      onView={onViewLeave}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
      pagination={pagination}
      setPagination={setPagination}
      resetPagination={resetPagination}
    />
  );
};

LeaveApplicationDisplayPage.propTypes = {
  history: PropTypes.func.isRequired,
};

export default LeaveApplicationDisplayPage;
