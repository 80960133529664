import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import PropMaintCancellationApprovalForm from "../../forms/PropMaint/PropMaintCancellationApprovalForm";
import {
  getCancelledPropMaints,
  approveCancelledPropMaints,
  rejectCancelledPropMaints,
} from "../../../api/propMaintApprovalApi";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function PropMaintCancellationApprovalPage({ history }) {
  const location = useLocation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [propMaints, setPropMaints] = useState([]);
  const [selectedPropMaints, setSelectedPropMaints] = useState([]);
  const [currentYear, setCurrentYear] = useState(
    location.state ? location.state.year : dayjs().year()
  );
  const [pagination, setPagination] = useState({
    current: 1,
    minIndex: 0,
    maxIndex: 100,
    pageSize: 100,
  });

  useEffect(getPropMaints, [currentYear]);

  function getPropMaints() {
    setIsLoading(true);
    getCancelledPropMaints(currentYear)
      .then((value) => {
        // reset pagination
        resetPagination();

        setPropMaints(value ? value : []);
        setSelectedPropMaints([]);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function resetPagination() {
    setPagination({
      current: 1,
      minIndex: 0,
      maxIndex: 100,
      pageSize: 100,
    });
  }

  function onAddNewPropMaint() {
    history("/home/propmaint/proptrans");
  }

  function onApprovePropMaints(selectedPropMaints) {
    setIsLoading(true);

    approveCancelledPropMaints(selectedPropMaints)
      .then(() => {
        getPropMaints();
        toast.success(t("general.record_approved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_approve_record"), error);
      });
  }

  function onRejectPropMaints(selectedPropMaints, reason) {
    setIsLoading(true);

    rejectCancelledPropMaints(selectedPropMaints, reason)
      .then(() => {
        getPropMaints();
        toast.success(t("general.record_rejected_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_reject_record"), error);
      });
  }

  function viewPropMaints(propMaintKey) {
    history("/home/propmaint/proptrans/" + propMaintKey, {
      state: { fromCancel: true },
    });
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <PropMaintCancellationApprovalForm
      isLoading={isLoading}
      data={propMaints}
      selectedItems={selectedPropMaints}
      onAdd={onAddNewPropMaint}
      onView={viewPropMaints}
      onApproved={onApprovePropMaints}
      onRejected={onRejectPropMaints}
      onItemSelected={setSelectedPropMaints}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
      currentYear={currentYear}
      pagination={pagination}
      setPagination={setPagination}
      resetPagination={resetPagination}
    />
  );
}

PropMaintCancellationApprovalPage.propTypes = {
  history: PropTypes.func.isRequired,
};
