import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import PropMaintValidationForm from "../../forms/PropMaint/PropMaintValidationForm";
import {
  getPendingAndValidatedPropMaints,
  validatePropMaints,
} from "../../../api/propMaintApprovalApi";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export default function PropMaintValidationPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [propMaints, setPropMaints] = useState([]);
  const [selectedPropMaints, setSelectedPropMaints] = useState([]);
  const [currentYear, setCurrentYear] = useState(dayjs().year());
  const [pagination, setPagination] = useState({
    current: 1,
    minIndex: 0,
    maxIndex: 100,
    pageSize: 100,
  });

  useEffect(getPropMaints, [currentYear]);

  function getPropMaints() {
    setIsLoading(true);
    getPendingAndValidatedPropMaints(currentYear)
      .then((value) => {
        // reset pagination
        resetPagination();

        setPropMaints(value ? value : []);
        setSelectedPropMaints([]);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function resetPagination() {
    setPagination({
      current: 1,
      minIndex: 0,
      maxIndex: 100,
      pageSize: 100,
    });
  }

  function onAddNewPropMaint() {
    history("/home/propmaint/proptrans");
  }

  function onValidatePropMaints(
    selectedPropMaints,
    isMaintRequired,
    reason,
    startDate,
    endDate
  ) {
    setIsLoading(true);

    let submitData = selectedPropMaints.map((x) => {
      x.IsRequireMaint = isMaintRequired;

      if (startDate) {
        x.RepairStartDate = startDate;
      }
      if (endDate) {
        x.RepairEndDate = endDate;
      }
      return x;
    });

    validatePropMaints(submitData, reason ? reason : "")
      .then(() => {
        getPropMaints();
        toast.success(t("general.record_validated_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_validate_record"), error);
      });
  }

  function viewPropMaint(propMaintKey) {
    history("/home/propmaint/proptrans/" + propMaintKey, {
      state: { fromValidation: true },
    });
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <PropMaintValidationForm
      isLoading={isLoading}
      data={propMaints}
      selectedItems={selectedPropMaints}
      onAdd={onAddNewPropMaint}
      onView={viewPropMaint}
      onValidated={onValidatePropMaints}
      onItemSelected={setSelectedPropMaints}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
      pagination={pagination}
      setPagination={setPagination}
      resetPagination={resetPagination}
    />
  );
}

PropMaintValidationPage.propTypes = {
  history: PropTypes.func.isRequired,
};
