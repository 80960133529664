import React, { useEffect, useState } from "react";
import VehicleRequisitionForm from "../../forms/VehicleReq/VehicleRequisitionForm";
import PropTypes from "prop-types";
import { getVehicleRequisitions } from "../../../api/vehicleRequisitionApi";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import { toast } from "react-toastify";
import Axios from "axios";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const VehicleRequisitionPage = ({ history }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [vehicleRequisitionDisplay, setVehicleRequisitionDisplay] = useState(
    []
  );
  const [currentYear, setCurrentYear] = useState(dayjs().year());
  const [pagination, setPagination] = useState({
    current: 1,
    minIndex: 0,
    maxIndex: 100,
    pageSize: 100,
  });

  useEffect(() => {
    setIsLoading(true);
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    getVehicleRequisitions(source, currentYear)
      .then((result) => {
        // reset pagination
        resetPagination();

        setVehicleRequisitionDisplay(result);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
    return () => {
      source.cancel();
    };
  }, [currentYear, t]);

  function resetPagination() {
    setPagination({
      current: 1,
      minIndex: 0,
      maxIndex: 100,
      pageSize: 100,
    });
  }

  function onAddNewVehicleRequisition() {
    history("/home/vehiclerequisition/vehicletrans");
  }

  function viewVehicleRequisition(VehicleRequisitionKey) {
    history("/home/vehiclerequisition/vehicletrans/" + VehicleRequisitionKey);
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <VehicleRequisitionForm
      isLoading={isLoading}
      data={vehicleRequisitionDisplay}
      onAdd={onAddNewVehicleRequisition}
      onView={viewVehicleRequisition}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
      pagination={pagination}
      setPagination={setPagination}
      resetPagination={resetPagination}
    />
  );
};

VehicleRequisitionPage.propTypes = {
  history: PropTypes.func.isRequired,
};

export default VehicleRequisitionPage;
