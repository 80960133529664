import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Row, Col, Pagination } from "antd";
import { ApprovalReasonModal } from "../../common/ApprovalReasonModal";
import { VehicleRequisitionList } from "./VehicleRequisitionList";
import "../../../App.css";
import {
  Status,
  StatusByHexColor,
} from "../../../components/model/common/Status";
import { useTranslation } from "react-i18next";

export default function VehicleRequisitionApprovalForm({
  isLoading,
  data,
  selectedItems,
  onAdd,
  onView,
  onApproved,
  onRejected,
  onItemSelected,
  onAttachmentClicked,
  setCurrentYear,
  currentYear,
  pagination,
  setPagination,
  resetPagination,
}) {
  const { t } = useTranslation();
  const [showReasonForm, setShowReasonForm] = useState(false);
  const [tempItem, setTempItem] = useState();
  const [isAllowAmend, setIsAllowAmend] = useState(false);

  function submitRejectReason(reason) {
    setShowReasonForm(false);
    if (tempItem) {
      //reject single item
      onRejected([tempItem], reason, isAllowAmend);
    } else {
      //reject multiple items
      onRejected(selectedItems, reason, isAllowAmend);
    }
  }

  function selectAllItems(isSelected) {
    if (onItemSelected) {
      if (isSelected) {
        onItemSelected(data.filter((x) => !x.IsViewOnly));
      } else {
        onItemSelected([]);
      }
    }
  }

  function rejectVehicleRequisition(selectedVehicleRequisition, isAllowAmend) {
    setTempItem(selectedVehicleRequisition);
    setIsAllowAmend(isAllowAmend);
    setShowReasonForm(true);
  }

  function rejectVehicleRequisitions(isAllowAmend) {
    setTempItem(null);
    setIsAllowAmend(isAllowAmend);
    setShowReasonForm(true);
  }

  function getListActions(item) {
    return [
      <div key>
        <span
          style={{
            float: "left",
            fontStyle: "italic",
            marginLeft: "50px",
            color: StatusByHexColor[item.Status],
          }}
        >
          {Status[item.Status]}
        </span>

        <Button
          type="default"
          size="default"
          style={{
            marginRight: 10,
            display: !item.IsViewOnly ? "" : "none",
          }}
          onClick={() => rejectVehicleRequisition(item, true)}
        >
          {t("general.reject_with_amendment")}
        </Button>
        <Button
          type="default"
          size="default"
          style={{
            marginRight: 10,
            display: !item.IsViewOnly ? "" : "none",
          }}
          onClick={() => rejectVehicleRequisition(item, false)}
        >
          {t("general.reject")}
        </Button>
        <Button
          type="primary"
          size="default"
          style={{
            display: !item.IsViewOnly ? "" : "none",
          }}
          onClick={() => onApproved([item])}
        >
          {t("general.approve")}
        </Button>
      </div>,
    ];
  }

  const footerElement = (footerValue, totalPagination) => (
    <Row>
      <Col span={2} style={{ paddingTop: "5px" }}>
        <Checkbox
          indeterminate={
            selectedItems.length &&
            selectedItems.length < data.filter((x) => !x.IsViewOnly).length
          }
          checked={
            selectedItems.length &&
            selectedItems.length === data.filter((x) => !x.IsViewOnly).length
          }
          onChange={(event) => selectAllItems(event.target.checked)}
        >
          {t("general.select_all")}
        </Checkbox>
      </Col>

      <Col span={22} style={{ textAlign: "right" }}>
        <Pagination
          style={{ display: "inline-flex", paddingRight: 10 }}
          defaultCurrent={pagination.current}
          current={pagination.current}
          total={totalPagination}
          onChange={onChangePagination}
          showTotal={(total) =>
            t("general.total") + ` ${total} ` + t("general.records")
          }
          defaultPageSize={pagination.pageSize}
          pageSize={pagination.pageSize}
        />
        {t("general.total")} :{" "}
        <b style={{ color: "#0070cc" }}>{footerValue} KM</b>
        <Button
          type="default"
          style={{ marginRight: "5px", marginLeft: "10px" }}
          disabled={selectedItems.length === 0 || isLoading}
          onClick={() => rejectVehicleRequisitions(true)}
        >
          {t("general.reject_with_amendment")}
        </Button>
        <Button
          type="default"
          style={{ marginRight: "5px" }}
          disabled={selectedItems.length === 0 || isLoading}
          onClick={() => rejectVehicleRequisitions(false)}
        >
          {t("general.reject")}
        </Button>
        <Button
          type="primary"
          disabled={selectedItems.length === 0 || isLoading}
          onClick={() => onApproved(selectedItems)}
        >
          {t("general.approve")}
        </Button>
      </Col>
    </Row>
  );

  function onChangePagination(page, pageSize) {
    setPagination({
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
      pageSize: pageSize,
    });
  }

  return (
    <div>
      <VehicleRequisitionList
        isLoading={isLoading}
        data={data}
        onAdd={onAdd}
        onItemViewed={onView}
        isSelectable={true}
        selectedItems={selectedItems}
        onItemSelected={onItemSelected}
        onListActionsRendered={getListActions}
        filterCheckedText={t(
          "vehicleRequisitionForm.show_pending_vehicle_only"
        )}
        footerElement={footerElement}
        onAttachmentClicked={onAttachmentClicked}
        setCurrentYear={setCurrentYear}
        currentYear={currentYear}
        pagination={pagination}
        resetPagination={resetPagination}
      />

      <ApprovalReasonModal
        isVisible={showReasonForm}
        reasonType={"Reject"}
        onSaved={submitRejectReason}
        onCancelled={() => setShowReasonForm(false)}
      />
    </div>
  );
}

VehicleRequisitionApprovalForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onApproved: PropTypes.func.isRequired,
  onRejected: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  isSelectable: PropTypes.bool,
  onAttachmentClicked: PropTypes.func.isRequired,
  setCurrentYear: PropTypes.func.isRequired,
  currentYear: PropTypes.number.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  resetPagination: PropTypes.func.isRequired,
};
