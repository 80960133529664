import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import TravelAuthorizationApprovalForm from "../../../forms/Claim/Travel/TravelAuthorizationApprovalForm";
import {
  getPendingAndApprovedTravels,
  approveTravels,
  rejectTravels,
} from "../../../../api/travelApprovalApi";
import { getSignedAttachmentUrl } from "../../../../api/attachApi";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function TravelAuthorizationApprovalPage({ history }) {
  const location = useLocation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [travelTrans, setTravelTrans] = useState([]);
  const [selectedTravelTrans, setSelectedTravelTrans] = useState([]);
  const [currentYear, setCurrentYear] = useState(
    location.state ? location.state.year : dayjs().year()
  );
  const [pagination, setPagination] = useState({
    current: 1,
    minIndex: 0,
    maxIndex: 100,
    pageSize: 100,
  });

  useEffect(getTravelTrans, [currentYear]);

  function getTravelTrans() {
    setIsLoading(true);
    getPendingAndApprovedTravels(currentYear)
      .then((value) => {
        // reset pagination
        resetPagination();

        setTravelTrans(value ? value : []);
        setSelectedTravelTrans([]);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function resetPagination() {
    setPagination({
      current: 1,
      minIndex: 0,
      maxIndex: 100,
      pageSize: 100,
    });
  }

  function onAddNewTravel() {
    history("/home/travelauth/travelTrans");
  }

  function approveTravelTrans(selectedTravels) {
    setIsLoading(true);

    approveTravels(selectedTravels)
      .then(() => {
        getTravelTrans();
        toast.success(t("general.record_approved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_approve_record"), error);
      });
  }

  function rejectTravelTrans(selectedTravels, reason, isAllowAmend) {
    setIsLoading(true);

    rejectTravels(selectedTravels, reason, isAllowAmend)
      .then(() => {
        getTravelTrans();
        toast.success(t("general.record_rejected_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_reject_record"), error);
      });
  }

  function viewTravelTrans(travelTransKey) {
    history("/home/travelauth/travelTrans/" + travelTransKey, {
      state: { fromApproval: true },
    });
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <TravelAuthorizationApprovalForm
      isLoading={isLoading}
      data={travelTrans}
      selectedItems={selectedTravelTrans}
      onAdd={onAddNewTravel}
      onView={viewTravelTrans}
      onApproved={approveTravelTrans}
      onRejected={rejectTravelTrans}
      onItemSelected={setSelectedTravelTrans}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
      currentYear={currentYear}
      pagination={pagination}
      setPagination={setPagination}
      resetPagination={resetPagination}
    />
  );
}

TravelAuthorizationApprovalPage.propTypes = {
  history: PropTypes.func.isRequired,
};
