import React from "react";
import { List, Row, Col, Card, Checkbox, Tag } from "antd";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import {
  RowState,
  RowStateByHexColor,
} from "../../../components/model/common/Status";

export const EmpySingleList = ({
  isLoading,
  data,
  isSelectable = false,
  selectedDetails = [],
  onDetailsSelected,
  selectedItems = [],
  onItemSelected,
  onListActionsRendered,
}) => {
  function selectItem(isSelected, key) {
    if (onDetailsSelected) {
      if (isSelected) {
        if (selectedDetails.findIndex((x) => x.EmpyAprKey === key) === -1) {
          let selectedDetail = data.find((x) => x.EmpyAprKey === key);
          onDetailsSelected([...selectedDetails, selectedDetail]);
        }
      } else {
        onDetailsSelected(selectedDetails.filter((x) => x.EmpyAprKey !== key));
        //remove parent item
        let selectedDetail = data.find((x) => x.EmpyAprKey === key);
        if (
          selectedDetails.findIndex(
            (x) => x.EmpyKey === selectedDetail.EmpyKey && x.EmpyAprKey !== key
          ) === -1
        ) {
          onItemSelected(
            selectedItems.filter((x) => x.EmpyKey !== selectedDetail.EmpyKey)
          );
        }
      }
    }
  }

  function convertMaritalStatus(code) {
    switch (code) {
      case "D":
        return "Divorce";
      case "S":
        return "Single";
      case "M":
        return "Married";
      default:
        return "Widowed";
    }
  }

  function convertEduStatus(code) {
    switch (code) {
      case "HIGHEDU":
        return "High Education";
      case "FORM6":
        return "Secondary (Form 6)";
      case "SECEDU":
        return "Secondary";
      case "PRIEDU":
        return "Primary";
      case "PRESCHL":
        return "Preschool";
      case "OTHERS":
        return "Others";
      default:
        return "Not Available";
    }
  }

  return (
    <div>
      <List
        loading={isLoading}
        split={false}
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
          >
            {isSelectable && (
              <Checkbox
                style={{ marginRight: 10 }}
                checked={
                  selectedDetails.findIndex(
                    (x) => x.EmpyAprKey === item.EmpyAprKey
                  ) >= 0
                }
                onChange={(event) =>
                  selectItem(event.target.checked, item.EmpyAprKey)
                }
              />
            )}
            <Card
              style={{ width: "100%" }}
              className="right-align-actions"
              actions={onListActionsRendered && onListActionsRendered(item)}
            >
              <Row
                style={{ padding: "10px 15px 0px 15px" }}
                type="flex"
                justify="space-between"
              >
                <Col style={{ width: "90%", paddingBottom: 2 }}>
                  <List.Item.Meta
                    title={
                      <div>
                        <Tag color={RowStateByHexColor[item.RowState]}>
                          {RowState[item.RowState]}
                        </Tag>

                        {item.Category}
                      </div>
                    }
                    description={
                      <span>
                        <i style={{ fontSize: "11px" }}>
                          {" "}
                          {dayjs(item.SubmitDate, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                          )}
                        </i>
                      </span>
                    }
                  />
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <Tag color="blue">
                    {item.AprStatus === "PD" ? "Pending" : "Partially Approved"}
                  </Tag>
                </Col>
              </Row>

              <Row type="flex" justify="space-between">
                <Col style={{ padding: "0 15px" }}>
                  <p style={{ margin: "0" }}>
                    <span>
                      {item.Category === "General Details" ? (
                        item.Field === "PhyBankAcc" ? (
                          <span>
                            {"Physical Bank Account : "}
                            <span
                              style={{
                                display:
                                  item.RowState === 2 &&
                                  item.OldPhyBankAcc !== item.PhyBankAcc
                                    ? ""
                                    : "none",
                              }}
                            >
                              {item.OldPhyBankAcc + " -> "}
                            </span>
                            <b>{item.PhyBankAcc}</b>
                          </span>
                        ) : item.Field === "BNMCode" ? (
                          <span>
                            {"Bank Name : "}
                            <span
                              style={{
                                display:
                                  item.RowState === 2 &&
                                  item.OldBankName !== item.BankName
                                    ? ""
                                    : "none",
                              }}
                            >
                              {item.OldBankName + " -> "}
                            </span>
                            <b>{item.BankName}</b>
                          </span>
                        ) : item.Field === "MaritalStatus" ? (
                          <span>
                            {"Marital Status : "}
                            <span
                              style={{
                                display:
                                  item.RowState === 2 &&
                                  item.OldMaritalStatus !== item.MaritalStatus
                                    ? ""
                                    : "none",
                              }}
                            >
                              {convertMaritalStatus(item.OldMaritalStatus) +
                                " -> "}
                            </span>
                            <b>{convertMaritalStatus(item.MaritalStatus)}</b>
                          </span>
                        ) : item.Field === "SpouseName" ? (
                          <span>
                            {"Spouse Name : "}
                            <span
                              style={{
                                display:
                                  item.RowState === 2 &&
                                  item.OldSpouseName !== item.SpouseName
                                    ? ""
                                    : "none",
                              }}
                            >
                              {item.OldSpouseName + " -> "}
                            </span>
                            <b>{item.SpouseName}</b>
                          </span>
                        ) : item.Field === "SpouseID" ? (
                          <span>
                            {"Spouse ID : "}
                            <span
                              style={{
                                display:
                                  item.RowState === 2 &&
                                  item.OldSpouseID !== item.SpouseID
                                    ? ""
                                    : "none",
                              }}
                            >
                              {item.OldSpouseID + " -> "}
                            </span>
                            <b>{item.SpouseID}</b>
                          </span>
                        ) : item.Field === "SpouseDOB" ? (
                          <span>
                            {"Spouse Date of Birth : "}
                            <span
                              style={{
                                display:
                                  item.RowState === 2 &&
                                  dayjs(item.OldSpouseDOB, "YYYY-MM-DD").format(
                                    "DD/MM/YYYY"
                                  ) !==
                                    dayjs(item.SpouseDOB, "YYYY-MM-DD").format(
                                      "DD/MM/YYYY"
                                    )
                                    ? ""
                                    : "none",
                              }}
                            >
                              {dayjs(item.OldSpouseDOB, "YYYY-MM-DD").format(
                                "DD/MM/YYYY"
                              ) + " -> "}
                            </span>
                            <b>
                              {dayjs(item.SpouseDOB, "YYYY-MM-DD").format(
                                "DD/MM/YYYY"
                              )}
                            </b>
                          </span>
                        ) : item.Field === "SpouseDOM" ? (
                          <span>
                            {"Spouse Marriage Date : "}
                            <span
                              style={{
                                display:
                                  item.RowState === 2 &&
                                  dayjs(item.OldSpouseDOM, "YYYY-MM-DD").format(
                                    "DD/MM/YYYY"
                                  ) !==
                                    dayjs(item.SpouseDOM, "YYYY-MM-DD").format(
                                      "DD/MM/YYYY"
                                    )
                                    ? ""
                                    : "none",
                              }}
                            >
                              {dayjs(item.OldSpouseDOM, "YYYY-MM-DD").format(
                                "DD/MM/YYYY"
                              ) + " -> "}
                            </span>
                            <b>
                              {dayjs(item.SpouseDOM, "YYYY-MM-DD").format(
                                "DD/MM/YYYY"
                              )}
                            </b>
                          </span>
                        ) : item.Field === "IsDisableSpouse" ? (
                          <span>
                            {"Spouse Disabled : "}
                            <span
                              style={{
                                display:
                                  item.RowState === 2 &&
                                  (item.OldIsDisableSpouse ? "Yes" : "No") !==
                                    (item.IsDisableSpouse ? "Yes" : "No")
                                    ? ""
                                    : "none",
                              }}
                            >
                              {(item.OldIsDisableSpouse ? "Yes" : "No") +
                                " -> "}
                            </span>
                            <b>{item.IsDisableSpouse ? "Yes" : "No"}</b>
                          </span>
                        ) : item.Field === "IsSpouseWork" ? (
                          <span>
                            {"Spouse Working : "}
                            <span
                              style={{
                                display:
                                  item.RowState === 2 &&
                                  (item.OldIsSpouseWork ? "Yes" : "No") !==
                                    (item.IsSpouseWork ? "Yes" : "No")
                                    ? ""
                                    : "none",
                              }}
                            >
                              {(item.OldIsSpouseWork ? "Yes" : "No") + " -> "}
                            </span>
                            <b>{item.IsSpouseWork ? "Yes" : "No"}</b>
                          </span>
                        ) : (
                          ""
                        )
                      ) : item.Category === "Children" ? (
                        <span>
                          <span
                            style={{
                              display:
                                item.RowState === 2 &&
                                item.OldEmpyChildName !== item.EmpyChildName
                                  ? ""
                                  : "none",
                            }}
                          >
                            {item.OldEmpyChildName + " -> "}
                          </span>
                          <b>{item.EmpyChildName}</b>
                        </span>
                      ) : (
                        <span>
                          <span
                            style={{
                              display:
                                item.RowState === 2 &&
                                item.OldEducation !== item.Education
                                  ? ""
                                  : "none",
                            }}
                          >
                            {item.OldEducation + " -> "}
                          </span>
                          <b>{item.Education}</b>
                        </span>
                      )}
                    </span>
                  </p>
                  <p style={{ margin: "0" }}>
                    {item.Category === "Children" ? (
                      <span>
                        {"Date Of Birth : "}
                        <span
                          style={{
                            display:
                              item.RowState === 2 &&
                              dayjs(item.OldEmpyChildDOB, "YYYY-MM-DD").format(
                                "DD/MM/YYYY"
                              ) !==
                                dayjs(item.EmpyChildDOB, "YYYY-MM-DD").format(
                                  "DD/MM/YYYY"
                                )
                                ? ""
                                : "none",
                          }}
                        >
                          {dayjs(item.OldEmpyChildDOB, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                          ) + " -> "}
                        </span>
                        {dayjs(item.OldEmpyChildDOB, "YYYY-MM-DD").format(
                          "DD/MM/YYYY"
                        ) !==
                        dayjs(item.EmpyChildDOB, "YYYY-MM-DD").format(
                          "DD/MM/YYYY"
                        ) ? (
                          <b>
                            {dayjs(item.EmpyChildDOB, "YYYY-MM-DD").format(
                              "DD/MM/YYYY"
                            )}
                          </b>
                        ) : (
                          dayjs(item.EmpyChildDOB, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                          )
                        )}
                      </span>
                    ) : item.Category === "Education" ? (
                      <span>
                        {"Remarks : "}
                        <span
                          style={{
                            display:
                              item.RowState === 2 &&
                              item.OldRemarks !== item.Remarks
                                ? ""
                                : "none",
                          }}
                        >
                          {item.OldRemarks + " -> "}
                        </span>
                        {item.OldRemarks !== item.Remarks ? (
                          <b>{item.Remarks}</b>
                        ) : (
                          item.Remarks
                        )}
                      </span>
                    ) : (
                      ""
                    )}
                  </p>
                  <p style={{ margin: "0" }}>
                    {item.Category === "Children" ? (
                      <span>
                        {"Education : "}
                        <span
                          style={{
                            display:
                              item.RowState === 2 &&
                              item.OldEduLevel !== item.EduLevel
                                ? ""
                                : "none",
                          }}
                        >
                          {item.OldEduLevel === null
                            ? ""
                            : convertEduStatus(item.OldEduLevel.toUpperCase()) +
                              " -> "}
                        </span>
                        {item.OldEduLevel !== item.EduLevel ? (
                          <b>{convertEduStatus(item.EduLevel.toUpperCase())}</b>
                        ) : (
                          convertEduStatus(item.EduLevel.toUpperCase())
                        )}
                      </span>
                    ) : (
                      ""
                    )}
                  </p>
                  <p style={{ margin: "0" }}>
                    {item.Category === "Children" ? (
                      <span>
                        {"School : "}
                        <span
                          style={{
                            display:
                              item.RowState === 2 &&
                              item.OldSchName !== item.SchName
                                ? ""
                                : "none",
                          }}
                        >
                          {item.OldSchName + " -> "}
                        </span>
                        {item.OldSchName !== item.SchName ? (
                          <b>{item.SchName}</b>
                        ) : (
                          item.SchName
                        )}
                      </span>
                    ) : (
                      ""
                    )}
                  </p>
                  <p style={{ margin: "0" }}>
                    {item.Category === "Children" ? (
                      <span>
                        {"IC No / Passport No : "}
                        <span
                          style={{
                            display:
                              item.RowState === 2 && item.OldIDNo !== item.IDNo
                                ? ""
                                : "none",
                          }}
                        >
                          {item.OldIDNo + " -> "}
                        </span>
                        {item.OldIDNo !== item.IDNo ? (
                          <b>{item.IDNo}</b>
                        ) : (
                          item.IDNo
                        )}
                      </span>
                    ) : (
                      ""
                    )}
                  </p>
                </Col>
              </Row>
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};

EmpySingleList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isSelectable: PropTypes.bool,
  selectedDetails: PropTypes.array,
  selectedItems: PropTypes.array,
  onItemSelected: PropTypes.func,
  onDetailsSelected: PropTypes.func,
  data: PropTypes.array,
  onListActionsRendered: PropTypes.func,
};
