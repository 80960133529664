import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import LeaveApplicationApprovalForm from "../../forms/Leave/LeaveApplicationApprovalForm";
import {
  getPendingAndApprovedLeaves,
  approveLeaves,
  rejectLeaves,
  checkPayrollMonthEndClose,
} from "../../../api/leaveApprovalApi";
import { getLeaveConfg } from "../../../api/leaveConfgApi";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Modal } from "antd";

export default function LeaveApplicationApprovalPage({ history }) {
  const location = useLocation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [leaveTrans, setLeaveTrans] = useState([]);
  const [selectedLeaveTrans, setSelectedLeaveTrans] = useState([]);
  const [leaveConfg, setLeaveConfg] = useState({});
  const [currentYear, setCurrentYear] = useState(
    location.state ? location.state.year : dayjs().year()
  );
  const [pagination, setPagination] = useState({
    current: 1,
    minIndex: 0,
    maxIndex: 100,
    pageSize: 100,
  });

  useEffect(getLeaveTrans, [currentYear]);

  function getLeaveTrans() {
    setIsLoading(true);
    let promises = [getPendingAndApprovedLeaves(currentYear), getLeaveConfg()];

    Promise.all(promises)
      .then((values) => {
        // reset pagination
        resetPagination();

        setLeaveTrans(values[0] ? values[0] : []);
        setLeaveConfg(values[1] ? values[1] : []);
        setSelectedLeaveTrans([]);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function resetPagination() {
    setPagination({
      current: 1,
      minIndex: 0,
      maxIndex: 100,
      pageSize: 100,
    });
  }

  function onAddNewLeave() {
    history("/home/leaveapplication/leavetrans");
  }

  function checkMthEndClose(selectedItems) {
    setIsLoading(true);

    checkPayrollMonthEndClose(selectedItems)
      .then((value) => {
        setIsLoading(false);
        if (value.length > 0) {
          Modal.error({
            title: t("leaveApplicationForm.invalid_leave_approval"),
            content: t(
              "leaveApplicationForm.not_allowed_approve_closed_payroll_month_message"
            ),
          });
        } else {
          approveLeaveTrans(selectedItems);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(
          t("leaveApplicationForm.fail_to_check_payroll_month_end_status"),
          error
        );
      });
  }

  function approveLeaveTrans(selectedLeaves) {
    setIsLoading(true);

    approveLeaves(selectedLeaves)
      .then(() => {
        getLeaveTrans();
        toast.success(t("general.record_approved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_approve_record"), error);
      });
  }

  function rejectLeaveTrans(selectedLeaves, reason, isAllowAmend) {
    setIsLoading(true);

    rejectLeaves(selectedLeaves, reason, isAllowAmend)
      .then(() => {
        getLeaveTrans();
        toast.success(t("general.record_rejected_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_reject_record"), error);
      });
  }

  function viewLeaveTrans(leaveTransKey) {
    history("/home/leaveapplication/leavetrans/" + leaveTransKey, {
      state: { fromApproval: true },
    });
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <LeaveApplicationApprovalForm
      isLoading={isLoading}
      data={leaveTrans}
      selectedItems={selectedLeaveTrans}
      onAdd={onAddNewLeave}
      onView={viewLeaveTrans}
      onApproved={
        leaveConfg && leaveConfg.IsPostULToQuarto
          ? checkMthEndClose
          : approveLeaveTrans
      }
      onRejected={rejectLeaveTrans}
      onItemSelected={setSelectedLeaveTrans}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
      currentYear={currentYear}
      pagination={pagination}
      setPagination={setPagination}
      resetPagination={resetPagination}
    />
  );
}

LeaveApplicationApprovalPage.propTypes = {
  history: PropTypes.func.isRequired,
};
