import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  getApprovedAndVoidLeaves,
  voidLeaves,
  checkPayrollMonthEndClose,
} from "../../../api/leaveApprovalApi";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import { getLeaveConfg } from "../../../api/leaveConfgApi";
import LeaveApplicationVoidForm from "../../forms/Leave/LeaveApplicationVoidForm";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";

export default function LeaveApplicationVoidPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [leaveTrans, setLeaveTrans] = useState([]);
  const [selectedLeaveTrans, setSelectedLeaveTrans] = useState([]);
  const [leaveConfg, setLeaveConfg] = useState({});
  const [currentYear, setCurrentYear] = useState(dayjs().year());
  const [pagination, setPagination] = useState({
    current: 1,
    minIndex: 0,
    maxIndex: 100,
    pageSize: 100,
  });

  useEffect(getLeaveTrans, [currentYear]);

  function getLeaveTrans() {
    setIsLoading(true);
    let promises = [getApprovedAndVoidLeaves(currentYear), getLeaveConfg()];

    Promise.all(promises)
      .then((values) => {
        // reset pagination
        resetPagination();

        setLeaveTrans(values[0] ? values[0] : []);
        setLeaveConfg(values[1] ? values[1] : []);
        setSelectedLeaveTrans([]);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function resetPagination() {
    setPagination({
      current: 1,
      minIndex: 0,
      maxIndex: 100,
      pageSize: 100,
    });
  }

  function onAddNewLeave() {
    history("/home/leaveapplication/leavetrans");
  }

  function checkMthEndClose(selectedItems, reason) {
    setIsLoading(true);

    checkPayrollMonthEndClose(selectedItems)
      .then((value) => {
        setIsLoading(false);
        if (value.length > 0) {
          Modal.error({
            title: t("leaveApplicationForm.invalid_leave_void"),
            content: t(
              "leaveApplicationForm.not_allowed_void_closed_payroll_month_message"
            ),
          });
        } else {
          voidLeaveTrans(selectedItems, reason);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(
          t("leaveApplicationForm.fail_to_check_payroll_month_end_status"),
          error
        );
      });
  }

  function voidLeaveTrans(selectedLeaves, reason) {
    setIsLoading(true);

    voidLeaves(selectedLeaves, reason)
      .then(() => {
        getLeaveTrans();
        toast.success(t("general.record_voided_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_void_record"), error);
      });
  }

  function viewLeaveTrans(leaveTransKey) {
    history("/home/leaveapplication/leavetrans/" + leaveTransKey, {
      state: { fromVoid: true },
    });
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <LeaveApplicationVoidForm
      isLoading={isLoading}
      data={leaveTrans}
      selectedItems={selectedLeaveTrans}
      onAdd={onAddNewLeave}
      onView={viewLeaveTrans}
      onVoided={
        leaveConfg && leaveConfg.IsPostULToQuarto
          ? checkMthEndClose
          : voidLeaveTrans
      }
      onItemSelected={setSelectedLeaveTrans}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
      pagination={pagination}
      setPagination={setPagination}
      resetPagination={resetPagination}
    />
  );
}

LeaveApplicationVoidPage.propTypes = {
  history: PropTypes.func.isRequired,
};
