import React, { useEffect, useState } from "react";
import "../../../App.css";
import {
  CalendarOutlined,
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  SearchOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  List,
  Row,
  Col,
  Card,
  Checkbox,
  Button,
  Avatar,
  Divider,
  Tooltip,
  Dropdown,
  Menu,
  Input,
  DatePicker,
  Select,
} from "antd";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { EmployeeProfileStatusByCode } from "../../../components/model/common/Status";
import { useTranslation } from "react-i18next";
import { EmpySingleList } from "./EmpySingleList";

export const EmpyApprovalList = ({
  isLoading,
  data,
  initialData,
  isSelectable = false,
  selectedItems = [],
  onItemSelected,
  selectedDetails = [],
  onDetailsSelected,
  onListActionsRendered,
  footerElement,
}) => {
  const { t } = useTranslation();
  const { Search } = Input;
  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const [showSearchComp, setShowSearchComp] = useState(false);
  const [showDateComp, setShowDateComp] = useState(false);
  const [showUserComp, setShowUserComp] = useState(false);

  const [userSelectionData, setUserSelectionData] = useState([]);
  const [filteredDatas, setFilteredDatas] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  const [searchDateValue, setSearchDateValue] = useState([]);
  const [searchUserValue, setSearchUserValue] = useState();

  const [filterVisible, setFilterVisible] = useState(false);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("Date");
  const [isFilterChecked, setIsFilterChecked] = useState(true);

  useEffect(() => {
    initialData.sort((a, b) => dayjs(b.SubmitDate) - dayjs(a.SubmitDate));

    onCheckFilter(true);
  }, [initialData]); // eslint-disable-line

  function getDistinctUser(data) {
    const result = [];

    const map = new Map();

    for (const item of data) {
      if (!map.has(item.EmpyKey)) {
        map.set(item.EmpyKey, true);
        result.push({
          EmpyKey: item.EmpyKey,
          EmpyName: item.EmpyName,
        });
      }
    }
    setUserSelectionData(result);
  }

  function selectItem(isSelected, key) {
    if (onItemSelected) {
      if (isSelected) {
        if (selectedItems.findIndex((x) => x.EmpyKey === key) === -1) {
          let selectedItem = data.find((x) => x.EmpyKey === key);
          let selectedDetail = initialData.filter((x) => x.EmpyKey === key);
          let selectedDets = Object.assign(
            [],
            selectedDetails.filter((x) => x.EmpyKey !== key)
          );
          selectedDetail.forEach((res) => {
            selectedDets.push(res);
          });
          onItemSelected([...selectedItems, selectedItem]);
          onDetailsSelected(selectedDets);
        }
      } else {
        onItemSelected(selectedItems.filter((x) => x.EmpyKey !== key));
        onDetailsSelected(selectedDetails.filter((x) => x.EmpyKey !== key));
      }
    }
  }

  function showCompFunc(comp) {
    if (comp === "Search") {
      setShowSearchComp(true);
    } else if (comp === "Date") {
      setShowDateComp(true);
    } else if (comp === "User") {
      setShowUserComp(true);
    }
  }

  function closeCompFunc() {
    setShowSearchComp(false);
    setShowDateComp(false);
    setShowUserComp(false);

    setSearchValue("");
    setSearchDateValue([]);
    setSearchUserValue();

    if (isFilterChecked) {
      let filteredData = initialData.filter((x) => !x.IsViewOnly);
      setFilteredDatas(filteredData);
    } else {
      setFilteredDatas(initialData);
    }
  }

  function onSelectFilter(e) {
    let value = e.key;

    if (value === "0") {
      setOrderBy("Date");
      sortData("Date", order);
    } else if (value === "1") {
      setOrderBy("Status");
      sortData("Status", order);
    }

    if (value === "997") {
      setOrder("asc");
      sortData(orderBy, "asc");
    } else if (value === "998") {
      setOrder("desc");
      sortData(orderBy, "desc");
    }

    if (value === "999") {
      setFilterVisible(false);
      setOrderBy("Date");
      setOrder("desc");
      sortData("Date", "desc");
    }
  }

  const checkMenuIcon = (menu, check) => (
    <>
      <span style={{ float: "left" }}>{menu}</span>
      <span style={{ float: "right", display: check === true ? "" : "none" }}>
        <CheckOutlined />
      </span>
    </>
  );

  const menu = (
    <Menu
      onClick={onSelectFilter}
      items={[
        {
          label:
            orderBy === "Date"
              ? checkMenuIcon(t("general.date"), true)
              : checkMenuIcon(t("general.date"), false),
          key: "0",
        },
        {
          label:
            orderBy === "Status"
              ? checkMenuIcon(t("general.status"), true)
              : checkMenuIcon(t("general.status"), false),
          key: "1",
        },

        { type: "divider" },
        {
          label:
            order === "asc"
              ? checkMenuIcon(t("general.ascending"), true)
              : checkMenuIcon(t("general.ascending"), false),
          key: "997",
        },
        {
          label:
            order === "desc"
              ? checkMenuIcon(t("general.descending"), true)
              : checkMenuIcon(t("general.descending"), false),
          key: "998",
        },
        { type: "divider" },
        {
          label: t("general.reset"),
          key: "999",
        },
      ]}
    ></Menu>
  );

  function onEnterSearch(value, isChecked) {
    let filteredData = initialData.filter(
      (x) =>
        (x.BankName != null &&
          x.BankName.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
        (x.Category != null &&
          x.Category.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
        (x.Education != null &&
          x.Education.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
        (x.EmpyChildName != null &&
          x.EmpyChildName.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
        (x.EmpyName != null &&
          x.EmpyName.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
        (x.PhyBankAcc != null &&
          x.PhyBankAcc.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
        (x.Remarks != null &&
          x.Remarks.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
        (x.SchName != null &&
          x.SchName.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
        (x.SpouseID != null &&
          x.SpouseID.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
        (x.SpouseName != null &&
          x.SpouseName.toLowerCase().indexOf(value.toLowerCase()) > -1)
    );

    if (isChecked === true) {
      filteredData = filteredData.filter((x) => !x.IsViewOnly);
    }

    setFilteredDatas(filteredData);
  }

  function onChangeSearch(e) {
    setSearchValue(e.target.value);
  }

  function onChangeDate(date, isChecked) {
    if (date != null) {
      if (date.length > 0) {
        let fromFormatDate = date[0].startOf("day");
        let toFormatDate = date[1].startOf("day");

        let filteredData = initialData.filter((x) => {
          let fromDate = dayjs(x.SubmitDate).startOf("day").clone();
          let toDate = dayjs(x.SubmitDate).startOf("day").clone();

          //Do it once as inside loop already +1
          if (fromDate >= fromFormatDate && fromDate <= toFormatDate) {
            return x;
          }

          while (fromDate.add(1, "days").diff(toDate) < 0) {
            fromDate = fromDate.add(1, "days");
            if (fromDate >= fromFormatDate && fromDate <= toFormatDate) {
              return x;
            }
          }
          return false;
        });

        if (isChecked === true) {
          filteredData = filteredData.filter((x) => !x.IsViewOnly);
        }

        setSearchDateValue([date[0], date[1]]);
        setFilteredDatas(filteredData);
      }
    } else {
      setSearchDateValue([]);

      if (isChecked === true) {
        let filteredData = initialData.filter((x) => !x.IsViewOnly);

        setFilteredDatas(filteredData);
      } else {
        setFilteredDatas(initialData);
      }
    }
  }

  function onChangeUserSelection(value, isChecked) {
    setSearchUserValue(value);
    let filteredData = initialData.filter((x) => x.EmpyKey === value);

    if (isChecked === true) {
      filteredData = filteredData.filter((x) => !x.IsViewOnly);
    }

    setFilteredDatas(filteredData);
  }

  function onVisibleChange(flag) {
    setFilterVisible(flag);
  }

  function onCheckFilter(isChecked) {
    setIsFilterChecked(isChecked);
    sortData(orderBy, order);

    let filteredData = [];
    if (isChecked) {
      filteredData = initialData.filter((x) => !x.IsViewOnly);
      getDistinctUser(filteredData);
    } else {
      getDistinctUser(initialData);
    }

    if (searchValue && searchValue.length > 0) {
      onEnterSearch(searchValue, isChecked);
    } else if (searchDateValue.length > 0) {
      onChangeDate(searchDateValue, isChecked);
    } else if (searchUserValue) {
      onChangeUserSelection(searchUserValue, isChecked);
    } else {
      if (isChecked) {
        setFilteredDatas(filteredData);
      } else {
        setFilteredDatas(initialData);
      }
    }
  }

  function generateUsersOption() {
    let dataOptions = [];
    if (userSelectionData && userSelectionData.length) {
      dataOptions = userSelectionData.map((type) => (
        <Option key={type.EmpyKey} value={type.EmpyKey}>
          {type.EmpyName}
        </Option>
      ));
    }

    return dataOptions;
  }

  function sortData(orderBy, order) {
    if (order === "asc") {
      if (orderBy === "Date") {
        //Sort By Date
        filteredDatas.sort((a, b) => dayjs(a.SubmitDate) - dayjs(b.SubmitDate));
        initialData.sort((a, b) => dayjs(a.SubmitDate) - dayjs(b.SubmitDate));
      } else if (orderBy === "Status") {
        //Sort By String
        filteredDatas.sort((a, b) => sortStringAsc(a, b));
        initialData.sort((a, b) => sortStringAsc(a, b));
      }
    } else {
      if (orderBy === "Date") {
        filteredDatas.sort((a, b) => dayjs(b.SubmitDate) - dayjs(a.SubmitDate));
        initialData.sort((a, b) => dayjs(b.SubmitDate) - dayjs(a.SubmitDate));
      } else if (orderBy === "Status") {
        filteredDatas.sort((a, b) => sortStringDesc(a, b));
        initialData.sort((a, b) => sortStringDesc(a, b));
      }
    }
  }

  function sortStringAsc(a, b) {
    let statusA = EmployeeProfileStatusByCode[a.AprStatus].toUpperCase();
    let statusB = EmployeeProfileStatusByCode[b.AprStatus].toUpperCase();

    if (statusA < statusB) {
      return -1;
    }
    if (statusA > statusB) {
      return 1;
    }
    return 0;
  }

  function sortStringDesc(a, b) {
    let statusA = EmployeeProfileStatusByCode[a.AprStatus].toUpperCase();
    let statusB = EmployeeProfileStatusByCode[b.AprStatus].toUpperCase();

    if (statusB < statusA) {
      return -1;
    }
    if (statusB > statusA) {
      return 1;
    }
    return 0;
  }

  return (
    <div>
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 16, offset: 4 }}
        >
          <div
            className="ant-list-header"
            style={{
              paddingInline: 24,
              paddingBlock: 12,
            }}
          >
            <Row>
              <Col span={16}>
                <Button.Group
                  style={{
                    display:
                      showSearchComp || showDateComp || showUserComp
                        ? "none"
                        : "",
                    padding: "5px 0",
                  }}
                >
                  <Tooltip
                    title={t("general.search")}
                    getPopupContainer={() =>
                      document.getElementById("scroll-container")
                    }
                  >
                    <Button
                      {...{ type: "primary" }}
                      onClick={() => showCompFunc("Search")}
                    >
                      <SearchOutlined />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    title={t("general.filter_by_date")}
                    getPopupContainer={() =>
                      document.getElementById("scroll-container")
                    }
                  >
                    <Button
                      {...{ type: "primary" }}
                      onClick={() => showCompFunc("Date")}
                    >
                      <CalendarOutlined />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    title={t("general.filter_by_user")}
                    getPopupContainer={() =>
                      document.getElementById("scroll-container")
                    }
                  >
                    <Button
                      {...{ type: "primary" }}
                      onClick={() => showCompFunc("User")}
                    >
                      <UserOutlined />
                    </Button>
                  </Tooltip>
                </Button.Group>
                <>
                  <Search
                    value={searchValue}
                    enterButton
                    placeholder={t("general.search")}
                    onSearch={(value) => onEnterSearch(value, isFilterChecked)}
                    onChange={onChangeSearch}
                    style={{
                      width: "220px",
                      padding: "5px 0",
                      display: showSearchComp ? "" : "none",
                    }}
                  />
                  <RangePicker
                    value={searchDateValue}
                    format="DD/MM/YYYY"
                    onChange={(value) => onChangeDate(value, isFilterChecked)}
                    style={{
                      width: "220px",
                      display: showDateComp ? "" : "none",
                    }}
                  />
                  <Select
                    value={searchUserValue}
                    showSearch
                    placeholder={t("general.employee_placeholder")}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    getPopupContainer={() =>
                      document.getElementById("scroll-container")
                    }
                    onChange={(value) =>
                      onChangeUserSelection(value, isFilterChecked)
                    }
                    style={{
                      width: "220px",
                      padding: "5px 0",
                      display: showUserComp ? "" : "none",
                    }}
                  >
                    {generateUsersOption()}
                  </Select>

                  <Button
                    onClick={() => closeCompFunc()}
                    type="link"
                    style={{
                      color: "#ffffff",
                      display:
                        showSearchComp || showDateComp || showUserComp
                          ? ""
                          : "none",
                      padding: "5px 8px",
                    }}
                  >
                    <CloseOutlined />
                  </Button>
                </>
                <Divider type="vertical" />
                <Button.Group style={{ padding: "5px 0" }}>
                  <Tooltip>
                    <Dropdown
                      dropdownRender={() => menu}
                      trigger={["click"]}
                      open={filterVisible}
                      onOpenChange={onVisibleChange}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                    >
                      <Button {...{ type: "primary" }}>
                        {order === "asc" ? (
                          <SortAscendingOutlined />
                        ) : (
                          <SortDescendingOutlined />
                        )}
                        {t("general.sort_by")} {""}
                        {orderBy === "Date"
                          ? t("general.date")
                          : orderBy === "Status"
                          ? t("general.status")
                          : ""}
                        {""}
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Tooltip>
                </Button.Group>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          style={{
            height: "calc(100vh - 214px)",
            overflow: "auto",
            padding: "0px",
            backgroundColor: "#e4e6eb",
          }}
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 16, offset: 4 }}
        >
          <List
            loading={isLoading}
            split={false}
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item) => (
              <Card
                style={{
                  width: "100%",
                  padding: "0",
                  display:
                    filteredDatas.filter((res) => res.EmpyKey === item.EmpyKey)
                      .length > 0
                      ? ""
                      : "none",
                }}
              >
                <List.Item style={{ padding: 0 }}>
                  <Row style={{ display: "block", width: "100%" }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      {isSelectable && (
                        <Checkbox
                          style={{
                            marginRight: 5,
                            marginTop: 10,
                            width: "20px",
                            float: "left",
                          }}
                          indeterminate={
                            selectedDetails.filter(
                              (x) => x.EmpyKey === item.EmpyKey
                            ).length &&
                            selectedDetails.filter(
                              (x) => x.EmpyKey === item.EmpyKey
                            ).length <
                              initialData.filter(
                                (res) => res.EmpyKey === item.EmpyKey
                              ).length
                          }
                          checked={
                            selectedItems.findIndex(
                              (x) => x.EmpyKey === item.EmpyKey
                            ) >= 0 ||
                            selectedDetails.filter(
                              (res) => res.EmpyKey === item.EmpyKey
                            ).length ===
                              initialData.filter(
                                (res) => res.EmpyKey === item.EmpyKey
                              ).length
                          }
                          onChange={(event) =>
                            selectItem(event.target.checked, item.EmpyKey)
                          }
                        />
                      )}
                      <List.Item.Meta
                        avatar={<Avatar size="large" src={item.EmpyPhotoUrl} />}
                        title={
                          <div style={{ color: "#0070cc" }}>
                            {item.EmpyName}
                          </div>
                        }
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <EmpySingleList
                        data={filteredDatas.filter(
                          (res) => res.EmpyKey === item.EmpyKey
                        )}
                        selectedDetails={selectedDetails}
                        onDetailsSelected={onDetailsSelected}
                        isSelectable={true}
                        isLoading={isLoading}
                        selectedItems={selectedItems}
                        onItemSelected={onItemSelected}
                        onListActionsRendered={onListActionsRendered}
                      />
                    </Col>
                  </Row>
                </List.Item>
              </Card>
            )}
            bordered
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 16, offset: 4 }}
        >
          {initialData.length > 0 ? (
            <div
              style={{
                fontSize: "14px",
                padding: "10px 15px",
                border: "1px solid #c3d0d9",
                backgroundColor: "#f0f3f5",
              }}
            >
              {footerElement()}
            </div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

EmpyApprovalList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  initialData: PropTypes.array.isRequired,
  isSelectable: PropTypes.bool,
  selectedItems: PropTypes.array,
  selectedDetails: PropTypes.array,
  onDetailsSelected: PropTypes.func,
  onItemSelected: PropTypes.func,
  onListActionsRendered: PropTypes.func,
  footerElement: PropTypes.func,
};
