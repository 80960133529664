import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  getEmpyInfo,
  getOU,
  getESSEmployeeReport,
  getDepartment,
  getEmployeeUnderApproverLeaves,
  getLeaveType,
} from "../../api/reportApi";
import dayjs from "dayjs";
import { Status } from "../model/common/Status";
import { ReportViewer } from "./ReportViewer";
import Icon, { FilterFilled, FilterOutlined } from "@ant-design/icons";
import { Button, Row, Col, Select, DatePicker } from "antd";
import "../../styles/report.scss";
import * as UserType from "../../redux/actions/actionTypes";
import { useTranslation } from "react-i18next";
import { getCompany } from "../../api/companyApi";
import {
  TreeSelectionInput,
  getAllDsOption,
} from "../common/TreeSelectionInput";

const { Option } = Select;

export default function StaffLeaveConsumptionForm() {
  const { t } = useTranslation();
  const REPORT_NAME = "Employee Leave Consumption";

  const [statusLocal, setStatus] = useState([]);
  const [userRoleType, setUserRoleType] = useState(UserType.USER_ROLE_USER);
  const [empyInfo, setEmpyInfo] = useState({ EmpyKey: null });
  const [reportName, setReportName] = useState(REPORT_NAME);
  const [isReportParamValid, setReportParamValid] = useState(false);
  const [isReportGenerate, setReportGenerate] = useState(false);
  const [reportParam, setReportParam] = useState({});
  const [collapsed, setCollapsed] = useState(false);
  const [dsStatusOptions, setStatusOptions] = useState([]);
  const [dsOU, setOUOptions] = useState([]);
  const [dsEmpy, setEmpyOptions] = useState([]);
  const [dsDept, setDeptOptions] = useState([]);
  const [dsLeaveType, setLeaveTypeOptions] = useState([]);
  const [dsComp, setCompOptions] = useState([]);
  const [initialOU, setInitialOU] = useState([]);
  const [initialEmpy, setInitialEmpy] = useState([]);
  const [initialApprovalEmpyKey, setInitialApprEmpyKey] = useState([]);
  const [initialDept, setInitialDept] = useState([]);
  const [initialLeaveType, setInitialLeaveType] = useState([]);
  const [selectedOU, setSelectedOU] = useState([]);
  const [selectedDept, setSelectedDept] = useState([]);
  const [selectedEmpy, setSelectedEmpy] = useState([]);
  const [selectedLeaveType, setSelectedLeaveType] = useState([]);
  const [selectedFromDate, setFromDate] = useState();
  const [selectedToDate, setToDate] = useState();
  const [selectedComp, setSelectedComp] = useState();
  const [isReportGenerating, setReportGenerating] = useState(false);
  const [userStatus, setUserStatus] = useState([1]);
  const [userStatusDS, setUserStatusDS] = useState([
    { text: "ALL SELECTED", value: 0 },
    { text: "Active", value: 1 },
    { text: "Inactive", value: 2 },
  ]);

  const onSingleCompanyChange = (value) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value > -1) {
      setSelectedComp(value);
      const tempOU = Object.assign(
        [],
        initialOU.filter((x) => x.compKey === value)
      );
      setOUOptions(mapData(tempOU));
      setSelectedOU([]);
      setDeptOptions([]);
      setSelectedDept([]);
      setLeaveTypeOptions([]);
      setSelectedLeaveType([]);
      setEmpyOptions([]);
      setSelectedEmpy([]);
    } else {
      setSelectedComp();
      setOUOptions([]);
      setSelectedOU([]);
      setDeptOptions([]);
      setSelectedDept([]);
      setLeaveTypeOptions([]);
      setSelectedLeaveType([]);
      setEmpyOptions([]);
      setSelectedEmpy([]);
    }
  };

  const onMultipleOUChange = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      let tempDept = Object.assign([], initialDept);
      let tempLeaveType = Object.assign([], initialLeaveType);

      if (extra.triggerValue === -1) {
        if (value.includes(-1)) {
          setSelectedOU(getAllDsOption(dsOU, true));
          tempDept = Object.assign(
            [],
            initialDept.filter((x) =>
              dsOU.map((y) => y.value).includes(x.OUKey)
            )
          );
          tempLeaveType = Object.assign(
            [],
            initialLeaveType.filter((x) =>
              dsOU.map((y) => y.value).includes(x.OUKey)
            )
          );
        } else {
          setSelectedOU([]);
          tempDept = [];
          tempLeaveType = [];
        }
      } else {
        setSelectedOU(
          value.filter((x) => x !== -1).length === dsOU.length
            ? getAllDsOption(dsOU, true)
            : value.filter((x) => x !== -1)
        );

        tempDept = Object.assign(
          [],
          initialDept.filter((x) => value.includes(x.OUKey))
        );
        tempLeaveType = Object.assign(
          [],
          initialLeaveType.filter((x) => value.includes(x.OUKey))
        );
      }

      setDeptOptions(mapData(tempDept));
      setSelectedDept([]);
      setLeaveTypeOptions(mapData(tempLeaveType));
      setSelectedLeaveType([]);
      setEmpyOptions([]);
      setSelectedEmpy([]);
    } else {
      setSelectedOU([]);
      setDeptOptions([]);
      setSelectedDept([]);
      setLeaveTypeOptions([]);
      setSelectedLeaveType([]);
      setEmpyOptions([]);
      setSelectedEmpy([]);
    }
  };

  const onMultipleDeptChange = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      let tempEmpy = Object.assign([], initialEmpy);

      if (extra.triggerValue === -1) {
        if (value.includes(-1)) {
          setSelectedDept(getAllDsOption(dsDept, true));
          tempEmpy = Object.assign(
            [],
            initialEmpy.filter(
              (x) =>
                selectedOU.includes(x.OUKey) &&
                dsDept.map((y) => y.value).includes(x.DeptKey) &&
                (userStatus[0] === 0
                  ? true
                  : userStatus[0] === 1
                  ? x.Active === true
                  : x.Active === false)
            )
          );
        } else {
          setSelectedDept([]);
          tempEmpy = [];
        }
      } else {
        setSelectedDept(
          value.filter((x) => x !== -1).length === dsDept.length
            ? getAllDsOption(dsDept, true)
            : value.filter((x) => x !== -1)
        );

        tempEmpy = Object.assign(
          [],
          initialEmpy.filter(
            (x) =>
              selectedOU.includes(x.OUKey) &&
              value.includes(x.DeptKey) &&
              (userStatus[0] === 0
                ? true
                : userStatus[0] === 1
                ? x.Active === true
                : x.Active === false)
          )
        );
      }

      setEmpyOptions(mapData(tempEmpy));
      setSelectedEmpy([]);
    } else {
      setSelectedDept([]);
      setEmpyOptions([]);
      setSelectedEmpy([]);
    }
  };

  const onMultipleEmpyChange = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      if (extra.triggerValue === -1) {
        if (value.includes(-1)) {
          setSelectedEmpy(getAllDsOption(dsEmpy, true));
        } else {
          setSelectedEmpy([]);
        }
      } else {
        setSelectedEmpy(
          value.filter((x) => x !== -1).length === dsEmpy.length
            ? getAllDsOption(dsEmpy, true)
            : value.filter((x) => x !== -1)
        );
      }
    } else {
      setSelectedEmpy([]);
    }
  };

  const onMultipleLeaveTypeChange = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      if (extra.triggerValue === -1) {
        if (value.includes(-1)) {
          setSelectedLeaveType(getAllDsOption(dsLeaveType, true));
        } else {
          setSelectedLeaveType([]);
        }
      } else {
        if (dsLeaveType.length > 0) {
          setSelectedLeaveType(
            value.filter((x) => x !== -1).length === dsLeaveType.length
              ? getAllDsOption(dsLeaveType, true)
              : value.filter((x) => x !== -1)
          );
        }
      }
    } else {
      setSelectedLeaveType([]);
    }
  };

  const onStatusChange = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      if (extra.triggerValue === -1) {
        if (value.includes(-1)) {
          setStatus(getAllDsOption(dsStatusOptions, true));
        } else {
          setStatus([]);
        }
      } else {
        if (dsStatusOptions.length > 0) {
          setStatus(
            value.filter((x) => x !== -1).length === dsStatusOptions.length
              ? getAllDsOption(dsStatusOptions, true)
              : value.filter((x) => x !== -1)
          );
        }
      }
    } else {
      setStatus([]);
    }
  };

  const onFromDateChange = (value) => {
    value === null ? setFromDate() : setFromDate(value.format("DD/MM/YYYY"));
  };

  const onToDateChange = (value) => {
    value === null ? setToDate() : setToDate(value.format("DD/MM/YYYY"));
  };

  function disabledToDate(current) {
    //disable date that is before from date
    return selectedFromDate
      ? current < dayjs(selectedFromDate, "DD/MM/YYYY")
      : null;
  }

  function disabledFromDate(current) {
    //disable date that is before from date
    return selectedToDate
      ? current > dayjs(selectedToDate, "DD/MM/YYYY").add(1, "days")
      : null;
  }

  const onUserStatus = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    let tempValue = getAllDsOption(userStatusDS, false);

    if (value.length > 0) {
      if (extra.triggerValue === 0) {
        if (value.includes(0)) {
          setUserStatus(getAllDsOption(userStatusDS, false));
          tempValue = getAllDsOption(userStatusDS, false);
        } else {
          setUserStatus([]);
          tempValue = [];
        }
      } else {
        if (userStatusDS.length > 0) {
          setUserStatus(
            value.includes(1) && value.includes(2)
              ? getAllDsOption(userStatusDS, false)
              : value.filter((x) => x !== 0)
          );

          if (value.includes(1) && value.includes(2)) {
            tempValue = getAllDsOption(userStatusDS, false);
          } else {
            tempValue = value.filter((x) => x !== 0);
          }
        }
      }

      if (tempValue[0] !== undefined) {
        let tempEmpy = Object.assign([], initialEmpy);

        tempEmpy = Object.assign(
          [],
          initialEmpy.filter(
            (x) =>
              selectedOU.includes(x.OUKey) &&
              selectedDept.includes(x.DeptKey) &&
              (tempValue[0] === 0
                ? true
                : tempValue[0] === 1
                ? x.Active === true
                : x.Active === false)
          )
        );

        let tempMapEmpy = mapData(tempEmpy);
        setEmpyOptions(tempMapEmpy);
        setSelectedEmpy([]);
      } else {
        setEmpyOptions([]);
        setSelectedEmpy([]);
      }
    } else {
      setUserStatus([]);
      setEmpyOptions([]);
      setSelectedEmpy([]);
    }
  };

  const generateReport = (e) => {
    e.preventDefault();
    setReportGenerate(true);
    setReportGenerating(true);
    setReportName(
      REPORT_NAME +
        "#" +
        empyInfo.ClientID +
        "#" +
        empyInfo.ClientKey +
        "#" +
        empyInfo.UserKey +
        "#" +
        empyInfo.LangKey
    );

    var tempEmpyList = [];
    dsEmpy.forEach((x) =>
      initialApprovalEmpyKey.includes(x.value) ? tempEmpyList.push(x.value) : {}
    );

    setReportParam({
      CompKey:
        userRoleType === UserType.USER_ROLE_USER
          ? empyInfo.CompKey
          : selectedComp,
      OUKey:
        userRoleType === UserType.USER_ROLE_USER
          ? empyInfo.OUKey
          : selectedOU.includes(-1)
          ? [null]
          : selectedOU,
      Status: statusLocal.includes(-1) ? [null] : statusLocal,
      DeptKey:
        userRoleType === UserType.USER_ROLE_USER
          ? empyInfo.DeptKey
          : selectedDept.includes(-1)
          ? [null]
          : selectedDept,
      EmpyKey:
        userRoleType === UserType.USER_ROLE_USER
          ? empyInfo.EmpyKey
          : userRoleType === UserType.USER_ROLE_APPROVER
          ? selectedEmpy.includes(-1)
            ? tempEmpyList
            : selectedEmpy.includes(-1)
            ? [null]
            : selectedEmpy
          : selectedEmpy.includes(-1)
          ? [null]
          : selectedEmpy,
      LTKey: selectedLeaveType.includes(-1) ? [null] : selectedLeaveType,
      FromDate:
        dayjs(selectedFromDate, "DD/MM/YYYY").format(
          "YYYY-MM-DDTHH:mm:ss.SSS"
        ) + "Z",
      ToDate:
        dayjs(selectedToDate, "DD/MM/YYYY").format("YYYY-MM-DDTHH:mm:ss.SSS") +
        "Z",
      ClientKey: empyInfo.ClientKey,
      UserKey: -1,
      DefOUKey: empyInfo.OUKey,
      BreakEmpy: false,
      HideSignature: false,
      HideBorder: false,
      IsESS: true,
      UserStatus: userStatus[0],
    });
  };

  const validate = (
    status,
    selectedOU,
    selectedEmpy,
    selectedDept,
    selectedFromDate,
    selectedToDate,
    selectedLeaveType,
    selectedComp,
    userStatus
  ) => {
    var valid = false;
    if (
      status.length > 0 &&
      selectedOU.length > 0 &&
      selectedEmpy.length > 0 &&
      selectedDept.length > 0 &&
      selectedFromDate !== undefined &&
      selectedToDate !== undefined &&
      selectedLeaveType.length > 0 &&
      selectedComp !== undefined &&
      userStatus.length > 0
    ) {
      valid = true;
    }
    setReportParamValid(valid);
  };

  useEffect(() => {
    validate(
      statusLocal,
      selectedOU,
      selectedEmpy,
      selectedDept,
      selectedFromDate,
      selectedToDate,
      selectedLeaveType,
      selectedComp,
      userStatus
    );
  }, [
    statusLocal,
    selectedOU,
    selectedEmpy,
    selectedDept,
    selectedFromDate,
    selectedToDate,
    selectedLeaveType,
    selectedComp,
    userStatus,
  ]);

  useEffect(() => {
    let { UserRoleType } = JSON.parse(sessionStorage.getItem("auth"));
    let promises = [
      getEmpyInfo(),
      getOU(),
      getEmployeeUnderApproverLeaves(),
      getESSEmployeeReport(),
      getDepartment(),
      getLeaveType(),
      getCompany(),
    ];
    const statusArr = [];
    for (var st in Status) {
      // eslint-disable-next-line no-prototype-builtins
      if (Status.hasOwnProperty(st)) {
        if (st >= 0 && st <= 6) {
          statusArr.push({
            text: Status[st],
            value: st,
          });
        }
      }
    }
    setUserStatusDS([
      { text: "ALL SELECTED", value: 0 },
      { text: "Active", value: 1 },
      { text: "Inactive", value: 2 },
    ]);
    setStatusOptions(statusArr);
    setStatus(["0", "1", "4", "5"]);
    setUserRoleType(UserRoleType);
    Promise.all(promises)
      .then((data) => {
        if (data[0] && data[0].length > 0) {
          setEmpyInfo(data[0][0]);
          if (UserRoleType === UserType.USER_ROLE_USER) {
            setSelectedComp(data[0][0].CompKey);
            setSelectedEmpy([data[0][0].EmpyKey]);
            setSelectedOU([data[0][0].OUKey]);
            setSelectedDept([data[0][0].DeptKey]);
          }
        }

        if (data[1] && data[1].length > 0) {
          const OUArr = [];
          data[1].forEach((element) => {
            OUArr.push({
              text: element.OUCode + " - " + element.OUDesc,
              value: element.OUKey,
              compKey: element.CompKey,
            });
          });
          setInitialOU(OUArr);
          if (UserRoleType === UserType.USER_ROLE_USER) {
            setOUOptions(
              OUArr.filter(
                (x) =>
                  x.value === data[0][0].OUKey &&
                  x.compKey === data[0][0].CompKey
              )
            );
          }
        }

        if (data[3] && data[3].length > 0) {
          const EmpyArr = [];
          let filteredEmpy = data[3];
          if (UserRoleType === UserType.USER_ROLE_APPROVER) {
            if (data[2] && data[2].length > 0) {
              setInitialApprEmpyKey(data[2]);
              filteredEmpy = data[3].filter((x) => data[2].includes(x.EmpyKey));
            }
          }
          filteredEmpy.forEach((element) => {
            EmpyArr.push({
              text: element.EmpyID + " - " + element.EmpyName,
              value: element.EmpyKey,
              OUKey: element.OUKey,
              DeptKey: element.DeptKey,
              Active: element.Active,
            });
          });
          setInitialEmpy(EmpyArr);
          if (UserRoleType === UserType.USER_ROLE_USER) {
            setEmpyOptions(
              EmpyArr.filter(
                (x) =>
                  x.value === data[0][0].EmpyKey &&
                  x.OUKey === data[0][0].OUKey &&
                  x.DeptKey === data[0][0].DeptKey
              )
            );
          }
        }

        if (data[4] && data[4].length > 0) {
          const DeptArr = [];
          data[4].forEach((element) => {
            DeptArr.push({
              text: element.DeptCode + " - " + element.DeptDesc,
              value: element.DeptKey,
              OUKey: element.OUKey,
            });
          });
          setInitialDept(DeptArr);
          if (UserRoleType === UserType.USER_ROLE_USER) {
            setDeptOptions(
              DeptArr.filter(
                (x) =>
                  x.value === data[0][0].DeptKey && x.OUKey === data[0][0].OUKey
              )
            );
          }
        }

        if (data[5] && data[5].length > 0) {
          const LeaveTypeArr = [];
          data[5].forEach((element) => {
            LeaveTypeArr.push({
              text: element.LTCode + " - " + element.LTDesc,
              value: element.LTKey,
              OUKey: element.OUKey,
            });
          });
          setInitialLeaveType(LeaveTypeArr);
          if (UserRoleType === UserType.USER_ROLE_USER) {
            let tempLeaveTypeArr = Object.assign(
              [],
              LeaveTypeArr.filter((x) => x.OUKey === data[0][0].OUKey)
            );

            const leaveTypeResult = [];
            const mapLeaveType = new Map();
            for (const item of tempLeaveTypeArr) {
              if (!mapLeaveType.has(item.value)) {
                mapLeaveType.set(item.value, true);
                leaveTypeResult.push({
                  text: item.text,
                  value: item.value,
                });
              }
            }
            setLeaveTypeOptions(leaveTypeResult);
          }
        }

        if (data[6] && data[6].length > 0) {
          const CompArr = [];
          data[6].forEach((element) => {
            CompArr.push({
              text: element.CodeDesc,
              value: element.Key,
            });
          });
          setCompOptions(CompArr);
        }
      })
      .catch(() => {
        toast.error("something went wrong");
      });
  }, []);

  function mapData(data) {
    const mapResult = [];
    const newMap = new Map();
    for (const item of data) {
      if (!newMap.has(item.value)) {
        newMap.set(item.value, true);
        mapResult.push({
          text: item.text,
          value: item.value,
        });
      }
    }
    return mapResult;
  }

  return (
    <Row>
      <Col span={24}>
        <div className="report-container">
          <ReportViewer
            Collapsed={collapsed}
            ReportParam={reportParam}
            ReportName={reportName}
            ReportGenerate={isReportGenerate}
            CustomStyle={false}
            setReportGenerating={setReportGenerating}
            setReportGenerate={setReportGenerate}
          />
        </div>
        <div className="report-filter-indicator">
          <Button
            type="link"
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            <Icon
              style={{ fontSize: "24px" }}
              component={collapsed ? FilterFilled : FilterOutlined}
            />
          </Button>
        </div>
        <div
          className="report-param"
          style={{ display: collapsed ? "none" : "block" }}
        >
          <label>{t("general.company")}</label>
          <br />
          <Select
            mode="single"
            style={{
              width: 240,
              maxHeight: 100,
              overflow: "auto",
              whiteSpace: "nowrap",
            }}
            placeholder={t("general.company_placeholder")}
            onChange={onSingleCompanyChange}
            optionLabelProp="label"
            value={selectedComp}
            filterOption={(input, option) =>
              option.props.label
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            disabled={userRoleType === UserType.USER_ROLE_USER}
          >
            {dsComp.map((element) => (
              <Option
                key={element.value}
                value={element.value}
                label={element.text}
              >
                <div>{element.text}</div>
              </Option>
            ))}
          </Select>
          <br />
          <label>{t("general.operating_unit")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("general.operating_unit_placeholder_full")}
            fncOnChange={onMultipleOUChange}
            value={selectedOU}
            dsOption={dsOU}
            isIncludeAllOption={true}
            disabled={userRoleType === UserType.USER_ROLE_USER}
          ></TreeSelectionInput>
          <br />
          <label>{t("general.leave_type")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("general.leave_type_placeholder")}
            fncOnChange={onMultipleLeaveTypeChange}
            value={selectedLeaveType}
            dsOption={dsLeaveType}
            isIncludeAllOption={true}
          ></TreeSelectionInput>
          <br />
          <label>{t("general.department")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("general.department_placeholder")}
            fncOnChange={onMultipleDeptChange}
            value={selectedDept}
            dsOption={dsDept}
            isIncludeAllOption={true}
            disabled={userRoleType === UserType.USER_ROLE_USER}
          ></TreeSelectionInput>
          <br />
          <label>{t("general.employee")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("general.employee_placeholder")}
            fncOnChange={onMultipleEmpyChange}
            value={selectedEmpy}
            dsOption={dsEmpy}
            isIncludeAllOption={true}
            disabled={userRoleType === UserType.USER_ROLE_USER}
          ></TreeSelectionInput>
          <br />
          <label>{t("general.from_date")}</label>
          <br />
          <DatePicker
            placeholder={t("general.from_date")}
            format={"DD/MM/YYYY"}
            onChange={onFromDateChange}
            disabledDate={disabledFromDate}
            style={{ width: "100%" }}
          />
          <br />
          <label>{t("general.to_date")}</label>
          <br />
          <DatePicker
            placeholder={t("general.to_date")}
            format={"DD/MM/YYYY"}
            onChange={onToDateChange}
            disabledDate={disabledToDate}
            style={{ width: "100%" }}
          />
          <br />
          <label>{t("general.status")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("general.status_placeholder")}
            fncOnChange={onStatusChange}
            value={statusLocal}
            dsOption={dsStatusOptions}
            isIncludeAllOption={true}
          ></TreeSelectionInput>
          <br />
          <label>{t("report.employee_status")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("report.employee_status_placeholder")}
            fncOnChange={onUserStatus}
            value={userStatus}
            dsOption={userStatusDS}
            isIncludeAllOption={false}
            disabled={userRoleType === UserType.USER_ROLE_USER}
          ></TreeSelectionInput>
          <br />
          <Button
            type="primary"
            onClick={generateReport}
            disabled={!isReportParamValid}
            loading={isReportGenerating}
          >
            {t("report.generate_report")}
          </Button>
        </div>
      </Col>
    </Row>
  );
}
