import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { SelectionInput } from "../../common/SelectionInput";
import ImageInput from "../../common/ImageInput";
import EmpyList from "./EmpyList";
import EmpyChildModal from "./EmpyChildModal";
import EmpyEduModal from "./EmpyEduModal";
import EmpyAttachmentModal from "./EmpyAttachmentModal";
import dayjs from "dayjs";
import {
  CheckCircleTwoTone,
  EyeOutlined,
  QuestionCircleTwoTone,
} from "@ant-design/icons";
import {
  Input,
  Card,
  Spin,
  DatePicker,
  Typography,
  Select,
  Row,
  Col,
  Button,
  Anchor,
  Switch,
  Tooltip,
  Form,
  Divider,
  InputNumber,
} from "antd";
import ProfileChildModel from "../../model/Profile/ProfileChildModel";
import ProfileEducationModel from "../../model/Profile/ProfileEducationModel";
import ProfileAttachmentModel from "../../model/Profile/ProfileAttachmentModel";
import { regionLocale } from "../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const EmployeeForm = ({
  flag,
  fileList,
  setFileList,
  attachmentDataSource,
  allBanks,
  allCountrys,
  empyDataSource,
  childDataSource,
  eduDataSource,
  handleChildModalSave,
  handleEduModalSave,
  handleBNMCode,
  handleSelectChange,
  handleSpouseDate,
  handleSpouseDOMDate,
  handleImageChange,
  handleChildDelete,
  handleEduDelete,
  handleAttchModalSave,
  handleAttchDelete,
  handleChangePassword,
  handleSubmit,
  setIsDirty,
  showSalary,
  setShowSalaryFlag,
  region,
  form,
}) => {
  const { t } = useTranslation();
  const newProfileChild = ProfileChildModel({});
  const newProfileEducation = ProfileEducationModel({});
  const newProfileAttachment = ProfileAttachmentModel({});

  Form.useWatch("PhyBankAcc", form);

  const Empy = empyDataSource;
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
    labelAlign: "left",
  };

  const inputformItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: {
      span: 24,
    },
  };

  //#region Initialize Modal Form
  const [formChild] = Form.useForm();
  const [isFormChildInitialized, setIsFormChildInitialized] = useState(false);
  useEffect(() => formChild.resetFields(), [isFormChildInitialized, formChild]);

  const [formEducation] = Form.useForm();
  const [isFormEduInitialized, setIsFormEduInitialized] = useState(false);
  useEffect(
    () => formEducation.resetFields(),
    [isFormEduInitialized, formEducation]
  );

  const [formAttach] = Form.useForm();
  const [isFormAttachInitialized, setIsFormAttachInitialized] = useState(false);
  useEffect(
    () => formAttach.resetFields(),
    [isFormAttachInitialized, formAttach]
  );

  //Handle Save Attachment
  useEffect(() => {
    if (!flag.isLoadingAttch) {
      setShowAttachmentForm(false);
    }
  }, [flag.isLoadingAttch]);
  //#endregion

  //#region Declare Show/Hide Modal
  const [showChildForm, setShowChildForm] = useState(false);
  const [selectedChild, setSelectedChild] = useState(newProfileChild);

  const [showEducationForm, setShowEducationForm] = useState(false);
  const [selectedEducation, setSelectedEducation] =
    useState(newProfileEducation);

  const [showAttachmentForm, setShowAttachmentForm] = useState(false);
  const [selectedAttachment, setSelectedAttachment] =
    useState(newProfileAttachment);
  //#endregion

  //#region Child
  function addChild() {
    setSelectedChild(newProfileChild);
    setShowChildForm(true);
    setIsFormChildInitialized(!isFormChildInitialized);
  }

  function editChild(editData) {
    let editChild = childDataSource.filter(
      (item) => item.EmpyChildDetKey === editData.EmpyChildDetKey
    );
    let selectedChild = { ...editChild };

    setSelectedChild(selectedChild[0]);
    setShowChildForm(true);
    setIsFormChildInitialized(!isFormChildInitialized);
  }

  function handleSaveChild(child) {
    if (child !== undefined || child !== null) {
      handleChildModalSave(child);

      setShowChildForm(false);
    }
  }

  function cancelInputChild() {
    setSelectedChild(newProfileChild);
    setShowChildForm(false);
  }
  //#endregion

  //#region Education
  function addEducation() {
    setSelectedEducation(newProfileEducation);
    setShowEducationForm(true);
    setIsFormEduInitialized(!isFormEduInitialized);
  }

  function editEducation(editData) {
    let editEducation = eduDataSource.filter(
      (item) => item.EmpyEduDetKey === editData.EmpyEduDetKey
    );
    let selectedEducation = { ...editEducation };

    setSelectedEducation(selectedEducation[0]);
    setShowEducationForm(true);
    setIsFormEduInitialized(!isFormEduInitialized);
  }

  function handleSaveEducation(education) {
    if (education !== undefined || education !== null) {
      handleEduModalSave(education);

      setShowEducationForm(false);
    }
  }

  function cancelInputEducation() {
    setSelectedEducation(newProfileEducation);
    setShowEducationForm(false);
  }
  //#endregion

  //#region Attachment
  function addAttachment() {
    setSelectedAttachment(newProfileAttachment);
    setShowAttachmentForm(true);
    setIsFormAttachInitialized(!isFormAttachInitialized);
  }

  function editAttachment(editData) {
    let uploadedFileList = {
      uid: editData.AttchKey,
      name: editData.FileName,
      status: "done",
      url: editData.SecuredURL,
    };

    setFileList([...fileList, uploadedFileList]);
    setSelectedAttachment(editData);
    setShowAttachmentForm(true);
    setIsFormAttachInitialized(!isFormAttachInitialized);
  }

  function handleSaveAttachment(attachment) {
    if (attachment !== undefined || attachment !== null) {
      handleAttchModalSave(attachment);
    }
  }

  function cancelInputAttachment() {
    setFileList([]);
    setSelectedAttachment(newProfileAttachment);
    setShowAttachmentForm(false);
  }
  //#endregion

  function getAnchorItems() {
    let anchorItems = [
      {
        key: "GD",
        href: "#GD",
        title: t("employeeForm.general_details"),
      },
      {
        key: "EP",
        href: "#EP",
        title: t("employeeForm.employment"),
      },
      {
        key: "Sal",
        href: "#Sal",
        title: t("employeeForm.salary"),
      },
      {
        key: "Con",
        href: "#Con",
        title: t("employeeForm.contribution"),
      },
      {
        key: "EPF",
        href: "#EPF",
        title: t("employeeForm.epf"),
      },
      {
        key: "SEP",
        href: "#SEP",
        title: t("employeeForm.socso_eis_pcb_baitulmal"),
      },
      {
        key: "FM",
        href: "#FM",
        title: t("employeeForm.family"),
      },
      {
        key: "CD",
        href: "#CD",
        title: t("claimTypesForm.children"),
      },
      {
        key: "CA",
        href: "#CA",
        title: t("employeeForm.contact_address"),
      },
      {
        key: "ED",
        href: "#ED",
        title: t("employeeForm.education"),
      },
      {
        key: "AT",
        href: "#AT",
        title: t("general.attachment"),
      },
    ];
    if (region === "I") {
      anchorItems = anchorItems.filter(
        (x) => x.key !== "EPF" && x.key !== "SEP"
      );
    } else {
      anchorItems = anchorItems.filter((x) => x.key !== "Con");
    }
    return anchorItems;
  }

  return (
    <Spin spinning={flag.isLoading}>
      <Form
        form={form}
        {...formItemLayout}
        onFinish={(values) => {
          handleSubmit(values, form);
        }}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={20} xl={20}>
            <Row>
              <Col
                id="empy-scroll-container"
                style={{
                  height: "calc(100vh - 154px)",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <Row id="GD" className="setup-layout">
                  <Card
                    title={
                      <span style={{ color: "#ffffff" }}>
                        {t("employeeForm.general_details")}
                      </span>
                    }
                  >
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={8}>
                        <h3 style={{ margin: "auto" }}>{Empy.EmpyName}</h3>
                        <br></br>
                        <ImageInput
                          imageURL={Empy.EmpyPerMasPhotoUrl}
                          onChange={handleImageChange}
                        />
                        <br></br>
                        <span
                          style={{
                            fontSize: 12,
                            fontStyle: "italic",
                            color: "red",
                          }}
                        >
                          {t("employeeForm.max_file_size_4mb")}
                        </span>
                        <br></br>
                        <br></br>
                        <Button
                          type="link"
                          onClick={handleChangePassword}
                          style={{ paddingLeft: 0 }}
                        >
                          {t("forgotPasswordForm.change_password")}
                        </Button>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={8}>
                        <Form.Item label={t("general.employee_id")}>
                          : {Empy.EmpyID}
                        </Form.Item>
                        <Form.Item label={t("leaveTypesForm.gender")}>
                          :{" "}
                          {typeof Empy.Gender == "undefined" ||
                          Empy.Gender === ""
                            ? ""
                            : Empy.Gender === "M"
                            ? "Male"
                            : "Female"}
                        </Form.Item>
                        <Form.Item label={t("employeeForm.date_of_birth")}>
                          : {dayjs(Empy.DOB, "YYYY/MM/DD").format("DD/MM/YYYY")}
                        </Form.Item>
                        <Form.Item label={t("employeeForm.nationality")}>
                          : {Empy.NationalDesc}
                        </Form.Item>
                        <Form.Item label="Race">: {Empy.RaceDesc}</Form.Item>
                        <Form.Item label={t("general.operating_unit")}>
                          : {Empy.OUCodeOUDesc}
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
                        <Form.Item
                          label={
                            region === "M"
                              ? t("employeeForm.nric_no")
                              : t("employeeForm.ktp_no")
                          }
                        >
                          : {region === "M" ? Empy.IDNo : Empy.KTPNoI}
                        </Form.Item>
                        <Form.Item label={t("employeeForm.passport_no")}>
                          : {Empy.PassportNo}
                        </Form.Item>
                        <Form.Item label={t("general.department")}>
                          : {Empy.DeptCodeDeptDesc}
                        </Form.Item>
                        <Form.Item label={t("general.position")}>
                          : {Empy.PostDesc}
                        </Form.Item>
                        <Form.Item label={t("general.grade")}>
                          : {Empy.GradeDesc}
                        </Form.Item>
                        <Form.Item label={t("general.email")}>
                          : {Empy.LoginEmail}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Row>
                <br></br>
                <Row id="EP" className="setup-layout">
                  <Card
                    title={
                      <span style={{ color: "#ffffff" }}>
                        {t("employeeForm.employment")}
                      </span>
                    }
                  >
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
                        <Form.Item label={t("employeeForm.date_joined")}>
                          :{" "}
                          {dayjs(Empy.HiredDate, "YYYY/MM/DD").format(
                            "DD/MM/YYYY"
                          )}
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
                        <Form.Item label={t("employeeForm.first_working_date")}>
                          :{" "}
                          {dayjs(Empy.FirstDayWork, "YYYY/MM/DD").format(
                            "DD/MM/YYYY"
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Row>
                <br></br>
                <Row id="Sal" className="setup-layout">
                  <Card
                    title={
                      <span style={{ color: "#ffffff" }}>
                        {t("employeeForm.salary")}
                      </span>
                    }
                  >
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
                        <Form.Item label={t("employeeForm.monthly_amount")}>
                          <>
                            <Row gutter={8}>
                              <Col span={18} style={{ top: 1.5 }}>
                                <Typography>
                                  :{" "}
                                  {showSalary === true
                                    ? Empy.MRate.toLocaleString(
                                        regionLocale(),
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )
                                    : "***********"}
                                </Typography>
                              </Col>
                              <Col span={6} style={{ top: 1.5 }}>
                                <Button
                                  size="small"
                                  type="link"
                                  icon={<EyeOutlined />}
                                  onClick={setShowSalaryFlag}
                                />
                              </Col>
                            </Row>
                          </>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                        <Form.Item
                          label={t("employeeForm.payslip_email")}
                          {...inputformItemLayout}
                          name="Email"
                          initialValue={Empy.Email}
                        >
                          <Input
                            type="text"
                            name="Email"
                            placeholder={t(
                              "employeeForm.payslip_email_placeholder"
                            )}
                            maxLength="100"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                        <Row gutter={8}>
                          <Col span={22}>
                            <Form.Item
                              label={t("employeeForm.physical_bank_ac_no")}
                              {...inputformItemLayout}
                              name="PhyBankAcc"
                              rules={[
                                {
                                  required:
                                    form.getFieldValue("BNMCode") !== undefined,
                                  message: t(
                                    "employeeForm.physical_bank_ac_no_required_validation"
                                  ),
                                },
                                {
                                  max: 20,
                                  message: t(
                                    "employeeForm.physical_bank_ac_no_max_length_validation"
                                  ),
                                },
                                {
                                  validator: (_, value) => {
                                    if (value === "") {
                                      form.setFields([
                                        {
                                          name: "BNMCode",
                                          errors: [""],
                                          validating: true,
                                        },
                                      ]);
                                    }

                                    return Promise.resolve();
                                  },
                                },
                              ]}
                              initialValue={Empy.PhyBankAcc}
                            >
                              <Input
                                type="text"
                                name="PhyBankAcc"
                                placeholder={t(
                                  "employeeForm.physical_bank_ac_no_placeholder"
                                )}
                                maxLength="20"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ top: 38 }}>
                            {flag.PhyBankAcc ? (
                              <Tooltip
                                title={t("employeeForm.latest_information")}
                              >
                                <CheckCircleTwoTone
                                  twoToneColor="#00d376"
                                  className="status-icon"
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={t("employeeForm.pending_approval")}
                              >
                                <QuestionCircleTwoTone
                                  twoToneColor="#f6263d"
                                  className="status-icon"
                                />
                              </Tooltip>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                        <Row gutter={8}>
                          <Col span={22}>
                            <Form.Item
                              label={t("employeeForm.bank_name")}
                              {...inputformItemLayout}
                              name="BNMCode"
                              rules={[
                                {
                                  required:
                                    form.getFieldValue("PhyBankAcc") !== "",
                                  message: t(
                                    "employeeForm.bank_name_required_validation"
                                  ),
                                },
                                {
                                  validator: (_, value) => {
                                    if (value === undefined) {
                                      form.setFields([
                                        {
                                          name: "PhyBankAcc",
                                          errors: [""],
                                          validating: true,
                                        },
                                      ]);
                                    }

                                    return Promise.resolve();
                                  },
                                },
                              ]}
                              initialValue={
                                Empy.BNMCode !== ""
                                  ? {
                                      key: Empy.BNMCode,
                                      value: Empy.BNMCode,
                                    }
                                  : undefined
                              }
                            >
                              <SelectionInput
                                allowClear={true}
                                getPopupContainer={(triggerNode) =>
                                  triggerNode.parentNode
                                }
                                ref={React.createRef()}
                                labelInValue={true}
                                data={allBanks}
                                valueProp={"BNMCode"}
                                textProp={"BankName"}
                                placeholder={t(
                                  "employeeForm.bank_name_placeholder"
                                )}
                                onChange={(value) => handleBNMCode(value)}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ top: 38 }}>
                            {flag.BNMCode ? (
                              <Tooltip
                                title={t("employeeForm.latest_information")}
                              >
                                <CheckCircleTwoTone
                                  twoToneColor="#00d376"
                                  className="status-icon"
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={t("employeeForm.pending_approval")}
                              >
                                <QuestionCircleTwoTone
                                  twoToneColor="#f6263d"
                                  className="status-icon"
                                />
                              </Tooltip>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Row>
                <br></br>

                <Row
                  id="Con"
                  className="setup-layout"
                  style={{
                    display: region === "M" ? "none" : "",
                  }}
                >
                  <Card
                    title={
                      <span style={{ color: "#ffffff" }}>
                        {t("employeeForm.contribution")}
                      </span>
                    }
                  >
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                        <Form.Item
                          label={t("employeeForm.bpjs_ketenagakerjaan_ref_no")}
                        >
                          : {Empy.BPJSKetenagakerjaanRefNo}
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                        <Form.Item
                          label={t("employeeForm.bpjs_pensiun_ref_no")}
                        >
                          : {Empy.BPJSPensiunRefNo}
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                        <Form.Item
                          label={t("employeeForm.bpjs_kesehatan_ref_no")}
                        >
                          : {Empy.BPJSKesehatanRefNo}
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                        <Form.Item label={t("employeeForm.npwp_no")}>
                          : {Empy.NPWPI}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                  <br></br>
                </Row>

                <Row
                  id="EPF"
                  className="setup-layout"
                  style={{
                    display: region === "I" ? "none" : "",
                  }}
                >
                  <Card
                    title={
                      <span style={{ color: "#ffffff" }}>
                        {t("employeeForm.epf")}
                      </span>
                    }
                  >
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                        <Form.Item label={t("employeeForm.member_no")}>
                          : {Empy.EPFNo}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                  <br></br>
                </Row>

                <Row
                  id="SEP"
                  className="setup-layout"
                  style={{
                    display: region === "I" ? "none" : "",
                  }}
                >
                  <Card
                    title={
                      <span style={{ color: "#ffffff" }}>
                        {t("employeeForm.socso_eis_pcb_baitulmal")}
                      </span>
                    }
                  >
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
                        <Form.Item label={t("employeeForm.socso_no")}>
                          : {Empy.SOCSONo}
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
                        <Form.Item
                          label={t("employeeForm.tax_identification_no")}
                        >
                          : {Empy.TaxNo}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                  <br></br>
                </Row>

                <Row id="FM" className="setup-layout">
                  <Card
                    title={
                      <span style={{ color: "#ffffff" }}>
                        {t("employeeForm.family")}
                      </span>
                    }
                  >
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Row gutter={8}>
                          <Col span={22}>
                            <Form.Item
                              label={t("employeeForm.marital_status")}
                              {...inputformItemLayout}
                              name="MaritalStatus"
                              initialValue={Empy.MaritalStatus}
                            >
                              <Select
                                showSearch
                                placeholder={t(
                                  "employeeForm.marital_status_placeholder"
                                )}
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                onChange={(value) => handleSelectChange(value)}
                              >
                                <Option value="S">
                                  {t("employeeForm.single")}
                                </Option>
                                <Option value="M">
                                  {t("employeeForm.married")}
                                </Option>
                                <Option value="D">
                                  {t("employeeForm.divorce")}
                                </Option>
                                <Option value="W">
                                  {t("employeeForm.widowed")}
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ top: 38 }}>
                            {flag.MaritalStatus ? (
                              <Tooltip
                                title={t("employeeForm.latest_information")}
                              >
                                <CheckCircleTwoTone
                                  twoToneColor="#00d376"
                                  className="status-icon"
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={t("employeeForm.pending_approval")}
                              >
                                <QuestionCircleTwoTone
                                  twoToneColor="#f6263d"
                                  className="status-icon"
                                />
                              </Tooltip>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row gutter={24}>
                      <Divider orientation="left">
                        {t("claimTypesForm.spouse")}
                      </Divider>
                      <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                        <Row gutter={8}>
                          <Col span={22}>
                            <Form.Item
                              label={t("employeeForm.spouse_name")}
                              {...inputformItemLayout}
                              name="SpouseName"
                              initialValue={Empy.SpouseName}
                            >
                              <Input
                                type="text"
                                name="SpouseName"
                                disabled={Empy.MaritalStatus === "S"}
                                maxLength="100"
                                placeholder={t(
                                  "employeeForm.spouse_name_placeholder"
                                )}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ top: 38 }}>
                            {flag.SpouseName ? (
                              <Tooltip
                                title={t("employeeForm.latest_information")}
                              >
                                <CheckCircleTwoTone
                                  twoToneColor="#00d376"
                                  className="status-icon"
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={t("employeeForm.pending_approval")}
                              >
                                <QuestionCircleTwoTone
                                  twoToneColor="#f6263d"
                                  className="status-icon"
                                />
                              </Tooltip>
                            )}
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Row gutter={8}>
                          <Col span={22}>
                            <Form.Item
                              label={
                                region === "M"
                                  ? t("employeeForm.spouse_ic")
                                  : t("employeeForm.spouse_ktp_no")
                              }
                              {...inputformItemLayout}
                              name="SpouseID"
                              initialValue={Empy.SpouseID}
                            >
                              <Input
                                type="text"
                                name="SpouseID"
                                disabled={Empy.MaritalStatus === "S"}
                                maxLength="100"
                                placeholder={
                                  region === "M"
                                    ? t("employeeForm.spouse_ic_placeholder")
                                    : t(
                                        "employeeForm.spouse_ktp_no_placeholder"
                                      )
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ top: 38 }}>
                            {flag.SpouseID ? (
                              <Tooltip
                                title={t("employeeForm.latest_information")}
                              >
                                <CheckCircleTwoTone
                                  twoToneColor="#00d376"
                                  className="status-icon"
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={t("employeeForm.pending_approval")}
                              >
                                <QuestionCircleTwoTone
                                  twoToneColor="#f6263d"
                                  className="status-icon"
                                />
                              </Tooltip>
                            )}
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                        <Row gutter={8}>
                          <Col span={22}>
                            <Form.Item
                              label={t("employeeForm.date_of_birth")}
                              {...inputformItemLayout}
                              name="SpouseDOB"
                              initialValue={
                                Empy.SpouseDOB
                                  ? dayjs(new Date(Empy.SpouseDOB))
                                  : undefined
                              }
                            >
                              <DatePicker
                                allowClear={false}
                                placeholder={t("employeeForm.date_of_birth")}
                                format={"DD/MM/YYYY"}
                                onChange={(value) => handleSpouseDate(value)}
                                disabled={Empy.MaritalStatus === "S"}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ top: 38 }}>
                            {flag.SpouseDOB ? (
                              <Tooltip
                                title={t("employeeForm.latest_information")}
                              >
                                <CheckCircleTwoTone
                                  twoToneColor="#00d376"
                                  className="status-icon"
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={t("employeeForm.pending_approval")}
                              >
                                <QuestionCircleTwoTone
                                  twoToneColor="#f6263d"
                                  className="status-icon"
                                />
                              </Tooltip>
                            )}
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                        <Row gutter={8}>
                          <Col span={22}>
                            <Form.Item
                              label={t("employeeForm.date_of_marriage")}
                              {...inputformItemLayout}
                              name="SpouseDOM"
                              initialValue={
                                Empy.SpouseDOM
                                  ? dayjs(new Date(Empy.SpouseDOM))
                                  : undefined
                              }
                            >
                              <DatePicker
                                allowClear={false}
                                placeholder={t("employeeForm.date_of_marriage")}
                                format={"DD/MM/YYYY"}
                                onChange={(value) => handleSpouseDOMDate(value)}
                                disabled={Empy.MaritalStatus === "S"}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ top: 38 }}>
                            {flag.SpouseDOM ? (
                              <Tooltip
                                title={t("employeeForm.latest_information")}
                              >
                                <CheckCircleTwoTone
                                  twoToneColor="#00d376"
                                  className="status-icon"
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={t("employeeForm.pending_approval")}
                              >
                                <QuestionCircleTwoTone
                                  twoToneColor="#f6263d"
                                  className="status-icon"
                                />
                              </Tooltip>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row gutter={24}>
                      <Col xs={24} sm={24} md={24} lg={3} xl={3}>
                        <Row gutter={8}>
                          <Col span={16}>
                            <Form.Item
                              label={t("employeeForm.working")}
                              {...inputformItemLayout}
                              name="IsSpouseWork"
                              valuePropName="checked"
                              initialValue={Empy.IsSpouseWork}
                            >
                              <Switch
                                checkedChildren={t("general.yes")}
                                unCheckedChildren={t("general.no")}
                                disabled={Empy.MaritalStatus === "S"}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ top: 38 }}>
                            {flag.IsSpouseWork ? (
                              <Tooltip
                                title={t("employeeForm.latest_information")}
                              >
                                <CheckCircleTwoTone
                                  twoToneColor="#00d376"
                                  className="status-icon"
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={t("employeeForm.pending_approval")}
                              >
                                <QuestionCircleTwoTone
                                  twoToneColor="#f6263d"
                                  className="status-icon"
                                />
                              </Tooltip>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={3}
                        xl={3}
                        style={{
                          display: region === "M" ? "" : "none",
                        }}
                      >
                        <Row gutter={8}>
                          <Col span={16}>
                            <Form.Item
                              label={t("employeeForm.disabled_person")}
                              {...inputformItemLayout}
                              name="IsDisableSpouse"
                              valuePropName="checked"
                              initialValue={Empy.IsDisableSpouse}
                            >
                              <Switch
                                checkedChildren={t("general.yes")}
                                unCheckedChildren={t("general.no")}
                                disabled={Empy.MaritalStatus === "S"}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ top: 38 }}>
                            {flag.IsDisableSpouse ? (
                              <Tooltip
                                title={t("employeeForm.latest_information")}
                              >
                                <CheckCircleTwoTone
                                  twoToneColor="#00d376"
                                  className="status-icon"
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={t("employeeForm.pending_approval")}
                              >
                                <QuestionCircleTwoTone
                                  twoToneColor="#f6263d"
                                  className="status-icon"
                                />
                              </Tooltip>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row gutter={24} style={{ marginTop: "15px" }}>
                      <Divider orientation="left">
                        {t("employeeForm.emergency")}
                      </Divider>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Form.Item
                          label={t("general.name")}
                          {...inputformItemLayout}
                          name="EmergContactName"
                          initialValue={Empy.EmergContactName}
                        >
                          <Input
                            type="text"
                            name="EmergContactName"
                            maxLength="50"
                            placeholder={t("general.name_placeholder")}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                          label={t("employeeForm.telephone")}
                          {...inputformItemLayout}
                          name="EmergContactTel"
                          initialValue={Empy.EmergContactTel}
                        >
                          <Input
                            type="tel"
                            name="EmergContactTel"
                            maxLength="20"
                            placeholder={t(
                              "employeeForm.telephone_placeholder"
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                          label={t("employeeForm.relationship")}
                          {...inputformItemLayout}
                          name="EmergContactRelate"
                          initialValue={Empy.EmergContactRelate}
                        >
                          <Input
                            type="text"
                            name="EmergContactRelate"
                            maxLength="20"
                            placeholder={t(
                              "employeeForm.relationship_placeholder"
                            )}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Row>
                <br></br>
                <Row id="CD" className="setup-layout">
                  <Card
                    title={
                      <span style={{ color: "#ffffff" }}>
                        {t("claimTypesForm.children")}
                      </span>
                    }
                  >
                    <Col span={24}>
                      <EmpyList
                        dataSource={childDataSource}
                        title={"EmpyChildName"}
                        desc_name1={t("employeeForm.education")}
                        desc_value1={"EduLevel"}
                        desc_name2={t("employeeForm.school")}
                        desc_value2={"SchName"}
                        desc_name3={
                          region === "M"
                            ? t("employeeForm.ic_no_passport_no")
                            : t("employeeForm.ktp_no")
                        }
                        desc_value3={"IDNo"}
                        content={"EmpyChildDOB"}
                        onAdd={addChild}
                        onEdit={editChild}
                        onDelete={handleChildDelete}
                      />
                      <EmpyChildModal
                        form={formChild}
                        handleSave={handleSaveChild}
                        handleCancel={cancelInputChild}
                        isVisible={showChildForm}
                        child={selectedChild}
                        region={region}
                        setIsDirty={setIsDirty}
                      />
                    </Col>
                  </Card>
                </Row>
                <br></br>
                <Row id="CA" className="setup-layout">
                  <Card
                    title={
                      <span style={{ color: "#ffffff" }}>
                        {t("employeeForm.contact_address")}
                      </span>
                    }
                  >
                    <Row gutter={12}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                        <Form.Item
                          label={t("employeeForm.telephone")}
                          {...inputformItemLayout}
                          name="Tel"
                          initialValue={Empy.Tel}
                        >
                          <Input
                            type="tel"
                            name="Tel"
                            maxLength="20"
                            placeholder={t(
                              "employeeForm.telephone_placeholder"
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                        <Form.Item
                          label={t("employeeForm.mobile_no")}
                          {...inputformItemLayout}
                          name="Mobile"
                          initialValue={Empy.Mobile}
                        >
                          <Input
                            type="tel"
                            name="Mobile"
                            maxLength="20"
                            placeholder={t(
                              "employeeForm.mobile_no_placeholder"
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                        <Form.Item
                          label={t("employeeForm.fax_no")}
                          {...inputformItemLayout}
                          name="Fax"
                          initialValue={Empy.Fax}
                        >
                          <Input
                            type="tel"
                            name="Fax"
                            maxLength="20"
                            placeholder={t("employeeForm.fax_no_placeholder")}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={12}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Row gutter={24} style={{ marginTop: "25px" }}>
                          <Divider orientation="left">
                            {t("employeeForm.permanent_address")}
                          </Divider>
                          <Col span={24}>
                            <Form.Item
                              label={t("employeeForm.street")}
                              {...inputformItemLayout}
                              name="PermStreet"
                              initialValue={Empy.PermStreet}
                            >
                              <Input
                                type="text"
                                name="PermStreet"
                                maxLength="100"
                                placeholder={t(
                                  "employeeForm.street_placeholder"
                                )}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                              label={t("employeeForm.postcode")}
                              {...inputformItemLayout}
                              name="PermPostCode"
                              initialValue={Empy.PermPostCode}
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                precision={0}
                                name="PermPostCode"
                                maxLength="50"
                                placeholder={t(
                                  "employeeForm.postcode_placeholder"
                                )}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                              label={t("employeeForm.city")}
                              {...inputformItemLayout}
                              name="PermCity"
                              initialValue={Empy.PermCity}
                            >
                              <Input
                                type="text"
                                name="PermCity"
                                maxLength="50"
                                placeholder={t("employeeForm.city_placeholder")}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                              label={t("employeeForm.state")}
                              {...inputformItemLayout}
                              name="PermState"
                              initialValue={Empy.PermState}
                            >
                              <Input
                                type="text"
                                name="PermState"
                                maxLength="50"
                                placeholder={t(
                                  "employeeForm.state_placeholder"
                                )}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                              label={t("employeeForm.country")}
                              {...inputformItemLayout}
                              name="PermCtryKey"
                              initialValue={
                                Empy.PermCtryKey > 0
                                  ? {
                                      key: Empy.PermCtryKey,
                                      value: Empy.PermCtryKey,
                                    }
                                  : undefined
                              }
                            >
                              <SelectionInput
                                getPopupContainer={(triggerNode) =>
                                  triggerNode.parentNode
                                }
                                ref={React.createRef()}
                                labelInValue={true}
                                data={allCountrys}
                                valueProp={"CtryKey"}
                                textProp={"CtryDesc"}
                                placeholder={t(
                                  "employeeForm.country_placeholder"
                                )}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Row gutter={24} style={{ marginTop: "25px" }}>
                          <Divider orientation="left">
                            {t("employeeForm.mailing_address")}
                          </Divider>
                          <Col span={24}>
                            <Form.Item
                              label={t("employeeForm.street")}
                              {...inputformItemLayout}
                              name="MailStreet"
                              initialValue={Empy.MailStreet}
                            >
                              <Input
                                type="text"
                                name="MailStreet"
                                maxLength="100"
                                placeholder={t(
                                  "employeeForm.street_placeholder"
                                )}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                              label={t("employeeForm.postcode")}
                              {...inputformItemLayout}
                              name="MailPostCode"
                              initialValue={Empy.MailPostCode}
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                precision={0}
                                name="MailPostCode"
                                maxLength="50"
                                placeholder={t(
                                  "employeeForm.postcode_placeholder"
                                )}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                              label={t("employeeForm.city")}
                              {...inputformItemLayout}
                              name="MailCity"
                              initialValue={Empy.MailCity}
                            >
                              <Input
                                type="text"
                                name="MailCity"
                                maxLength="50"
                                placeholder={t("employeeForm.city_placeholder")}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                              label={t("employeeForm.state")}
                              {...inputformItemLayout}
                              name="MailState"
                              initialValue={Empy.MailState}
                            >
                              <Input
                                type="text"
                                name="MailState"
                                maxLength="50"
                                placeholder={t(
                                  "employeeForm.state_placeholder"
                                )}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                              label={t("employeeForm.country")}
                              {...inputformItemLayout}
                              name="MailCtryKey"
                              initialValue={
                                Empy.MailCtryKey > 0
                                  ? {
                                      key: Empy.MailCtryKey,
                                      value: Empy.MailCtryKey,
                                    }
                                  : undefined
                              }
                            >
                              <SelectionInput
                                getPopupContainer={(triggerNode) =>
                                  triggerNode.parentNode
                                }
                                ref={React.createRef()}
                                labelInValue={true}
                                data={allCountrys}
                                valueProp={"CtryKey"}
                                textProp={"CtryDesc"}
                                placeholder={t(
                                  "employeeForm.country_placeholder"
                                )}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Row>
                <br></br>
                <Row id="ED" className="setup-layout">
                  <Card
                    title={
                      <span style={{ color: "#ffffff" }}>
                        {t("employeeForm.education")}
                      </span>
                    }
                  >
                    <Col span={24}>
                      <EmpyList
                        dataSource={eduDataSource}
                        title={"Education"}
                        desc_name1={t("general.year")}
                        desc_value1={"Year"}
                        desc_name2={t("general.remarks")}
                        desc_value2={"Remarks"}
                        desc_name3={""}
                        desc_value3={""}
                        content={""}
                        onAdd={addEducation}
                        onEdit={editEducation}
                        onDelete={handleEduDelete}
                      />
                      <EmpyEduModal
                        form={formEducation}
                        handleSave={handleSaveEducation}
                        handleCancel={cancelInputEducation}
                        isVisible={showEducationForm}
                        education={selectedEducation}
                        setIsDirty={setIsDirty}
                      />
                    </Col>
                  </Card>
                </Row>
                <br></br>
                <Row id="AT" className="setup-layout">
                  <Card
                    title={
                      <span style={{ color: "#ffffff" }}>
                        {t("general.attachment")}
                      </span>
                    }
                  >
                    <EmpyList
                      dataSource={attachmentDataSource}
                      title={"Attachment"}
                      desc_name1={t("employeeForm.document_reference")}
                      desc_value1={"FileName"}
                      desc_name2={t("general.remarks")}
                      desc_value2={"Remarks"}
                      desc_name3={""}
                      desc_value3={""}
                      content={""}
                      onAdd={addAttachment}
                      onEdit={editAttachment}
                      onDelete={handleAttchDelete}
                    />
                    <EmpyAttachmentModal
                      form={formAttach}
                      handleSave={handleSaveAttachment}
                      handleCancel={cancelInputAttachment}
                      isVisible={showAttachmentForm}
                      fileList={fileList}
                      setFileList={setFileList}
                      attachment={selectedAttachment}
                      setIsDirty={setIsDirty}
                      isLoadingAttch={flag.isLoadingAttch}
                    />
                  </Card>
                </Row>
              </Col>

              <Row
                style={{
                  textAlign: "right",
                  backgroundColor: "#f7f9fa",
                  border: "1px solid #e8e8e8",
                  width: "100%",
                  display: "block",
                }}
              >
                <Col style={{ textAlign: "right", padding: "10px" }}>
                  <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                      <Button size="large" type="primary" htmlType="submit">
                        {t("general.save")}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Row>
          </Col>
          <Col
            xs={0}
            sm={0}
            md={0}
            lg={5}
            xl={4}
            style={{
              height: "calc(100vh - 93.5px)",
              overflowY: "auto",
              overflowX: "hidden",
              backgroundColor: "#fff",
              padding: "20px",
              fontSize: "9px !important",
            }}
          >
            <Anchor
              affix={true}
              getContainer={() =>
                document.getElementById("empy-scroll-container")
              }
              items={getAnchorItems()}
            ></Anchor>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

EmployeeForm.propTypes = {
  flag: PropTypes.object.isRequired,
  fileList: PropTypes.array.isRequired,
  setFileList: PropTypes.func.isRequired,
  allBanks: PropTypes.array.isRequired,
  allCountrys: PropTypes.array.isRequired,
  empyDataSource: PropTypes.object.isRequired,
  childDataSource: PropTypes.array.isRequired,
  eduDataSource: PropTypes.array.isRequired,
  attachmentDataSource: PropTypes.array.isRequired,
  handleChildModalSave: PropTypes.func.isRequired,
  handleEduModalSave: PropTypes.func.isRequired,
  handleAttchModalSave: PropTypes.func.isRequired,
  handleBNMCode: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleSpouseDate: PropTypes.func.isRequired,
  handleSpouseDOMDate: PropTypes.func.isRequired,
  handleImageChange: PropTypes.func.isRequired,
  handleChildDelete: PropTypes.func.isRequired,
  handleEduDelete: PropTypes.func.isRequired,
  handleAttchDelete: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChangePassword: PropTypes.func.isRequired,
  showSalary: PropTypes.bool.isRequired,
  setShowSalaryFlag: PropTypes.func.isRequired,
  region: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default EmployeeForm;
