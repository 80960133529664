export const Status = Object.freeze({
  0: "Open",
  1: "Submitted",
  2: "Cancellation Requested",
  3: "Rejected",
  4: "Partially Approved",
  5: "Approved",
  6: "Voided",
  7: "Closed",
  8: "Validated",
  66: "Published",
  88: "Off Day",
  95: "Public Holiday",
});

export const StatusByValue = Object.freeze({
  OPEN: 0,
  SUBMITTED: 1,
  REQUEST_CANCEL: 2,
  REJECTED: 3,
  PARTIAL_APPROVED: 4,
  APPROVED: 5,
  VOID: 6,
  CLOSED: 7,
  PUBLISHED: 66,
  OFF_DAY: 88,
  PUBLIC_HOLIDAY: 95,
});

export const StatusByHexColor = Object.freeze({
  0: "#00bbd3", //Open
  1: "#3e50b4", //Submitted
  2: "#5f7c8a", //Request Cancel
  3: "#e81d62", //Rejected
  4: "#fc8d56", //Partial Approved
  5: "#8ac249", //Approved
  6: "#363f45", //Void
  7: "#9370db", //Closed
  88: "#bcc4cc", //Off Day
  95: "#d4c584", //Public Holiday
});

export const StatusByCode = Object.freeze({
  SM: Status[1],
  RC: Status[2],
  RJ: Status[3],
  PA: Status[4],
  AP: Status[5],
  VD: Status[6],
  CL: Status[7],
  VL: Status[8],
});

export const EmployeeProfileStatusByCode = Object.freeze({
  PD: "Pending",
  PA: Status[4],
  AP: Status[5],
  VD: Status[6],
});

export const RowState = Object.freeze({
  1: "New", //New
  2: "Edit", //Edit
  3: "Delete", //Delete
});

export const RowStateByHexColor = Object.freeze({
  1: "#8ac249", //New
  2: "#fc8d56", //Edit
  3: "#363f45", //Delete
});

export const StatusesBySubject = Object.freeze({
  1: Object.keys(StatusByCode)
    .filter((status, index) => status !== "CL")
    .map((status) => status), //Claim
  3: Object.keys(StatusByCode)
    .filter((status, index) => status !== "CL")
    .map((status) => status), //Leave
  4: Object.keys(StatusByCode)
    .filter((status, index) => status !== "CL")
    .map((status) => status), //Clinic visit
  5: Object.keys(StatusByCode)
    .filter((status, index) => status !== "CL")
    .map((status) => status), //Travel
  6: Object.keys(StatusByCode)
    .filter((status, index) => status !== "CL")
    .map((status) => status), //Appraisal
  7: Object.keys(StatusByCode)
    .filter((status, index) => status !== "VL")
    .map((status) => status), //Vehicle requisition
  8: Object.keys(StatusByCode)
    .filter((status, index) => status !== "VL")
    .map((status) => status), //Meeting room requisition
  9: Object.keys(StatusByCode).map((status) => status), //prop maint
  10: Object.keys(StatusByCode).map((status) => status), //Recruitment
  11: Object.keys(StatusByCode)
    .filter((status, index) => status !== "CL" && status !== "VL")
    .map((status) => status), //Training
});

export function isStatusOpen(statusValue) {
  return StatusByValue.OPEN === statusValue;
}

export function isStatusSubmitted(statusValue) {
  return StatusByValue.SUBMITTED === statusValue;
}

export function isStatusRequestCancel(statusValue) {
  return StatusByValue.REQUEST_CANCEL === statusValue;
}

export function isStatusRejected(statusValue) {
  return StatusByValue.REJECTED === statusValue;
}

export function isStatusPartialApproved(statusValue) {
  return StatusByValue.PARTIAL_APPROVED === statusValue;
}

export function isStatusApproved(statusValue) {
  return StatusByValue.APPROVED === statusValue;
}

export function isViewOnly(isViewOnly) {
  return isViewOnly;
}

export function isStatusVoid(statusValue) {
  return StatusByValue.VOID === statusValue;
}

export function isStatusClosed(statusValue) {
  return StatusByValue.CLOSED === statusValue;
}

export function isStatusPublished(statusValue) {
  return StatusByValue.PUBLISHED === statusValue;
}
