import React from "react";
import PropTypes from "prop-types";
import { JobRecruitmentList } from "./JobRecruitmentList";
import { useTranslation } from "react-i18next";
import { Pagination } from "antd";

const JobRecruitmentForm = ({
  isLoading,
  isFromAdmin = false,
  onAdd,
  onView,
  data,
  onAttachmentClicked,
  setCurrentYear,
  pagination,
  setPagination,
  resetPagination = () => {},
}) => {
  const { t } = useTranslation();

  const footerElement = (totalPagination) => (
    <div style={{ textAlign: "right", marginRight: "10px" }}>
      <Pagination
        style={{ display: "inline-flex", paddingRight: 10 }}
        defaultCurrent={pagination.current}
        current={pagination.current}
        total={totalPagination}
        onChange={onChangePagination}
        showTotal={(total) =>
          t("general.total") + ` ${total} ` + t("general.records")
        }
        defaultPageSize={pagination.pageSize}
        pageSize={pagination.pageSize}
      />
    </div>
  );

  function onChangePagination(page, pageSize) {
    setPagination({
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
      pageSize: pageSize,
    });
  }

  return (
    <div>
      <JobRecruitmentList
        isLoading={isLoading}
        data={data}
        isFromAdmin={isFromAdmin}
        onAdd={onAdd}
        onItemViewed={onView}
        isShowStatus={true}
        footerElement={footerElement}
        isMultipleAction={false}
        onAttachmentClicked={onAttachmentClicked}
        setCurrentYear={setCurrentYear}
        pagination={pagination}
        setPagination={setPagination}
        resetPagination={resetPagination}
      />
    </div>
  );
};

JobRecruitmentForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  isFromAdmin: PropTypes.bool,
  onAdd: PropTypes.func,
  onView: PropTypes.func.isRequired,
  onAttachmentClicked: PropTypes.func.isRequired,
  setCurrentYear: PropTypes.func.isRequired,
  pagination: PropTypes.object,
  setPagination: PropTypes.func,
  resetPagination: PropTypes.func,
};

export default JobRecruitmentForm;
