import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import TravelAuthorizationValidationForm from "../../../forms/Claim/Travel/TravelAuthorizationValidationForm";
import {
  getPendingAndValidatedTravels,
  validateTravels,
  invalidateTravels,
} from "../../../../api/travelApprovalApi";
import { getSignedAttachmentUrl } from "../../../../api/attachApi";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export default function TravelAuthorizationValidationPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [travelTrans, setTravelTrans] = useState([]);
  const [selectedTravelTrans, setSelectedTravelTrans] = useState([]);
  const [currentYear, setCurrentYear] = useState(dayjs().year());
  const [pagination, setPagination] = useState({
    current: 1,
    minIndex: 0,
    maxIndex: 100,
    pageSize: 100,
  });

  useEffect(getTravelTrans, [currentYear]);

  function getTravelTrans() {
    setIsLoading(true);
    getPendingAndValidatedTravels(currentYear)
      .then((value) => {
        // reset pagination
        resetPagination();

        setTravelTrans(value ? value : []);
        setSelectedTravelTrans([]);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function resetPagination() {
    setPagination({
      current: 1,
      minIndex: 0,
      maxIndex: 100,
      pageSize: 100,
    });
  }

  function onAddNewTravel() {
    history("/home/travelauth/travelTrans");
  }

  function validateTravelTrans(selectedTravels) {
    setIsLoading(true);

    validateTravels(selectedTravels)
      .then(() => {
        getTravelTrans();
        toast.success(t("general.record_validated_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_validate_record"), error);
      });
  }

  function rejectTravelTrans(selectedTravels, reason) {
    setIsLoading(true);

    invalidateTravels(selectedTravels, reason)
      .then(() => {
        getTravelTrans();
        toast.success(t("general.record_rejected_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_reject_record"), error);
      });
  }

  function viewTravelTrans(travelTransKey) {
    history("/home/travelauth/travelTrans/" + travelTransKey, {
      state: { fromValidation: true },
    });
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <TravelAuthorizationValidationForm
      isLoading={isLoading}
      data={travelTrans}
      selectedItems={selectedTravelTrans}
      onAdd={onAddNewTravel}
      onView={viewTravelTrans}
      onValidated={validateTravelTrans}
      onRejected={rejectTravelTrans}
      onItemSelected={setSelectedTravelTrans}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
      pagination={pagination}
      setPagination={setPagination}
      resetPagination={resetPagination}
    />
  );
}

TravelAuthorizationValidationPage.propTypes = {
  history: PropTypes.func.isRequired,
};
