import React from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Row, Col, Modal, Pagination } from "antd";
import { AppraisalSubmissionList } from "./AppraisalSubmissionList";
import "../../../App.css";
import {
  Status,
  StatusByHexColor,
} from "../../../components/model/common/Status";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export default function AppraisalSubmissionApprovalForm({
  isLoading,
  data,
  selectedItems,
  onAdd,
  onView,
  onApproved,
  onItemSelected,
  setCurrentYear,
  pagination,
  setPagination,
  resetPagination,
}) {
  const isBetween = require("dayjs/plugin/isBetween");
  dayjs.extend(isBetween);
  const { t } = useTranslation();
  const { confirm } = Modal;

  function selectAllItems(isSelected) {
    if (onItemSelected) {
      if (isSelected) {
        onItemSelected(
          data.filter(
            (x) =>
              !x.IsViewOnly &&
              dayjs().isBetween(x.FromReviewDate, x.ToReviewDate, "day", "[]")
          )
        );
      } else {
        onItemSelected([]);
      }
    }
  }

  function getListActions(item) {
    return [
      <div key>
        <span
          style={{
            float: "left",
            fontStyle: "italic",
            marginLeft: "50px",
            color: StatusByHexColor[item.Status],
          }}
        >
          {Status[item.Status]}
        </span>

        <Button
          type="primary"
          size="default"
          style={{
            display:
              !item.IsViewOnly &&
              dayjs().isBetween(
                item.FromReviewDate,
                item.ToReviewDate,
                "day",
                "[]"
              )
                ? ""
                : "none",
          }}
          onClick={() => viewItem([item])}
        >
          {t("appraisalSubmissionForm.review")}
        </Button>

        <Button
          type="primary"
          size="default"
          style={{
            display:
              !item.IsViewOnly &&
              dayjs().isBetween(
                item.FromReviewDate,
                item.ToReviewDate,
                "day",
                "[]"
              )
                ? ""
                : "none",
            marginLeft: "10px",
          }}
          onClick={() => showApproveConfirm([item])}
        >
          {t("general.approve")}
        </Button>
      </div>,
    ];
  }

  function viewItem(item) {
    onView(item[0].AppraisalTransHdrKey);
  }

  function showApproveConfirm(item) {
    confirm({
      title: "Approval Confirmation",
      content: (
        <div>
          {t("appraisalSubmissionForm.approve_message")}
          <br></br> <b>{t("appraisalSubmissionForm.note")} : </b>
          {t("appraisalSubmissionForm.approve_message_1")}
        </div>
      ),
      okText: t("general.yes"),
      cancelText: t("general.no"),
      onOk() {
        onApproved(item);
      },
      onCancel() {},
    });
  }

  const footerElement = (footerValue, totalPagination) => (
    <Row>
      <Col span={2} style={{ paddingTop: "5px" }}>
        <Checkbox
          indeterminate={
            selectedItems.length &&
            selectedItems.length <
              data.filter(
                (x) =>
                  !x.IsViewOnly &&
                  dayjs().isBetween(
                    x.FromReviewDate,
                    x.ToReviewDate,
                    "day",
                    "[]"
                  )
              ).length
          }
          checked={
            selectedItems.length &&
            selectedItems.length ===
              data.filter(
                (x) =>
                  !x.IsViewOnly &&
                  dayjs().isBetween(
                    x.FromReviewDate,
                    x.ToReviewDate,
                    "day",
                    "[]"
                  )
              ).length
          }
          onChange={(event) => selectAllItems(event.target.checked)}
        >
          {t("general.select_all")}
        </Checkbox>
      </Col>
      <Col span={22} style={{ textAlign: "right" }}>
        <Pagination
          style={{ display: "inline-flex", paddingRight: 10 }}
          defaultCurrent={pagination.current}
          current={pagination.current}
          total={totalPagination}
          onChange={onChangePagination}
          showTotal={(total) =>
            t("general.total") + ` ${total} ` + t("general.records")
          }
          defaultPageSize={pagination.pageSize}
          pageSize={pagination.pageSize}
        />
        <Button
          type="primary"
          disabled={selectedItems.length === 0 || isLoading}
          onClick={() => showApproveConfirm(selectedItems)}
          style={{ marginLeft: "10px" }}
        >
          {t("general.approve")}
        </Button>
      </Col>
    </Row>
  );

  function onChangePagination(page, pageSize) {
    setPagination({
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
      pageSize: pageSize,
    });
  }

  return (
    <div>
      <AppraisalSubmissionList
        isLoading={isLoading}
        data={data}
        onAdd={onAdd}
        onItemViewed={onView}
        isSelectable={true}
        selectedItems={selectedItems}
        onItemSelected={onItemSelected}
        onListActionsRendered={getListActions}
        filterCheckedText={t(
          "appraisalSubmissionForm.show_pending_appraisal_review_only"
        )}
        footerElement={footerElement}
        setCurrentYear={setCurrentYear}
        pagination={pagination}
        resetPagination={resetPagination}
      />
    </div>
  );
}

AppraisalSubmissionApprovalForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onApproved: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  isSelectable: PropTypes.bool,
  setCurrentYear: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  resetPagination: PropTypes.func.isRequired,
};
