import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "EmpyPerMasReport/";

export function getEmpyInfo() {
  return axios({
    method: "GET",
    url: base + "GetEmpyInfo",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getYearMonthParam(type) {
  return axios({
    method: "GET",
    url: base + "GetYrMthParam",
    headers: authHeader(),
    params: { type: type },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getOU() {
  return axios({
    method: "GET",
    url: API_ROOT + "ou/GetAllOU",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getESSEmployee() {
  return axios({
    method: "GET",
    url: API_ROOT + "empy/GetESSEmployee?$orderby=EmpyID",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getESSEmployeeReport() {
  return axios({
    method: "GET",
    url: API_ROOT + "empy/GetESSEmployeeReport?$orderby=EmpyID",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getClaimYrMth() {
  return axios({
    method: "GET",
    url: base + "GetClaimYrMth",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getDepartment() {
  return axios({
    method: "GET",
    url: base + "GetDepartment",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getLeaveType() {
  return axios({
    method: "GET",
    url: base + "GetLeaveType",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getClinic() {
  return axios({
    method: "GET",
    url: base + "GetClinic",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getEmployeeUnderApproverClaim() {
  return axios({
    method: "GET",
    url: API_ROOT + "claimapproval/GetEmployeeUnderApprover",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getEmployeeUnderApproverLeaves() {
  return axios({
    method: "GET",
    url: API_ROOT + "leaveapproval/GetEmployeeUnderApprover",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getPayrollCycleInfo(yr, mth, ouKey) {
  return axios({
    method: "GET",
    url: base + "GetPayrollCycleInfo",
    headers: authHeader(),
    params: { yr: yr, mth: mth, ouKey: ouKey },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getEmployeeUnderApproverVehicles() {
  return axios({
    method: "GET",
    url: API_ROOT + "vehiclerequisitionapproval/GetEmployeeUnderApprover",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getEmployeeUnderApproverRooms() {
  return axios({
    method: "GET",
    url: API_ROOT + "roomrequisitionapproval/GetEmployeeUnderApprover",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getEmployeeUnderApproverPropMaints() {
  return axios({
    method: "GET",
    url: API_ROOT + "propmaintapproval/GetEmployeeUnderApprover",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getEmployeeUnderApproverTrainings() {
  return axios({
    method: "GET",
    url: API_ROOT + "trainingapplicationapproval/GetEmployeeUnderApprover",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getTrainingYear() {
  return axios({
    method: "GET",
    url: base + "GetTrainingYear",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}
