import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import TrainingApplicationCancellationApprovalForm from "../../forms/Training/TrainingApplicationCancellationApprovalForm";
import {
  getCancelledTrainingApplications,
  approveCancelledTrainingApplications,
  rejectCancelledTrainingApplications,
} from "../../../api/trainingApplicationApprovalApi";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function TrainingApplicationCancellationApprovalPage({
  history,
}) {
  const location = useLocation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [trainingApplications, setTrainingApplications] = useState([]);
  const [selectedTrainingApplications, setSelectedTrainingApplications] =
    useState([]);
  const [currentYear, setCurrentYear] = useState(
    location.state ? location.state.year : dayjs().year()
  );
  const [pagination, setPagination] = useState({
    current: 1,
    minIndex: 0,
    maxIndex: 100,
    pageSize: 100,
  });

  useEffect(getTrainingApplications, [currentYear]);

  function getTrainingApplications() {
    setIsLoading(true);
    getCancelledTrainingApplications(currentYear)
      .then((value) => {
        // reset pagination
        resetPagination();

        setTrainingApplications(value ? value : []);
        setSelectedTrainingApplications([]);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function resetPagination() {
    setPagination({
      current: 1,
      minIndex: 0,
      maxIndex: 100,
      pageSize: 100,
    });
  }

  function onAddNewTrainingApplication() {
    history("/home/trainingapplication/trainingtrans");
  }

  function onApproveTrainingApplications(selectedTrainingApplications) {
    setIsLoading(true);

    approveCancelledTrainingApplications(selectedTrainingApplications)
      .then(() => {
        getTrainingApplications();
        toast.success(t("general.record_approved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_approve_record"), error);
      });
  }

  function onRejectTrainingApplications(selectedTrainingApplications, reason) {
    setIsLoading(true);

    rejectCancelledTrainingApplications(selectedTrainingApplications, reason)
      .then(() => {
        getTrainingApplications();
        toast.success(t("general.record_rejected_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_reject_record"), error);
      });
  }

  function viewTrainingApplications(trainingApplicationKey) {
    history(
      "/home/trainingapplication/trainingtrans/" + trainingApplicationKey,
      { state: { fromCancel: true } }
    );
  }

  function viewTrainingProgram(trainingKey) {
    history("/home/trainings/training/" + trainingKey, {
      state: {
        fromCancel: true,
        fromList: true,
        year: currentYear,
      },
    });
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <TrainingApplicationCancellationApprovalForm
      isLoading={isLoading}
      data={trainingApplications}
      selectedItems={selectedTrainingApplications}
      onAdd={onAddNewTrainingApplication}
      onView={viewTrainingApplications}
      onViewProgram={viewTrainingProgram}
      onApproved={onApproveTrainingApplications}
      onRejected={onRejectTrainingApplications}
      onItemSelected={setSelectedTrainingApplications}
      setCurrentYear={setCurrentYear}
      currentYear={currentYear}
      pagination={pagination}
      setPagination={setPagination}
      resetPagination={resetPagination}
    />
  );
}

TrainingApplicationCancellationApprovalPage.propTypes = {
  history: PropTypes.func.isRequired,
};
