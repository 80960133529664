import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Button,
  Row,
  Col,
  Input,
  InputNumber,
  Divider,
  Upload,
  Spin,
  Card,
  Modal,
  Checkbox,
  Switch,
  message,
  Tag,
  Form,
} from "antd";
import dayjs from "dayjs";
import { SelectionInput } from "../../common/SelectionInput";
import { ApprovalReasonModal } from "../../common/ApprovalReasonModal";
import EntryFormFooter from "../../common/EntryFormFooter";
import {
  inputLocaleFormatter,
  inputLocaleParser,
  regionLocale,
  stepPrecision,
  precisionDecimal,
} from "../../common/InputNumberFormatter";
import PropTypes from "prop-types";
import {
  Status,
  StatusByValue,
  isStatusOpen,
  isStatusSubmitted,
  isStatusApproved,
  isStatusPartialApproved,
  isStatusRequestCancel,
  isStatusRejected,
  isStatusVoid,
} from "../../model/common/Status";
import { RecordType } from "../../model/common/RecordType";
import { RowState } from "../../model/common/RowState";
import { toast } from "react-toastify";
import { LimitType } from "../../model/Claim/ClaimEnum";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { MonthPicker } = DatePicker;
const { TextArea } = Input;
const { confirm } = Modal;

const ManageClaimSubmissionForm = ({
  isLoading,
  form,
  editItem,
  loadPageData,
  onSubmitted,
  onCancelled,
  onWithdraw,
  onDelete,
  onRequestCancel,
  onUploadAttachment,
  defaultClaimYearMonth,
  onIsSaveAndNewChange,
  isSaveAndNewData,
  fileList,
  setFileList,
  onClaimTypeChanged,
  getTotalClaimAndLimitUsage,
  getMonthlyExRateAndCurrency,
  allUOMs,
  allClaimTypes,
  allProjects,
  allCurrencies,
  selectedClaimType,
  selectedClaimLimitUsage,
  isOwner,
  isViewOnly = false,
  claimConfg,
  travelConfg,
  userCompCurr,
  selectedCurrency,
  onCurrencyChanged,
  isFromTravel,
  calcFuncTotalAmount,
  onRemoveAttachment,
  setIsDirty,
  onViewTravel,
}) => {
  const { t } = useTranslation();
  const SYMBOLS = {
    INFINITE: t("general.unlimited"),
    ZERO: "-",
  };

  const isEdit = editItem.ClaimTransKey > 0;
  const isSystemRcd = editItem.RcdType === RecordType.SYSTEM;
  const isOpen = isStatusOpen(editItem.Status);
  const isSubmitted = isStatusSubmitted(editItem.Status);
  const isVoid = isStatusVoid(editItem.Status);
  const isApproved =
    isStatusPartialApproved(editItem.Status) ||
    isStatusApproved(editItem.Status);
  const isRequestCancelRejected =
    isApproved &&
    editItem.RejectRequestCancelBy > 0 &&
    editItem.Reason.length > 0;
  const isRejectWithAmendment =
    isOpen &&
    editItem.Reason.length > 0 &&
    (editItem.RejectedBy > 0 || editItem.ValidatedBy > 0);
  const isShowReason =
    isStatusVoid(editItem.Status) ||
    isStatusRejected(editItem.Status) ||
    isStatusRequestCancel(editItem.Status) ||
    isRequestCancelRejected ||
    isRejectWithAmendment;
  const [showReasonForm, setShowReasonForm] = useState(false);
  const [deletedAttachments, setDeletedAttachments] = useState([]);
  const { ClaimTransKey } = useParams();

  useEffect(() => {
    loadPageData(ClaimTransKey, form.resetFields);
    // eslint-disable-next-line
  }, [ClaimTransKey]);

  const objLimitPerTrans =
    selectedClaimLimitUsage && selectedClaimLimitUsage.ClaimTypeLimits
      ? selectedClaimLimitUsage.ClaimTypeLimits.find(
          (a) => a.LimitTypeDesc === LimitType.TRANS.Desc
        )
      : null;
  const objLimitPerMonth =
    selectedClaimLimitUsage && selectedClaimLimitUsage.ClaimTypeLimits
      ? selectedClaimLimitUsage.ClaimTypeLimits.find(
          (a) => a.LimitTypeDesc === LimitType.MTH.Desc
        )
      : null;
  const objLimitPerYear =
    selectedClaimLimitUsage && selectedClaimLimitUsage.ClaimTypeLimits
      ? selectedClaimLimitUsage.ClaimTypeLimits.find(
          (a) => a.LimitTypeDesc === LimitType.YR.Desc
        )
      : null;

  function showDeleteConfirm() {
    confirm({
      title: t("general.delete_confirmation"),
      content: t("claimSubmissionForm.delete_message"),
      okText: t("general.yes"),
      okType: "danger",
      cancelText: t("general.no"),
      onOk: onActionDelete,
      onCancel() {},
    });
  }

  function showWithdrawConfirm() {
    confirm({
      title: t("general.withdraw_confirmation"),
      content: t("claimSubmissionForm.withdraw_message"),
      okText: t("general.yes"),
      cancelText: t("general.no"),
      onOk: onActionWithdraw,
      onCancel() {},
    });
  }

  function showSubmitConfirm() {
    form
      .validateFields()
      .then(() => {
        confirm({
          title: t("general.submit_confirmation"),
          content: t("claimSubmissionForm.submit_message"),
          okText: t("general.yes"),
          cancelText: t("general.no"),
          onOk() {
            handleSubmit(true);
          },
          onCancel() {},
        });
      })
      .catch(() => {});
  }

  function uploadProp() {
    return {
      onRemove: (file) => {
        editItem.Attaches.forEach((item) => {
          if (item.AttchKey === file.uid) {
            item.RowState = RowState.DELETED;
            setDeletedAttachments([...deletedAttachments, file.url]);
          }
        });
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        let tempRcd = fileList.filter((item) => item.name === file.name);
        if (tempRcd.length > 0) {
          message.error(t("general.duplicate_uploaded_file_name"));
        } else {
          file.RowState = 1;
          setFileList([...fileList, file]);
        }

        return false;
      },
      showUploadList: {
        showPreviewIcon: true,
        showRemoveIcon: isOpen && isOwner,
      },
      fileList,
    };
  }

  function onClaimYearMonthChanged(value) {
    if (value) {
      let claimType = form.getFieldValue("ClaimType");
      if (claimType) {
        getTotalClaimAndLimitUsage(claimType.key, value);
      }

      if (claimConfg && claimConfg.IsMultiCurrency) {
        let prevClaimYearMonthValue = form.getFieldValue("ClaimYearMonth");
        let currValue = form.getFieldValue("Currency");

        if (prevClaimYearMonthValue) {
          let prevDate = prevClaimYearMonthValue.toDate();
          let currentDate = value.toDate();
          //if month different only get currency and exchange rate
          if (
            prevDate.getMonth() !== currentDate.getMonth() ||
            prevDate.getYear() !== currentDate.getYear()
          ) {
            //only multi currency able to trigger this function and have promise resolve
            getMonthlyExRateAndCurrency(
              currentDate,
              currValue ? currValue.key : -1
            ).then((currentSelectedCurr) => {
              let totalAmount = form.getFieldValue("TotalAmount");
              if (currentSelectedCurr && currentSelectedCurr.ExRate) {
                form.setFieldsValue({
                  ExRate: currentSelectedCurr.ExRate,
                  FuncTotalAmount: calcFuncTotalAmount(
                    totalAmount,
                    currentSelectedCurr.ExRate
                  ),
                });
              } else {
                form.setFieldsValue({
                  Currency: undefined,
                  ExRate: 1,
                  FuncTotalAmount: totalAmount,
                });
              }
            });
          }
        }
      }
    }
  }

  function onChangeClaimType(value) {
    //set unit cost and recalculate total
    const tempData = allClaimTypes.find(
      (item) => item.ClaimTypeKey === value.value
    );

    let quantity = +form.getFieldValue("Quantity");
    let exRate = form.getFieldValue("ExRate");

    let unitCost =
      tempData && tempData.DefaultCost > 0 ? tempData.DefaultCost : 0;
    form.setFieldsValue({
      UnitCost: unitCost,
    });
    let totalAmount = quantity * unitCost;
    form.setFieldsValue({
      TotalAmount: totalAmount,
      FuncTotalAmount: calcFuncTotalAmount(totalAmount, exRate ? exRate : 1),
    });

    form.setFieldsValue({
      UOM:
        tempData.UOMKey > -1
          ? {
              key: tempData.UOMKey,
              value: tempData.UOMKey,
              label: tempData.UOMCode + " - " + tempData.UOMDesc,
            }
          : undefined,
    });
    form.setFieldsValue({
      IsBillable: tempData.IsBillable,
    });

    //get claim usage
    let claimYrMth = form.getFieldValue("ClaimYearMonth");
    if (claimYrMth) {
      getTotalClaimAndLimitUsage(
        value.value,
        form.getFieldValue("ClaimYearMonth")
      );
    }

    //set selected claim type
    onClaimTypeChanged(tempData);
  }

  function onQuantityChanged(value) {
    const unitCost = +form.getFieldValue("UnitCost");
    const exRate = form.getFieldValue("ExRate");
    form.setFieldsValue({
      TotalAmount: value * unitCost,
      FuncTotalAmount: calcFuncTotalAmount(
        value * unitCost,
        exRate ? exRate : 1
      ),
    });
  }

  function onUnitCostChanged(value) {
    const quantity = +form.getFieldValue("Quantity");
    const exRate = form.getFieldValue("ExRate");
    form.setFieldsValue({
      TotalAmount: quantity * value,
      FuncTotalAmount: calcFuncTotalAmount(
        quantity * value,
        exRate ? exRate : 1
      ),
    });
  }

  function onChangeCurrency(value) {
    form.resetFields(["UnitCost", "TotalAmount", "FuncTotalAmount"]);

    let exRate = 1,
      currentCurr = { ...userCompCurr };
    if (value) {
      let newCurrency = allCurrencies.filter((x) => x.CurrKey === value.value);
      if (newCurrency.length && newCurrency[0]) {
        exRate = newCurrency[0].ExRate;
        currentCurr = newCurrency[0];
      }
    }

    onCurrencyChanged(currentCurr);

    if (selectedClaimType.DefaultCost > 0) {
      //round default cost if is IND region (decimal 0)
      let unitCost =
        precisionDecimal(currentCurr.CurrCode) === 0
          ? Math.round(selectedClaimType.DefaultCost)
          : selectedClaimType.DefaultCost;
      form.setFieldsValue({
        UnitCost: unitCost,
        TotalAmount: +form.getFieldValue("Quantity") * unitCost,
      });
    }

    let funcTotalAmount = calcFuncTotalAmount(
      form.getFieldValue("TotalAmount"),
      exRate
    );

    form.setFieldsValue({
      ExRate: exRate,
      FuncTotalAmount: funcTotalAmount,
    });
  }

  function handleSubmit(isSubmit) {
    form
      .validateFields()
      .then((values) => {
        let currentDateMoment = dayjs();
        if (
          currentDateMoment.date() > claimConfg.CutOffDay &&
          dayjs(values.ClaimYearMonth).month() === currentDateMoment.month()
        ) {
          toast.error(
            t("claimSubmissionForm.claim_for_this_month_is_closed_message")
          );
          return;
        }

        let submitData = {
          ...editItem,
          ...values,
        };
        submitData.ClaimTypeKey = values.ClaimType.key;
        submitData.UOMKey = values.UOM.key;

        if (values.Project) {
          submitData.ProjKey = values.Project.key;
        }

        if (values.Currency) {
          submitData.CurrKey = values.Currency.key;
        } else {
          submitData.CurrKey = userCompCurr.CurrKey;
        }

        submitData.ClaimYearMonth = dayjs(values.ClaimYearMonth).format(
          "YYYY/MM/DD"
        );
        submitData.Status = isSubmit
          ? StatusByValue.SUBMITTED
          : StatusByValue.OPEN;

        uploadAttachments()
          .then((result) => {
            submitData.Attaches = [...submitData.Attaches.slice(), ...result];
            onSubmitted(submitData);
          })
          .catch(() => {
            toast.error(t("general.fail_to_upload_file"));
          });
      })
      .catch(() => {});
  }

  function onActionWithdraw() {
    onWithdraw(editItem);
  }

  function onActionDelete() {
    onDelete(editItem);
  }

  function submitCancelReason(reason) {
    setShowReasonForm(false);
    onRequestCancel(editItem, reason);
  }

  function uploadAttachments() {
    const formData = new FormData();

    let newFiles = fileList.filter((x) => x.RowState === 1);
    newFiles.forEach((file) => {
      formData.append("files[]", file);
    });

    if (deletedAttachments.length) removeAttachments(deletedAttachments);
    if (!newFiles.length) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }
    return onUploadAttachment(formData);
  }

  function removeAttachments(file) {
    return onRemoveAttachment(file).then(() => {
      setDeletedAttachments([]);
    });
  }

  function disabledDate(value) {
    const selectedValue = value.format("YYYY-MM-DD");
    const startDateOfMonth = defaultClaimYearMonth
      .startOf("month")
      .format("YYYY-MM-DD");
    return selectedValue < startDateOfMonth;
  }

  function onChangeIsSaveAndNew(e) {
    onIsSaveAndNewChange(e.target.checked);
  }

  return (
    <div>
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 16, offset: 4 }}
        >
          <Card
            title={
              <div>
                <span className="form-title">
                  {t("claimSubmissionForm.claim_submission")}
                </span>
                <Tag
                  onClick={() => onViewTravel(editItem.TravelTransHdrKey)}
                  color="blue"
                  style={{
                    marginLeft: 10,
                    verticalAlign: "text-top",
                    display: editItem.IsLink ? "" : "none",
                    cursor: "pointer",
                  }}
                >
                  {t("general.travel")}
                </Tag>
                <Tag
                  color="green"
                  style={{
                    marginLeft: 10,
                    verticalAlign: "text-top",
                    display:
                      editItem.IsRequireValidation && editItem.IsValidated
                        ? ""
                        : "none",
                  }}
                >
                  {t("general.validated")}
                </Tag>
              </div>
            }
          >
            <Spin spinning={isLoading}>
              <Form
                form={form}
                layout="vertical"
                onValuesChange={() => setIsDirty(true)}
              >
                <Row gutter={12}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={
                        <span>{t("claimSubmissionForm.claim_month_year")}</span>
                      }
                      name="ClaimYearMonth"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "claimSubmissionForm.claim_month_year_required_validation"
                          ),
                        },
                      ]}
                      initialValue={
                        isEdit && editItem.ClaimYearMonth
                          ? dayjs(new Date(editItem.ClaimYearMonth))
                          : defaultClaimYearMonth
                      }
                    >
                      <MonthPicker
                        allowClear={false}
                        disabledDate={disabledDate}
                        placeholder={t("claimSubmissionForm.claim_month_year")}
                        format="MM/YYYY"
                        disabled={!isOpen || !isOwner || isSystemRcd}
                        onChange={onClaimYearMonthChanged}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={
                        <span>
                          {t("claimSubmissionForm.item_transaction_date")}
                        </span>
                      }
                      name="TransDate"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "claimSubmissionForm.transaction_date_required_validation"
                          ),
                        },
                      ]}
                      initialValue={
                        (isEdit || isFromTravel) && editItem.TransDate
                          ? dayjs(new Date(editItem.TransDate))
                          : undefined
                      }
                    >
                      <DatePicker
                        allowClear={false}
                        placeholder={t("claimSubmissionForm.transaction_date")}
                        format="DD/MM/YYYY"
                        disabled={!isOpen || !isOwner || isSystemRcd}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={<span>{t("general.status")}</span>}
                      name="StatusDesc"
                      initialValue={Status[editItem.Status]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>

                  <Col
                    span={24}
                    style={{
                      display: isShowReason ? "" : "none",
                    }}
                  >
                    <Form.Item
                      label={
                        isRequestCancelRejected
                          ? t("general.reason_of_cancel_request_rejected")
                          : t("general.reason")
                      }
                    >
                      <TextArea
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        disabled={true}
                        value={editItem.Reason}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xs={24}
                    sm={24}
                    md={16}
                    lg={16}
                    xl={16}
                    style={{
                      display:
                        claimConfg && claimConfg.IsGroupByProj === true
                          ? ""
                          : "none",
                    }}
                  >
                    <Form.Item
                      label={<span>{t("claimSubmissionForm.project")}</span>}
                      name="Project"
                      rules={[
                        {
                          required:
                            claimConfg && claimConfg.IsGroupByProj === true,
                          message: t(
                            "claimSubmissionForm.project_required_validation"
                          ),
                        },
                        {
                          validator: (_, value) => {
                            if (
                              value &&
                              claimConfg &&
                              claimConfg.IsGroupByProj === true &&
                              !allProjects.some((x) => x.Key === value.value)
                            ) {
                              return Promise.reject(
                                new Error(
                                  t("claimSubmissionForm.invalid_project")
                                )
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                      initialValue={
                        editItem.ProjKey > 0
                          ? {
                              key: editItem.ProjKey,
                              value: editItem.ProjKey,
                              label:
                                editItem.ProjCode + " - " + editItem.ProjDesc,
                            }
                          : undefined
                      }
                    >
                      <SelectionInput
                        ref={React.createRef()}
                        labelInValue={true}
                        data={allProjects}
                        valueProp={"Key"}
                        textProp={"CodeDesc"}
                        disabledProp={"Active"}
                        placeholder={t(
                          "claimSubmissionForm.project_placeholder"
                        )}
                        disabled={!isOpen || !isOwner || isSystemRcd}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{
                      display:
                        claimConfg && claimConfg.IsGroupByProj === true
                          ? ""
                          : "none",
                    }}
                  >
                    <Form.Item
                      label={<span>{t("claimTypesForm.billable")}</span>}
                      name="IsBillable"
                      valuePropName="checked"
                      initialValue={
                        editItem && editItem.IsBillable
                          ? editItem.IsBillable
                          : undefined
                      }
                    >
                      <Switch
                        checkedChildren={t("general.yes")}
                        unCheckedChildren={t("general.no")}
                        disabled={!isOpen || !isOwner || isSystemRcd}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <Form.Item
                      label={<span>{t("general.claim_type")}</span>}
                      name="ClaimType"
                      rules={[
                        {
                          required: true,
                          message: t("general.claim_type_required_validation"),
                        },
                        {
                          validator: (_, value) => {
                            if (
                              value &&
                              !allClaimTypes.some(
                                (x) => x.ClaimTypeKey === value.value
                              )
                            ) {
                              return Promise.reject(
                                new Error(t("general.invalid_claim_type"))
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                      initialValue={
                        editItem.ClaimTypeKey > 0
                          ? {
                              key: editItem.ClaimTypeKey,
                              value: editItem.ClaimTypeKey,
                              label:
                                editItem.ClaimTypeCode +
                                " - " +
                                editItem.ClaimTypeDesc,
                            }
                          : undefined
                      }
                    >
                      <SelectionInput
                        ref={React.createRef()}
                        labelInValue={true}
                        data={allClaimTypes}
                        valueProp={"ClaimTypeKey"}
                        textProp={"ClaimTypeCodeDesc"}
                        disabledProp={"Active"}
                        placeholder={t("general.claim_type_placeholder")}
                        onChange={onChangeClaimType}
                        disabled={
                          !isOpen || !isOwner || isSystemRcd || isFromTravel
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={
                        <span>{t("claimTypesForm.unit_of_measurement")}</span>
                      }
                      name="UOM"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "claimTypesForm.unit_of_measurement_required_validation"
                          ),
                        },
                        {
                          validator: (_, value) => {
                            if (
                              value &&
                              !allUOMs.some((x) => x.Key === value.value)
                            ) {
                              return Promise.reject(
                                new Error(
                                  t(
                                    "claimTypesForm.unit_of_measurement_invalid_validation"
                                  )
                                )
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                      initialValue={
                        editItem.UOMKey > 0
                          ? {
                              key: editItem.UOMKey,
                              value: editItem.UOMKey,
                              label:
                                editItem.UOMCode + " - " + editItem.UOMDesc,
                            }
                          : undefined
                      }
                    >
                      <SelectionInput
                        ref={React.createRef()}
                        labelInValue={true}
                        data={allUOMs}
                        valueProp={"Key"}
                        textProp={"CodeDesc"}
                        disabledProp={"Active"}
                        placeholder={t(
                          "claimTypesForm.unit_of_measurement_placeholder"
                        )}
                        disabled={!isOpen || !isOwner || isSystemRcd}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{
                      display: selectedClaimLimitUsage ? "" : "none",
                    }}
                  >
                    <b>{t("claimTypesForm.limit_per_transaction")}</b>
                    <br />
                    <p>
                      {objLimitPerTrans && objLimitPerTrans.MaxTotalAmt
                        ? objLimitPerTrans.MaxTotalAmt.toLocaleString(
                            regionLocale(),
                            {
                              minimumFractionDigits: precisionDecimal(
                                userCompCurr.CurrCode
                              ),
                              maximumFractionDigits: 2,
                            }
                          )
                        : SYMBOLS.INFINITE}
                    </p>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{
                      display: selectedClaimLimitUsage ? "" : "none",
                    }}
                  >
                    <b>{t("claimTypesForm.limit_per_month")}</b>
                    <br />
                    <p>
                      {selectedClaimLimitUsage &&
                      selectedClaimLimitUsage.ClaimUsage &&
                      selectedClaimLimitUsage.ClaimUsage.TotalAmtByMonth
                        ? selectedClaimLimitUsage.ClaimUsage.TotalAmtByMonth.toLocaleString(
                            regionLocale(),
                            {
                              minimumFractionDigits: precisionDecimal(
                                userCompCurr.CurrCode
                              ),
                              maximumFractionDigits: 2,
                            }
                          )
                        : SYMBOLS.ZERO}
                      {" / "}
                      {objLimitPerMonth && objLimitPerMonth.MaxTotalAmt
                        ? objLimitPerMonth.MaxTotalAmt.toLocaleString(
                            regionLocale(),
                            {
                              minimumFractionDigits: precisionDecimal(
                                userCompCurr.CurrCode
                              ),
                              maximumFractionDigits: 2,
                            }
                          )
                        : SYMBOLS.INFINITE}
                    </p>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{
                      display: selectedClaimLimitUsage ? "" : "none",
                    }}
                  >
                    <b>{t("claimTypesForm.limit_per_year")}</b>
                    <br />
                    <p>
                      {selectedClaimLimitUsage &&
                      selectedClaimLimitUsage.ClaimUsage &&
                      selectedClaimLimitUsage.ClaimUsage.TotalAmtByYear
                        ? selectedClaimLimitUsage.ClaimUsage.TotalAmtByYear.toLocaleString(
                            regionLocale(),
                            {
                              minimumFractionDigits: precisionDecimal(
                                userCompCurr.CurrCode
                              ),
                              maximumFractionDigits: 2,
                            }
                          )
                        : SYMBOLS.ZERO}
                      {" / "}
                      {objLimitPerYear && objLimitPerYear.MaxTotalAmt
                        ? objLimitPerYear.MaxTotalAmt.toLocaleString(
                            regionLocale(),
                            {
                              minimumFractionDigits: precisionDecimal(
                                userCompCurr.CurrCode
                              ),
                              maximumFractionDigits: 2,
                            }
                          )
                        : SYMBOLS.INFINITE}
                    </p>
                  </Col>

                  <Col
                    span={24}
                    style={{
                      display:
                        claimConfg && claimConfg.IsMultiCurrency === true
                          ? ""
                          : "none",
                    }}
                  >
                    <Row gutter={12}>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item
                          label={<span>{t("general.currency")}</span>}
                          name="Currency"
                          rules={[
                            {
                              required:
                                claimConfg &&
                                claimConfg.IsMultiCurrency === true,
                              message: t(
                                "general.currency_required_validation"
                              ),
                            },
                            {
                              validator: (_, value) => {
                                if (
                                  value &&
                                  claimConfg &&
                                  claimConfg.IsMultiCurrency === true &&
                                  !allCurrencies.some(
                                    (x) => x.CurrKey === value.value
                                  )
                                ) {
                                  return Promise.reject(
                                    new Error(t("general.invalid_currency"))
                                  );
                                }

                                return Promise.resolve();
                              },
                            },
                          ]}
                          initialValue={
                            editItem.CurrKey > 0
                              ? {
                                  key: editItem.CurrKey,
                                  value: editItem.CurrKey,
                                  label:
                                    editItem.CurrCode +
                                    " - " +
                                    editItem.CurrDesc,
                                }
                              : undefined
                          }
                        >
                          <SelectionInput
                            ref={React.createRef()}
                            labelInValue={true}
                            data={allCurrencies}
                            valueProp={"CurrKey"}
                            textProp={"CurrCodeDesc"}
                            disabledProp={"Active"}
                            placeholder={t("general.currency_placeholder")}
                            onChange={onChangeCurrency}
                            disabled={
                              !isOpen || !isOwner || isSystemRcd || isFromTravel
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={<span>{t("general.quantity")}</span>}
                      name="Quantity"
                      rules={[
                        {
                          required: true,
                          message: t("general.quantity_required_validation"),
                        },
                        {
                          validator: (_, value) => {
                            if (value <= 0)
                              return Promise.reject(
                                new Error(
                                  t("claimSubmissionForm.quantity_must_be_>_0")
                                )
                              );

                            return Promise.resolve();
                          },
                        },
                      ]}
                      initialValue={editItem.Quantity}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        step="0.1"
                        min={0}
                        precision={2}
                        formatter={inputLocaleFormatter(2)}
                        parser={inputLocaleParser(2)}
                        onChange={onQuantityChanged}
                        disabled={!isOpen || !isOwner || isSystemRcd}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={
                        <span>
                          {t("general.unit_cost")}
                          {selectedCurrency && selectedCurrency.CurrCode
                            ? " (" + selectedCurrency.CurrCode + ")"
                            : ""}
                        </span>
                      }
                      name="UnitCost"
                      rules={[
                        {
                          required:
                            selectedClaimType &&
                            selectedClaimType.DefaultCost === 0,
                          message: t("general.unit_cost_required_validation"),
                        },
                        {
                          validator: (_, value) => {
                            if (value <= 0)
                              return Promise.reject(
                                new Error(
                                  t("claimSubmissionForm.unit_cost_must_be_>_0")
                                )
                              );
                            return Promise.resolve();
                          },
                        },
                      ]}
                      initialValue={
                        isFromTravel &&
                        selectedClaimType &&
                        selectedClaimType.DefaultCost > 0
                          ? selectedClaimType.DefaultCost
                          : editItem.UnitCost
                      }
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        step={stepPrecision(selectedCurrency.CurrCode)}
                        min={0}
                        precision={precisionDecimal(selectedCurrency.CurrCode)}
                        prefix={
                          selectedCurrency && selectedCurrency.CurrSymb
                            ? selectedCurrency.CurrSymb
                            : ""
                        }
                        formatter={inputLocaleFormatter(
                          precisionDecimal(selectedCurrency.CurrCode)
                        )}
                        parser={inputLocaleParser(
                          precisionDecimal(selectedCurrency.CurrCode)
                        )}
                        onChange={onUnitCostChanged}
                        disabled={
                          !isOpen ||
                          !isOwner ||
                          isSystemRcd ||
                          (selectedClaimType &&
                            selectedClaimType.DefaultCost > 0)
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={
                        <span>
                          {t("general.total_amount")}
                          {selectedCurrency && selectedCurrency.CurrCode
                            ? " (" + selectedCurrency.CurrCode + ")"
                            : ""}
                        </span>
                      }
                      name="TotalAmount"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (!isFromTravel) {
                              return Promise.resolve();
                            }
                            if (
                              value <= editItem.AuthorizedTotalAmount ||
                              travelConfg.IsAllowOverClaim
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              selectedCurrency && selectedCurrency.CurrCode
                                ? t(
                                    "claimSubmissionForm.total_amount_cannot_more_than_authorized_amount"
                                  ) +
                                    " (" +
                                    selectedCurrency.CurrCode +
                                    " " +
                                    editItem.AuthorizedTotalAmount +
                                    ")"
                                : t(
                                    "claimSubmissionForm.total_amount_cannot_more_than_authorized_amount"
                                  ) +
                                    " (" +
                                    editItem.AuthorizedTotalAmount +
                                    ")"
                            );
                          },
                        },
                      ]}
                      initialValue={editItem.TotalAmount}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        precision={precisionDecimal(selectedCurrency.CurrCode)}
                        prefix={
                          selectedCurrency && selectedCurrency.CurrSymb
                            ? selectedCurrency.CurrSymb
                            : ""
                        }
                        formatter={inputLocaleFormatter(
                          precisionDecimal(selectedCurrency.CurrCode)
                        )}
                        parser={inputLocaleParser(
                          precisionDecimal(selectedCurrency.CurrCode)
                        )}
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xs={24}
                    sm={24}
                    md={{ span: 8, offset: 8 }}
                    lg={{ span: 8, offset: 8 }}
                    xl={{ span: 8, offset: 8 }}
                    style={{
                      display:
                        claimConfg &&
                        claimConfg.IsMultiCurrency === true &&
                        form.getFieldValue("Currency") &&
                        form.getFieldValue("Currency").key !==
                          userCompCurr.CurrKey
                          ? ""
                          : "none",
                    }}
                  >
                    <Form.Item
                      label={<span>{t("general.exchange_rate")}</span>}
                      name="ExRate"
                      initialValue={editItem.ExRate}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        precision={6}
                        disabled={true}
                        formatter={inputLocaleFormatter(6)}
                        parser={inputLocaleParser(6)}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{
                      display:
                        claimConfg &&
                        claimConfg.IsMultiCurrency === true &&
                        form.getFieldValue("Currency") &&
                        userCompCurr &&
                        form.getFieldValue("Currency").key !==
                          userCompCurr.CurrKey
                          ? ""
                          : "none",
                    }}
                  >
                    <Form.Item
                      label={
                        <span>
                          {t("general.total_amount")}
                          {userCompCurr && userCompCurr.CurrCode
                            ? " (" + userCompCurr.CurrCode + ")"
                            : ""}
                        </span>
                      }
                      name="FuncTotalAmount"
                      initialValue={editItem.FuncTotalAmount}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        precision={precisionDecimal(userCompCurr.CurrCode)}
                        prefix={
                          userCompCurr.CurrSymb ? userCompCurr.CurrSymb : ""
                        }
                        formatter={inputLocaleFormatter(
                          precisionDecimal(userCompCurr.CurrCode)
                        )}
                        parser={inputLocaleParser(
                          precisionDecimal(userCompCurr.CurrCode)
                        )}
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={<span>{t("general.remarks")}</span>}
                      name="Remarks"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: t("general.remarks_required_validation"),
                        },
                        {
                          max: 250,
                          message: t("general.remarks_max_length_validation"),
                        },
                      ]}
                      initialValue={editItem.Remarks}
                    >
                      <TextArea
                        placeholder={t("general.remarks_placeholder")}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        disabled={
                          !isOpen || !isOwner || isViewOnly || isSystemRcd
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Divider />
                  <Col span={24}>
                    <Form.Item
                      label={<span>{t("general.attachment")}</span>}
                      name="Attachment"
                      rules={[{ required: false }]}
                    >
                      <Upload {...uploadProp()} listType="picture">
                        <Button
                          disabled={
                            !(isOpen && isOwner) || isViewOnly || isSystemRcd
                          }
                        >
                          <UploadOutlined /> {t("general.browse")}
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="save-form-section">
                  <Col style={{ textAlign: "right", padding: "10px" }}>
                    <Row>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                        <Checkbox
                          checked={isSaveAndNewData}
                          onChange={onChangeIsSaveAndNew}
                          style={{
                            float: "left",
                            marginTop: "10px",
                            marginBottom: "10px",
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.save_and_new")}
                        </Checkbox>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 16 }}>
                        <Button
                          size="large"
                          type="link"
                          onClick={onCancelled}
                          style={{
                            marginRight: 10,
                          }}
                        >
                          {t("general.back")}
                        </Button>
                        <Button
                          size="large"
                          onClick={() => handleSubmit(false)}
                          style={{
                            marginRight: 10,
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.save")}
                        </Button>
                        <Button
                          size="large"
                          type="link"
                          onClick={showDeleteConfirm}
                          style={{
                            display:
                              isEdit &&
                              isOpen &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.delete")}
                        </Button>
                        <Button
                          size="large"
                          type="primary"
                          onClick={showSubmitConfirm}
                          style={{
                            marginRight: 10,
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.submit")}
                        </Button>
                        <Button
                          type="primary"
                          size="large"
                          onClick={showWithdrawConfirm}
                          style={{
                            marginRight: 10,
                            display:
                              isSubmitted &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.withdraw")}
                        </Button>
                        <Button
                          type="primary"
                          size="large"
                          onClick={() => setShowReasonForm(true)}
                          style={{
                            display:
                              isApproved &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.request_cancel")}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Card>
          <EntryFormFooter
            footerData={editItem}
            isApproved={isApproved}
            isVoid={isVoid}
            isPending={
              editItem.Status === 4 || editItem.Status === 1 ? true : false
            }
            showFooter={editItem.ClaimTransKey !== -1 ? true : false}
            t={t}
          ></EntryFormFooter>
        </Col>
      </Row>

      <ApprovalReasonModal
        isVisible={showReasonForm}
        reasonType={"Cancel Claim"}
        onSaved={submitCancelReason}
        onCancelled={() => setShowReasonForm(false)}
      />
    </div>
  );
};

ManageClaimSubmissionForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  editItem: PropTypes.object.isRequired,
  loadPageData: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onWithdraw: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRequestCancel: PropTypes.func.isRequired,
  onUploadAttachment: PropTypes.func.isRequired,
  defaultClaimYearMonth: PropTypes.instanceOf(dayjs).isRequired,
  form: PropTypes.object.isRequired,
  onIsSaveAndNewChange: PropTypes.func.isRequired,
  isSaveAndNewData: PropTypes.bool.isRequired,
  fileList: PropTypes.array.isRequired,
  setFileList: PropTypes.func.isRequired,
  onClaimTypeChanged: PropTypes.func.isRequired,
  getTotalClaimAndLimitUsage: PropTypes.func.isRequired,
  getMonthlyExRateAndCurrency: PropTypes.func.isRequired,
  allUOMs: PropTypes.array.isRequired,
  allClaimTypes: PropTypes.array.isRequired,
  allProjects: PropTypes.array.isRequired,
  allCurrencies: PropTypes.array.isRequired,
  selectedClaimType: PropTypes.object.isRequired,
  selectedClaimLimitUsage: PropTypes.object.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isViewOnly: PropTypes.bool,
  claimConfg: PropTypes.object.isRequired,
  travelConfg: PropTypes.object.isRequired,
  userCompCurr: PropTypes.object.isRequired,
  selectedCurrency: PropTypes.object.isRequired,
  onCurrencyChanged: PropTypes.func.isRequired,
  isFromTravel: PropTypes.bool.isRequired,
  calcFuncTotalAmount: PropTypes.func.isRequired,
  onRemoveAttachment: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
  onViewTravel: PropTypes.func.isRequired,
};

export default ManageClaimSubmissionForm;
