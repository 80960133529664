import React, { useEffect, useState } from "react";
import RoomRequisitionForm from "../../forms/RoomReq/RoomRequisitionForm";
import PropTypes from "prop-types";
import { getRoomRequisitions } from "../../../api/roomRequisitionApi";
import { toast } from "react-toastify";
import Axios from "axios";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const RoomRequisitionPage = ({ history }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [roomRequisitionDisplay, setRoomRequisitionDisplay] = useState([]);
  const [currentYear, setCurrentYear] = useState(dayjs().year());
  const [pagination, setPagination] = useState({
    current: 1,
    minIndex: 0,
    maxIndex: 100,
    pageSize: 100,
  });

  useEffect(() => {
    setIsLoading(true);
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    getRoomRequisitions(source, currentYear)
      .then((result) => {
        // reset pagination
        resetPagination();

        setRoomRequisitionDisplay(result);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
    return () => {
      source.cancel();
    };
  }, [currentYear, t]);

  function resetPagination() {
    setPagination({
      current: 1,
      minIndex: 0,
      maxIndex: 100,
      pageSize: 100,
    });
  }

  function onAddNewRoomRequisition() {
    history("/home/meetingroomrequisition/roomtrans");
  }

  function viewRoomRequisition(RoomRequisitionKey) {
    history("/home/meetingroomrequisition/roomtrans/" + RoomRequisitionKey);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <RoomRequisitionForm
      isLoading={isLoading}
      data={roomRequisitionDisplay}
      onAdd={onAddNewRoomRequisition}
      onView={viewRoomRequisition}
      setCurrentYear={setCurrentYear}
      pagination={pagination}
      setPagination={setPagination}
      resetPagination={resetPagination}
    />
  );
};

RoomRequisitionPage.propTypes = {
  history: PropTypes.func.isRequired,
};

export default RoomRequisitionPage;
