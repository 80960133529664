import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Row,
  Col,
  Input,
  Divider,
  Upload,
  Spin,
  Card,
  Modal,
  Checkbox,
  message,
  Tag,
  DatePicker,
  Form,
} from "antd";
import { ApprovalReasonModal } from "../../common/ApprovalReasonModal";
import EntryFormFooter from "../../common/EntryFormFooter";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
  Status,
  StatusByValue,
  isStatusOpen,
  isStatusSubmitted,
  isStatusApproved,
  isStatusPartialApproved,
  isStatusRequestCancel,
  isStatusRejected,
  isStatusVoid,
  isStatusClosed,
} from "../../model/common/Status";
import { RowState } from "../../model/common/RowState";
import { RecordType } from "../../model/common/RecordType";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { confirm } = Modal;

const ManagePropMaintForm = ({
  isLoading,
  form,
  editItem,
  loadPageData,
  onSubmitted,
  onCancelled,
  onWithdraw,
  onDelete,
  onRequestCancel,
  onFeedbackPropMaint,
  onCompletePropMaint,
  onUploadAttachment,
  onRemoveAttachment,
  onIsSaveAndNewChange,
  isSaveAndNewData,
  fileList,
  setFileList,
  isOwner,
  isViewOnly = false,
  isAssignor,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const isEdit = editItem.PropMaintKey > 0;
  const isSystemRcd = editItem.RcdType === RecordType.SYSTEM;
  const isOpen = isStatusOpen(editItem.Status);
  const isSubmitted = isStatusSubmitted(editItem.Status);
  const isVoid = isStatusVoid(editItem.Status);
  const isApproved =
    isStatusPartialApproved(editItem.Status) ||
    isStatusApproved(editItem.Status);
  const isRequestCancelRejected =
    isApproved &&
    editItem.RejectRequestCancelBy > 0 &&
    editItem.Reason.length > 0;
  const isRejectWithAmendment =
    isOpen && editItem.Reason.length > 0 && editItem.RejectedBy > 0;
  const isShowReason =
    isStatusVoid(editItem.Status) ||
    isStatusRejected(editItem.Status) ||
    isStatusRequestCancel(editItem.Status) ||
    isRequestCancelRejected ||
    isRejectWithAmendment ||
    (editItem.IsValidated && !editItem.IsRequireMaint);
  const [showReasonForm, setShowReasonForm] = useState(false);
  const [deletedAttachments, setDeletedAttachments] = useState([]);
  const { PropMaintKey } = useParams();

  useEffect(() => {
    loadPageData(PropMaintKey, form.resetFields);
    // eslint-disable-next-line
  }, [PropMaintKey]);

  function showDeleteConfirm() {
    confirm({
      title: t("general.delete_confirmation"),
      content: t("propMaintForm.delete_message"),
      okText: t("general.yes"),
      okType: "danger",
      cancelText: t("general.no"),
      onOk: onActionDelete,
      onCancel() {},
    });
  }

  function showWithdrawConfirm() {
    confirm({
      title: t("general.withdraw_confirmation"),
      content: t("propMaintForm.withdraw_message"),
      okText: t("general.yes"),
      cancelText: t("general.no"),
      onOk: onActionWithdraw,
      onCancel() {},
    });
  }

  function showSubmitConfirm() {
    form
      .validateFields()
      .then(() => {
        confirm({
          title: t("general.submit_confirmation"),
          content: t("propMaintForm.submit_message"),
          okText: t("general.yes"),
          cancelText: t("general.no"),
          onOk() {
            handleSubmit(true);
          },
          onCancel() {},
        });
      })
      .catch(() => {});
  }

  function handleSubmit(isSubmit) {
    form
      .validateFields()
      .then((values) => {
        let submitData = {
          ...editItem,
          ...values,
        };

        submitData.Status = isSubmit
          ? StatusByValue.SUBMITTED
          : StatusByValue.OPEN;

        uploadAttachments()
          .then((result) => {
            submitData.Attaches = [...submitData.Attaches.slice(), ...result];
            onSubmitted(submitData);
          })
          .catch(() => {
            toast.error(t("general.fail_to_upload_file"));
          });
      })
      .catch(() => {});
  }

  function onActionWithdraw() {
    onWithdraw(editItem);
  }

  function onActionDelete() {
    onDelete(editItem);
  }

  function submitCancelReason(reason) {
    setShowReasonForm(false);
    onRequestCancel(editItem, reason);
  }

  function onChangeIsSaveAndNew(e) {
    onIsSaveAndNewChange(e.target.checked);
  }

  function feedbackPropMaint() {
    form
      .validateFields()
      .then((values) => {
        let submitData = {
          ...editItem,
          ...values,
        };

        let fromDate = values.RepairDate[0];
        fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        submitData.RepairStartDate = fromDate.startOf("day").$d;

        let toDate = values.RepairDate[1];
        toDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        submitData.RepairEndDate = toDate.startOf("day").$d;

        uploadAttachments()
          .then((result) => {
            submitData.Attaches = [...submitData.Attaches.slice(), ...result];
            onFeedbackPropMaint(submitData);
          })
          .catch(() => {
            toast.error(t("general.fail_to_upload_file"));
          });
      })
      .catch(() => {});
  }

  function completePropMaint() {
    form
      .validateFields()
      .then((values) => {
        confirm({
          title: t("propMaintForm.properties_maintenance_closure"),
          content: t("propMaintForm.closure_message"),
          okText: t("general.yes"),
          cancelText: t("general.no"),
          onOk() {
            let submitData = {
              ...editItem,
              ...values,
            };

            let fromDate = values.RepairDate[0];
            fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            submitData.RepairStartDate = fromDate.startOf("day").$d;

            let toDate = values.RepairDate[1];
            toDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            submitData.RepairEndDate = toDate.startOf("day").$d;

            uploadAttachments()
              .then((result) => {
                submitData.Attaches = [
                  ...submitData.Attaches.slice(),
                  ...result,
                ];
                onCompletePropMaint(submitData);
              })
              .catch(() => {
                toast.error(t("general.fail_to_upload_file"));
              });
          },
          onCancel() {},
        });
      })
      .catch(() => {});
  }

  function uploadProp() {
    return {
      onRemove: (file) => {
        editItem.Attaches.forEach((item) => {
          if (item.AttchKey === file.uid) {
            item.RowState = RowState.DELETED;
            setDeletedAttachments([...deletedAttachments, file.url]);
          }
        });
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        let tempRcd = fileList.filter((item) => item.name === file.name);
        if (tempRcd.length > 0) {
          message.error(t("general.duplicate_uploaded_file_name"));
        } else {
          file.RowState = 1;
          setFileList([...fileList, file]);
        }

        return false;
      },
      showUploadList: {
        showPreviewIcon: true,
        showRemoveIcon:
          (isOpen && isOwner) ||
          (!isOpen &&
            ((!editItem.IsRequireApproval && isSubmitted) ||
              isStatusApproved(editItem.Status)) &&
            editItem.IsValidated &&
            editItem.IsRequireMaint &&
            isAssignor),
      },
      fileList,
    };
  }

  function uploadAttachments() {
    const formData = new FormData();

    let newFiles = fileList.filter((x) => x.RowState === 1);
    newFiles.forEach((file) => {
      formData.append("files[]", file);
    });

    if (deletedAttachments.length) removeAttachments(deletedAttachments);
    if (!newFiles.length) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }
    return onUploadAttachment(formData);
  }

  function removeAttachments(file) {
    return onRemoveAttachment(file).then(() => {
      setDeletedAttachments([]);
    });
  }

  return (
    <div>
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 16, offset: 4 }}
        >
          <Card
            title={
              <div>
                <span className="form-title">
                  {t("propMaintForm.properties_maintenance")}
                </span>
                <Tag
                  color={editItem.IsRequireMaint ? "magenta" : "green"}
                  style={{
                    marginLeft: 10,
                    verticalAlign: "text-top",
                    display: editItem.IsValidated ? "" : "none",
                  }}
                >
                  {editItem.IsRequireMaint
                    ? t("propMaintForm.repair_required")
                    : t("propMaintForm.no_repair_required")}
                </Tag>
              </div>
            }
          >
            <Spin spinning={isLoading}>
              <Form
                form={form}
                layout="vertical"
                onValuesChange={() => setIsDirty(true)}
              >
                <Row gutter={12}>
                  <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <Form.Item
                      label={<span>{t("general.title")}</span>}
                      name="Title"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: t("general.title_required_validation"),
                        },
                        {
                          max: 250,
                          message: t("general.title_max_length_validation"),
                        },
                      ]}
                      initialValue={editItem.Title}
                    >
                      <Input
                        placeholder={t("general.title_placeholder")}
                        disabled={!isOpen || !isOwner || isSystemRcd}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={<span>{t("general.status")}</span>}
                      name="StatusDesc"
                      initialValue={Status[editItem.Status]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                </Row>

                <Col
                  span={24}
                  style={{
                    display: isShowReason ? "" : "none",
                  }}
                >
                  <Form.Item
                    label={
                      editItem.IsValidated && !editItem.IsRequireMaint
                        ? t("propMaintForm.reason_of_no_repair_required")
                        : isRequestCancelRejected
                        ? t("general.reason_of_cancel_request_rejected")
                        : t("general.reason")
                    }
                  >
                    <TextArea
                      autoSize={{ minRows: 2, maxRows: 6 }}
                      disabled={true}
                      value={editItem.Reason}
                    />
                  </Form.Item>
                </Col>

                <Row gutter={12}>
                  <Col span={24}>
                    <Form.Item
                      label={<span>{t("general.description")}</span>}
                      name="Description"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: t("general.description_required_validation"),
                        },
                      ]}
                      initialValue={editItem.Description}
                    >
                      <TextArea
                        placeholder={t("general.description_placeholder")}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        disabled={
                          !isOpen || !isOwner || isViewOnly || isSystemRcd
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{
                      display:
                        editItem.IsValidated && editItem.IsRequireMaint
                          ? ""
                          : "none",
                    }}
                  >
                    <Form.Item
                      label={<span>{t("propMaintForm.repair_date")}</span>}
                      name="RepairDate"
                      rules={[
                        {
                          required:
                            editItem.IsValidated && editItem.IsRequireMaint,
                          message: t(
                            "propMaintForm.repair_date_required_validation"
                          ),
                        },
                      ]}
                      initialValue={editItem.RepairDate}
                    >
                      <RangePicker
                        style={{ width: "100%" }}
                        allowClear={true}
                        showToday={false}
                        format="DD/MM/YYYY"
                        disabled={
                          isStatusClosed(editItem.Status) ||
                          !isAssignor ||
                          isSystemRcd ||
                          isViewOnly
                        }
                        placeholder={[
                          t("general.start_date"),
                          t("general.end_date"),
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    span={24}
                    style={{
                      display:
                        editItem.IsValidated && editItem.IsRequireMaint
                          ? ""
                          : "none",
                    }}
                  >
                    <Form.Item
                      label={
                        <span>{t("propMaintForm.maintenance_remarks")}</span>
                      }
                      name="MaintRemarks"
                      initialValue={editItem.MaintRemarks}
                    >
                      <TextArea
                        placeholder={t(
                          "propMaintForm.maintenance_remarks_placeholder"
                        )}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        disabled={
                          (!(!editItem.IsRequireApproval && isSubmitted) &&
                            !isStatusApproved(editItem.Status)) ||
                          !isAssignor ||
                          isViewOnly ||
                          isSystemRcd
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Divider />
                  <Col span={24}>
                    <Form.Item
                      label={<span>{t("general.attachment")}</span>}
                      name="Attachment"
                      rules={[{ required: false }]}
                    >
                      <Upload {...uploadProp()} listType="picture">
                        <Button
                          disabled={
                            !(
                              (isOpen && isOwner && !isViewOnly) ||
                              (!isOpen &&
                                ((!editItem.IsRequireApproval && isSubmitted) ||
                                  isStatusApproved(editItem.Status)) &&
                                editItem.IsValidated &&
                                editItem.IsRequireMaint &&
                                isAssignor)
                            )
                          }
                        >
                          <UploadOutlined /> {t("general.browse")}
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="save-form-section">
                  <Col style={{ textAlign: "right", padding: "10px" }}>
                    <Row>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                        <Checkbox
                          checked={isSaveAndNewData}
                          onChange={onChangeIsSaveAndNew}
                          style={{
                            float: "left",
                            marginTop: "10px",
                            marginBottom: "10px",
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.save_and_new")}
                        </Checkbox>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 16 }}>
                        <Button
                          size="large"
                          type="link"
                          onClick={onCancelled}
                          style={{
                            marginRight: 10,
                          }}
                        >
                          {t("general.back")}
                        </Button>
                        <Button
                          size="large"
                          onClick={() => handleSubmit(false)}
                          style={{
                            marginRight: 10,
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.save")}
                        </Button>
                        {/* Save for maintenance completion */}
                        <Button
                          size="large"
                          onClick={() => feedbackPropMaint()}
                          style={{
                            marginRight: isOwner || isAssignor ? 10 : 0,
                            display:
                              ((!editItem.IsRequireApproval && isSubmitted) ||
                                isStatusApproved(editItem.Status)) &&
                              editItem.IsValidated &&
                              editItem.IsRequireMaint &&
                              isAssignor &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.save")}
                        </Button>
                        <Button
                          size="large"
                          type="link"
                          onClick={showDeleteConfirm}
                          style={{
                            display:
                              isEdit &&
                              isOpen &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.delete")}
                        </Button>
                        <Button
                          size="large"
                          type="primary"
                          onClick={showSubmitConfirm}
                          style={{
                            marginRight: 10,
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.submit")}
                        </Button>
                        <Button
                          type="primary"
                          size="large"
                          onClick={showWithdrawConfirm}
                          style={{
                            marginRight: 10,
                            display:
                              isSubmitted &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd &&
                              !editItem.IsValidated
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.withdraw")}
                        </Button>
                        <Button
                          type="primary"
                          size="large"
                          onClick={() => setShowReasonForm(true)}
                          style={{
                            display:
                              isApproved &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd &&
                              !editItem.IsValidated
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.request_cancel")}
                        </Button>
                        <Button
                          type="primary"
                          size="large"
                          onClick={() => completePropMaint()}
                          style={{
                            background: "mediumpurple",
                            borderColor: "mediumpurple",
                            display:
                              ((!editItem.IsRequireApproval && isSubmitted) ||
                                isStatusApproved(editItem.Status)) &&
                              editItem.IsValidated &&
                              editItem.IsRequireMaint &&
                              isAssignor &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.complete")}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Card>
          <EntryFormFooter
            footerData={editItem}
            isApproved={isApproved}
            isVoid={isVoid}
            isPending={
              editItem.Status === 4 || editItem.Status === 1 ? true : false
            }
            showFooter={editItem.PropMaintKey !== -1 ? true : false}
            t={t}
          ></EntryFormFooter>
        </Col>
      </Row>

      <ApprovalReasonModal
        isVisible={showReasonForm}
        reasonType={"Cancel Properties Maintenance"}
        onSaved={submitCancelReason}
        onCancelled={() => setShowReasonForm(false)}
      />
    </div>
  );
};

ManagePropMaintForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  editItem: PropTypes.object.isRequired,
  loadPageData: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onWithdraw: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRequestCancel: PropTypes.func.isRequired,
  onFeedbackPropMaint: PropTypes.func.isRequired,
  onCompletePropMaint: PropTypes.func.isRequired,
  onUploadAttachment: PropTypes.func.isRequired,
  onRemoveAttachment: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  onIsSaveAndNewChange: PropTypes.func.isRequired,
  isSaveAndNewData: PropTypes.bool.isRequired,
  fileList: PropTypes.array.isRequired,
  setFileList: PropTypes.func.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isViewOnly: PropTypes.bool,
  isAssignor: PropTypes.bool.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default ManagePropMaintForm;
