import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Pagination } from "antd";
import { RoomRequisitionList } from "./RoomRequisitionList";
import { RoomAssignmentModal } from "./RoomAssignmentModal";
import "../../../App.css";
import {
  Status,
  StatusByHexColor,
  isStatusSubmitted,
  isStatusApproved,
} from "../../model/common/Status";
import { useTranslation } from "react-i18next";

export default function RoomRequisitionAssignmentForm({
  isLoading,
  data,
  allRooms,
  onAdd,
  onView,
  onAssigned,
  getAvailableRooms,
  setCurrentYear,
  pagination,
  setPagination,
  resetPagination,
}) {
  const { t } = useTranslation();
  const [showAssignmentForm, setShowAssignmentForm] = useState(false);
  const [tempItem, setTempItem] = useState();

  function submitRoomAssignment(roomKey) {
    setShowAssignmentForm(false);
    let roomRequisition = { ...tempItem, RoomKey: roomKey };
    onAssigned(roomRequisition);
  }

  function assignRoom(selectedRoomRequisition) {
    setTempItem(selectedRoomRequisition);
    getAvailableRooms(selectedRoomRequisition.RoomRequisitionKey, () =>
      setShowAssignmentForm(true)
    );
  }

  function getListActions(item) {
    return [
      <div key>
        <span
          style={{
            float: "left",
            fontStyle: "italic",
            marginLeft: "50px",
            color: StatusByHexColor[item.Status],
          }}
        >
          {Status[item.Status]}
        </span>

        <Button
          type="primary"
          size="default"
          style={{
            display:
              (!item.IsRequireApproval && isStatusSubmitted(item.Status)) ||
              isStatusApproved(item.Status)
                ? ""
                : "none",
          }}
          onClick={() => assignRoom(item)}
        >
          {t("roomRequisitionForm.assign_meeting_room")}
        </Button>
      </div>,
    ];
  }

  const footerElement = (footerValue, totalPagination) => (
    <div style={{ textAlign: "right", marginRight: "10px" }}>
      <Pagination
        style={{ display: "inline-flex", paddingRight: 10 }}
        defaultCurrent={pagination.current}
        current={pagination.current}
        total={totalPagination}
        onChange={onChangePagination}
        showTotal={(total) =>
          t("general.total") + ` ${total} ` + t("general.records")
        }
        defaultPageSize={pagination.pageSize}
        pageSize={pagination.pageSize}
      />
      {t("roomRequisitionForm.total_pending_assignment")} :{" "}
      <b style={{ color: "#0070cc" }}>
        {data.filter((x) => !x.IsViewOnly).length}
      </b>
    </div>
  );

  function onChangePagination(page, pageSize) {
    setPagination({
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
      pageSize: pageSize,
    });
  }

  return (
    <div>
      <RoomRequisitionList
        isLoading={isLoading}
        data={data}
        onAdd={onAdd}
        onItemViewed={onView}
        isSelectable={true}
        isMultipleAction={false}
        onListActionsRendered={getListActions}
        filterCheckedText={"Show unassigned meeting room requisition(s) only"}
        footerElement={footerElement}
        setCurrentYear={setCurrentYear}
        pagination={pagination}
        resetPagination={resetPagination}
      />

      <RoomAssignmentModal
        isVisible={showAssignmentForm}
        allRooms={allRooms}
        selectedRoom={tempItem}
        onSaved={submitRoomAssignment}
        onCancelled={() => setShowAssignmentForm(false)}
      />
    </div>
  );
}

RoomRequisitionAssignmentForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  allRooms: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onAssigned: PropTypes.func.isRequired,
  getAvailableRooms: PropTypes.func.isRequired,
  setCurrentYear: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  resetPagination: PropTypes.func.isRequired,
};
